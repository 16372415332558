import {
  getHardwareTypesApi,
  createHardwareTypeApi,
  updateHardwareTypeApi,
  deleteHardwareTypeApi,
} from "../api/hardwareTypes";
// import { getResponseExceptionMessage } from '../api/apiSettings';

// GetAll
const getHardwareTypesRequest = () => {
  return {
    type: "GET_HARDWARE_TYPES_REQUEST",
  };
};

const getHardwareTypesResponse = (input) => {
  return {
    type: "GET_HARDWARE_TYPES_RESPONSE",
    data: input.data,
    error: input.error,
  };
};

export const getHardwareTypes = () => {
  return async (dispatch, getState) => {
    dispatch(getHardwareTypesRequest());
    const result = {};

    try {
      const apiResult = await getHardwareTypesApi(
        getState().authentication.token
      );
      result.data = apiResult.data.result;
    } catch (ex) {
      result.error = ex.message || "Une erreur est survenue";
    }

    dispatch(getHardwareTypesResponse(result));
  };
};

// Create
const createHardwareTypeRequest = () => {
  return {
    type: "CREATE_HARDWARE_TYPE_REQUEST",
  };
};

const createHardwareTypeResponse = (input) => {
  return {
    type: "CREATE_HARDWARE_TYPE_RESPONSE",
    data: input.data,
    error: input.error,
  };
};

export const createHardwareType = (hardwareType) => {
  return async (dispatch, getState) => {
    dispatch(createHardwareTypeRequest());
    const result = {};

    try {
      const apiResult = await createHardwareTypeApi(
        getState().authentication.token,
        hardwareType
      );
      result.data = apiResult.data.result;
    } catch (ex) {
      result.error = ex.message || "Une erreur est survenue";
    }

    dispatch(createHardwareTypeResponse(result));
    return result;
  };
};

// Update
const updateHardwareTypeRequest = () => {
  return {
    type: "UPDATE_HARDWARE_TYPE_REQUEST",
  };
};

const updateHardwareTypeResponse = (input) => {
  return {
    type: "UPDATE_HARDWARE_TYPE_RESPONSE",
    data: input.data,
    error: input.error,
  };
};

export const updateHardwareType = (hardwareType) => {
  return async (dispatch, getState) => {
    dispatch(updateHardwareTypeRequest());
    const result = {};

    try {
      const apiResult = await updateHardwareTypeApi(
        getState().authentication.token,
        hardwareType
      );
      result.data = apiResult.data.result;
    } catch (ex) {
      result.error = ex.message || "Une erreur est survenue";
    }

    dispatch(updateHardwareTypeResponse(result));
    return result;
  };
};

// Delete
const deleteHardwareTypeRequest = () => {
  return {
    type: "DELETE_HARDWARE_TYPE_REQUEST",
  };
};

const deleteHardwareTypeResponse = (input) => {
  return {
    type: "DELETE_HARDWARE_TYPE_RESPONSE",
    data: input.data,
    error: input.error,
  };
};

export const deleteHardwareType = (hardwareTypeId) => {
  return async (dispatch, getState) => {
    dispatch(deleteHardwareTypeRequest());
    const result = {};

    try {
      const apiResult = await deleteHardwareTypeApi(
        getState().authentication.token,
        hardwareTypeId
      );
      result.data = apiResult.data.result;
    } catch (ex) {
      result.error = ex.message || "Une erreur est survenue";
    }

    dispatch(deleteHardwareTypeResponse(result));
    return result;
  };
};
