import { getCustomerGroupApi, getAllCustomerGroupsApi, createCustomerGroupApi, updateCustomerGroupApi, deleteCustomerGroupApi } from '../api/customerGroups';
import { createActions } from 'redux-actions';

export const { getCustomerGroupRequest, getCustomerGroupResponse, getAllCustomerGroupsRequest, getAllCustomerGroupsResponse,
    createCustomerGroupRequest, createCustomerGroupResponse, updateCustomerGroupRequest, updateCustomerGroupResponse,
    deleteCustomerGroupRequest, deleteCustomerGroupResponse } = createActions({
    GET_CUSTOMER_GROUP_REQUEST: () => ({ }),
    GET_CUSTOMER_GROUP_RESPONSE: (input) => (
        {
            data: input.data,
            error: input.error 
        }
    ),
    GET_ALL_CUSTOMER_GROUPS_REQUEST: () => ({ }),
    GET_ALL_CUSTOMER_GROUPS_RESPONSE: (input) => (
        {
            data: input.data,
            error: input.error 
        }
    ),
    CREATE_CUSTOMER_GROUP_REQUEST: () => ({ }),
    CREATE_CUSTOMER_GROUP_RESPONSE: (input) => (
        {
            data: input.data,
            error: input.error 
        }
    ),
    UPDATE_CUSTOMER_GROUP_REQUEST: () => ({ }),
    UPDATE_CUSTOMER_GROUP_RESPONSE: (input) => (
        {
            data: input.data,
            error: input.error 
        }
    ),
    DELETE_CUSTOMER_GROUP_REQUEST: () => ({ }),
    DELETE_CUSTOMER_GROUP_RESPONSE: (input) => (
        {
            data: input.data,
            error: input.error 
        }
    )
});

export const getCustomerGroup = () => {
    return async (dispatch, getState) => {
        dispatch(getCustomerGroupRequest());
        const result = {};
        try {
            const apiResult = await getCustomerGroupApi(getState().authentication.token);
            result.data = apiResult.data.result;
        }
        catch (ex) {
            result.error = ex.message || 'Une erreur est survenue';
        }
        dispatch(getCustomerGroupResponse(result));
        return result;
    };
};

export const getAllCustomerGroups = () => {
    return async (dispatch, getState) => {
        dispatch(getAllCustomerGroupsRequest());
        const result = {};
        try {
            const apiResult = await getAllCustomerGroupsApi(getState().authentication.token);
            result.data = apiResult.data.result;
        }
        catch (ex) {
            result.error = ex.message || 'Une erreur est survenue';
        }
        dispatch(getAllCustomerGroupsResponse(result));
        return result;
    };
};

export const createCustomerGroup = (customerGroup) => {
    return async (dispatch, getState) => {
        dispatch(createCustomerGroupRequest());
        const result = {};
        try {
            const apiResult = await createCustomerGroupApi(getState().authentication.token, customerGroup);
            result.data = apiResult.data.result;
        }
        catch (ex) {
            result.error = ex.message || 'Une erreur est survenue';
        }
        dispatch(createCustomerGroupResponse(result));
        return result;
    };
};

export const updateCustomerGroup = (customerGroup) => {
    return async (dispatch, getState) => {
        dispatch(updateCustomerGroupRequest());
        const result = {};
        try {
            const apiResult = await updateCustomerGroupApi(getState().authentication.token, customerGroup);
            result.data = apiResult.data.result;
        }
        catch (ex) {
            result.error = ex.message || 'Une erreur est survenue';
        }
        dispatch(updateCustomerGroupResponse(result));
        return result;
    };
};

export const deleteCustomerGroup = (customerId) => {
    return async (dispatch, getState) => {
        dispatch(deleteCustomerGroupRequest());
        const result = {};
        try {
            const apiResult = await deleteCustomerGroupApi(getState().authentication.token, customerId);
            result.data = apiResult.data.result;
        }
        catch (ex) {
            result.error = ex.message || 'Une erreur est survenue';
        }
        dispatch(deleteCustomerGroupResponse(result));
        return result;
    };
};