import axios from 'axios';
import * as ApiSettings from './apiSettings';
import { getDefaultHeaders, getAllDefaultParams } from './apiSettings';

const servicePath = '/api/services/app/Hardware';

export const getHardwaresApi = (authToken) => {
    return axios.get(`${ApiSettings.apiBaseUrl}${servicePath}/GetAll`, {
        params: { ...getAllDefaultParams() },
        headers: {
            ...getDefaultHeaders(),
            'Authorization': `Bearer ${authToken}`
        }
    });
};

export const createHardwareApi = (authToken, hardware) => {
    return axios.post(`${ApiSettings.apiBaseUrl}${servicePath}/Create`, {
        ...hardware
    }, {
        headers: {
            ...getDefaultHeaders(),
            'Authorization': `Bearer ${authToken}`
        }
    });
};

export const updateHardwareApi = (authToken, hardware) => {
    return axios.put(`${ApiSettings.apiBaseUrl}${servicePath}/Update`, {
        ...hardware
    }, {
        headers: {
            ...getDefaultHeaders(),
            'Authorization': `Bearer ${authToken}`
        }
    });
};

export const deleteHardwareApi = (authToken, hardwareId) => {
    return axios.delete(`${ApiSettings.apiBaseUrl}${servicePath}/Delete`, {
        headers: {
            ...getDefaultHeaders(),
            'Authorization': `Bearer ${authToken}`
        },
        params: {
            Id: hardwareId
        }
    });
};