import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { Provider } from 'react-redux';
import { createHashHistory } from 'history';
import { applyMiddleware, compose, createStore } from 'redux';
import { routerMiddleware } from 'connected-react-router';
import rootReducer from './reducers/index';
import { ConnectedRouter } from 'connected-react-router';
import thunk from 'redux-thunk';
import { StyleRoot } from 'radium';
import moment from 'moment';
import momentFrLocale from 'moment/locale/fr';

import 'typeface-roboto';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-table/react-table.css';
import './index.css';

moment.updateLocale('fr', momentFrLocale);

const history = createHashHistory();

const store = createStore(
    rootReducer(history), // new root reducer with router state
    //initialState,
    compose(
      applyMiddleware(
        routerMiddleware(history), // for dispatching history actions
        thunk
      ),
    ),
  )

ReactDOM.render(
    <StyleRoot>
        <Provider store={store}>
            <ConnectedRouter history={history}>
                <App />
            </ConnectedRouter>
        </Provider>
    </StyleRoot>, 
    document.getElementById('root')
);
