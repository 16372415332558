import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Alert } from 'reactstrap';
import { dismissNotification } from '../actions/notifications';
import sleep from '../helpers/sleep';

class Notification extends Component{

    state = { isVisible: true };

    isDismissed = false;
    
    async componentDidMount(){

        await sleep(5000);
        this.dismiss();

    }

    dismiss = async() => {

        if (!this.isDismissed){
            this.isDismissed = true;
            this.setState({ isVisible: false });
            await sleep(300);
            this.props.dispatch(dismissNotification(this.props.notification.id));
        }

    };

    render(){
        
        const { notification } = this.props;

        return (
            <Alert color={notification.type} isOpen={this.state.isVisible} toggle={this.dismiss}>
                {notification.text}
            </Alert>
        );

    }

}

const mapStateToProps = state => ({
});

export default connect(mapStateToProps)(Notification);