export const moveRequestStatusType = {
    pending: 1,
    validated: 2,
    rejected: 3,
};

export const moveRequestStatusTypeName = {
    [moveRequestStatusType.pending]: 'En attente de réponse MHD',
    [moveRequestStatusType.validated]: 'Demande validée par MHD',
    [moveRequestStatusType.rejected]: 'Demande rejetée par MHD'
};

export const moveRequestInstoreStatusType = {
    pending: 1,
    scheduled: 2,
    done: 3,
    rejected: 4
};

export const moveRequestInstoreStatusTypeName = {
    [moveRequestInstoreStatusType.pending]: 'En attente de réponse',
    [moveRequestInstoreStatusType.scheduled]: 'Planifiée',
    [moveRequestInstoreStatusType.done]: 'Réalisée',
    [moveRequestInstoreStatusType.rejected]: 'Annulée'
};