import React, { Component } from "react";
import { connect } from "react-redux";
import {
  CustomInput,
  Button,
  Form,
  Input,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import Radium from "radium";
import moment from "moment";
import Enumerable from "linq";
import Calendar from "react-calendar";
import { getCustomers } from "../../actions/customers";
import {
  getMoveRequestTickets,
  createMoveRequestTicket,
  updateMoveRequestTicket,
} from "../../actions/tickets";
import { push } from "connected-react-router";
import {
  faTimesCircle,
  faPlusCircle,
  faEdit,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { getHardwareTypes } from "../../actions/hardwareTypes";
import NewCustomerModal from "../../components/NewCustomerModal";
import { pushModalAsync, dismissModalAsync } from "../../actions/modals";
import { color } from "../../helpers/color";
import {
  moveRequestStatusType,
  moveRequestStatusTypeName,
  moveRequestInstoreStatusType,
  moveRequestInstoreStatusTypeName,
} from "../../helpers/moveRequestStatus";
import { permissions, hasPermission } from "../../helpers/permissions";
import Select from "react-select";
import { roles } from "../../helpers/roles";

const emptyTicket = () => ({
  id: "new",
  requestedDateYear: null,
  requestedDateDayOfYear: null,
  requestedTimeRangeStart: "00:00:00",
  requestedTimeRangeEnd: "00:00:00",
  hasInternetAccess: false,
  description: null,
  hardwares: [],
  hardwareTypes: [],
  customerId: null,
});

class MoveDetail extends Component {
  state = {
    ticket: {
      ...emptyTicket(),
    },
  };

  componentDidMount() {
    this.props.dispatch(getHardwareTypes());
    this.props.dispatch(getCustomers());
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      this.props.match &&
      this.props.match.params.id !== this.state.ticket.id
    ) {
      if (this.props.match.params.id !== "new") {
        if (!this.props.tickets.moveTickets) {
          this.props.dispatch(getMoveRequestTickets());
          return;
        }

        const moveTicket = Enumerable.from(
          this.props.tickets.moveTickets.items
        ).firstOrDefault((o) => o.id === this.props.match.params.id);
        if (!moveTicket) {
          return;
        }
        this.setState({ ticket: { ...moveTicket } });
      } else {
        this.setState({ ticket: { ...emptyTicket() } });
      }
    }
  }

  handleInputChange = (e) => {
    const propName = e.target.name;
    let propValue = e.target.value;
    let changes = {};

    switch (propName) {
      case "requestedDate":
        changes = {
          requestedDateYear: propValue ? moment(propValue).year() : null,
          requestedDateDayOfYear: propValue
            ? moment(propValue).dayOfYear()
            : null,
        };
        break;
      case "expectedDate":
        changes = {
          expectedDateYear: propValue ? moment(propValue).year() : null,
          expectedDateDayOfYear: propValue
            ? moment(propValue).dayOfYear()
            : null,
        };
        break;
      case "hasInternetAccess":
        changes = {
          hasInternetAccess: !this.state.ticket.hasInternetAccess,
        };
        break;
      case "gondoleRack":
        changes = {
          gondoleRack: !this.state.ticket.gondoleRack,
        };
        break;
      default:
        changes = {
          [propName]: propValue,
        };
        break;
    }

    this.setState((prevState, prevProps) => ({
      ticket: {
        ...prevState.ticket,
        ...changes,
      },
    }));
  };

  addHardwareType = (hardwareType) => {
    if (this.state.ticket.hardwareTypes) {
      if (
        !Enumerable.from(this.state.ticket.hardwareTypes).any(
          (o) => o.id === hardwareType.id
        )
      ) {
        this.setState((prevState, prevProps) => ({
          ticket: {
            ...prevState.ticket,
            hardwareTypes: [
              //   ...prevState.ticket.hardwareTypes,
              { _id: new Date().getTime().toString(), ...hardwareType },
            ],
          },
        }));
      }
    }
  };

  deleteHardwareType = (hardwareType) => {
    const hardwareTypeIndex = this.state.ticket.hardwareTypes.indexOf(
      hardwareType
    );
    if (hardwareTypeIndex !== -1) {
      const hardwareTypes = [...this.state.ticket.hardwareTypes];
      hardwareTypes.splice(hardwareTypeIndex, 1);
      this.setState((prevState, prevProps) => ({
        ticket: {
          ...prevState.ticket,
          hardwareTypes: hardwareTypes,
        },
      }));
    }
  };

  handleSubmit = async (e) => {
    e.preventDefault();

    if (this.isFormInvalid() || this.state.isBusy) {
      return;
    }

    this.setState({ isBusy: true }, async () => {
      try {
        const ticket = { ...this.state.ticket };
        ticket.hardwareTypeIds = ticket.hardwareTypes.map((o) => o.id);
        ticket.hardwareIds = ticket.hardwares.map((o) => o.id);
        delete ticket.hardwareTypes;
        delete ticket.hardwares;

        if (this.state.ticket.id === "new") {
          const createResult = await this.props.dispatch(
            createMoveRequestTicket(ticket)
          );
          if (!createResult.error) {
            this.setState({ isBusy: false }, () => {
              this.props.dispatch(getMoveRequestTickets());
              this.props.dispatch(push(`/move/${createResult.data.id}`));
            });
          } else {
            this.setState({ isBusy: false });
          }
        } else {
          const updateResult = await this.props.dispatch(
            updateMoveRequestTicket(ticket)
          );
          if (!updateResult.error) {
            this.setState({ isBusy: false });
          } else {
            this.setState({ isBusy: false });
          }
        }
      } catch (ex) {
        console.error(ex);
        this.setState({ isBusy: false });
      }
    });
  };

  getCurrentTicketCustomer = () => {
    const { customers } = this.props;
    const { ticket } = this.state;
    let customer =
      ticket.customerId && customers.items
        ? Enumerable.from(customers.items).firstOrDefault(
            (o) => o.id === ticket.customerId
          )
        : null;
    if (!customer && ticket.customer) {
      customer = ticket.customer;
    }
    return customer;
  };

  handleAddCustomerClick = async (e) => {
    this.props.dispatch(
      pushModalAsync(NewCustomerModal, {
        onClose: async (modalId, modalResult) => {
          this.props.dispatch(dismissModalAsync(modalId));
          if (modalResult && modalResult.data && modalResult.data.id) {
            this.setState((prevState, prevProps) => ({
              ticket: {
                ...prevState.ticket,
                customerId: modalResult.data.id,
              },
            }));
          }
        },
      })
    );
  };

  handleEditCustomerClick = async (e) => {
    let customer = this.getCurrentTicketCustomer();
    if (customer) {
      this.props.dispatch(
        pushModalAsync(NewCustomerModal, {
          customer: customer,
          onClose: async (modalId, modalResult) => {
            if (modalResult.isDeleted) {
              this.setState((prevState, prevProps) => ({
                ticket: {
                  ...prevState.ticket,
                  customerId: null,
                  customer: null,
                },
              }));
            } else {
              if (modalResult.data && modalResult.data.id) {
                this.setState((prevState, prevProps) => ({
                  ticket: {
                    ...prevState.ticket,
                    customerId: modalResult.data.id,
                  },
                }));
              }
            }
            this.props.dispatch(dismissModalAsync(modalId));
          },
        })
      );
    }
  };

  isFormInvalid = () =>
    !this.state.ticket.customerId ||
    this.state.ticket.hardwareTypes.length === 0 ||
    !this.state.ticket.requestedDateDayOfYear;

  render() {
    const { ticket } = this.state;
    const { hardwareTypes, customers, authentication } = this.props;
    const permissionNames =
      authentication.user && authentication.user.permissionNames
        ? authentication.user.permissionNames
        : [];
    const minDateRequested =
      authentication.user &&
      Enumerable.from(authentication.user.roleNames).any(
        (o) =>
          o === roles.Admin ||
          o === roles.AdminInstore ||
          o === roles.AdminPLV ||
          o === roles.AdminRS
      )
        ? null
        : moment()
            .add(14, "days")
            .toDate();
    const currentDateRequested =
      ticket.requestedDateYear && ticket.requestedDateDayOfYear
        ? moment()
            .year(ticket.requestedDateYear)
            .dayOfYear(ticket.requestedDateDayOfYear)
            .toDate()
        : null;
    const currentDateExpected =
      ticket.expectedDateYear && ticket.expectedDateDayOfYear
        ? moment()
            .year(ticket.expectedDateYear)
            .dayOfYear(ticket.expectedDateDayOfYear)
            .toDate()
        : null;
    let customer = this.getCurrentTicketCustomer();

    let isCustomerIncomplete = false;
    if (customer != null) {
      isCustomerIncomplete =
        !customer.contactPhone ||
        !customer.name ||
        !customer.code ||
        !customer.address ||
        !customer.postalCode ||
        !customer.city ||
        !customer.country ||
        !customer.phone ||
        !customer.email ||
        !customer.contactPhone ||
        !customer.faxPC ||
        !customer.function ||
        !customer.customerGroupId;
    }

    const customerSelectOptions =
      customers && customers.items
        ? Enumerable.from(customers.items)
            .orderBy((o) => o.name)
            .select((o) => ({ value: o.id, label: o.name }))
            .toArray()
        : [];
    const selectedCustomer = customer
      ? Enumerable.from(customerSelectOptions).firstOrDefault(
          (o) => o.value === customer.id
        )
      : null;

    const styles = {
      root: {
        height: "100%",
        width: "100%",
        overflow: "hidden",
        overflowY: "auto",
        padding: "20px",
      },
      title: {
        textTransform: "uppercase",
        fontSize: "20px",
        color: "white",
        flex: "1",
      },
    };

    return (
      <div style={{ display: "flex", height: "100%", width: "100%" }}>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            height: "100%",
          }}
        >
          <div style={{ padding: "20px", background: color.gold }}>
            <div style={{ display: "flex" }}>
              <div style={styles.title}>
                {ticket.id !== "new" ? (
                  <span>
                    Demande de déplacement linéaire{" "}
                    <strong>{ticket.friendlyNum}</strong>
                  </span>
                ) : (
                  `Nouvelle demande de déplacement linéaire`
                )}
              </div>

              <div>
                <Button
                  color="success"
                  type="button"
                  onClick={this.handleSubmit}
                  style={{
                    display: "block",
                    fontSize: "16px",
                    textTransform: "uppercase",
                  }}
                  disabled={
                    this.isFormInvalid() ||
                    this.state.isBusy ||
                    isCustomerIncomplete
                  }
                >
                  {ticket.id !== "new" ? "Enregistrer" : "Créer la demande"}
                </Button>
              </div>
            </div>

            {ticket.id !== "new" && (
              <div>
                <div style={{ fontSize: "13px", color: "white" }}>
                  <div>
                    Créé par {ticket.creatorName} le{" "}
                    {moment(ticket.creationTime).format("DD/MM/YYYY HH:mm")}
                  </div>
                </div>

                <UncontrolledDropdown
                  style={{ display: "inline-block", paddingRight: "5px" }}
                >
                  <DropdownToggle
                    caret
                    style={{ marginTop: "10px", width: "200px" }}
                  >
                    {moveRequestStatusTypeName[ticket.currentStatus]}
                  </DropdownToggle>
                  <DropdownMenu>
                    {Object.keys(moveRequestStatusType).map((key) => (
                      <DropdownItem
                        disabled={
                          !hasPermission(
                            permissionNames,
                            permissions.UpdateTicketStatus
                          )
                        }
                        key={moveRequestStatusType[key]}
                        onClick={() =>
                          this.handleInputChange({
                            target: {
                              name: "currentStatus",
                              value: moveRequestStatusType[key],
                            },
                          })
                        }
                      >
                        {moveRequestStatusTypeName[moveRequestStatusType[key]]}
                      </DropdownItem>
                    ))}
                  </DropdownMenu>
                </UncontrolledDropdown>

                <UncontrolledDropdown style={{ display: "inline-block" }}>
                  <DropdownToggle
                    caret
                    style={{ marginTop: "10px", width: "200px" }}
                  >
                    {moveRequestInstoreStatusTypeName[ticket.instoreStatus]
                      ? moveRequestInstoreStatusTypeName[ticket.instoreStatus]
                      : "Statut SAV"}
                  </DropdownToggle>
                  <DropdownMenu>
                    {Object.keys(moveRequestInstoreStatusType).map((key) => (
                      <DropdownItem
                        disabled={
                          !hasPermission(
                            permissionNames,
                            permissions.UpdateTicketInstoreStatus
                          )
                        }
                        key={moveRequestInstoreStatusType[key]}
                        onClick={() =>
                          this.handleInputChange({
                            target: {
                              name: "instoreStatus",
                              value: moveRequestInstoreStatusType[key],
                            },
                          })
                        }
                      >
                        {
                          moveRequestInstoreStatusTypeName[
                            moveRequestInstoreStatusType[key]
                          ]
                        }
                      </DropdownItem>
                    ))}
                  </DropdownMenu>
                </UncontrolledDropdown>
              </div>
            )}
          </div>

          <Form onSubmit={this.handleSubmit} style={styles.root}>
            <div
              style={{ display: "flex", marginBottom: "5px", flexWrap: "wrap" }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  flex: "1",
                  border: "1px solid #CCC",
                  background: "white",
                  padding: "15px",
                  height: "350px",
                  margin: "5px",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    borderBottom: "1px solid #DDD",
                    paddingBottom: "10px",
                    marginBottom: "15px",
                  }}
                >
                  <div
                    style={{ flex: "1", fontSize: "20px", color: color.gold }}
                  >
                    MAGASIN
                  </div>
                  {authentication.user && (
                    <div>
                      {authentication.user.permissionNames &&
                        authentication.user.permissionNames.indexOf(
                          permissions.ManageCustomers
                        ) !== -1 && (
                          <FontAwesomeIcon
                            icon={faPlusCircle}
                            color="#28a745"
                            style={{
                              margin: "auto",
                              paddingRight: "5px",
                              cursor: "pointer",
                            }}
                            size="2x"
                            onClick={this.handleAddCustomerClick}
                          />
                        )}
                      {customer && (
                        <FontAwesomeIcon
                          icon={faEdit}
                          color="#007bff"
                          style={{
                            margin: "auto",
                            paddingRight: "5px",
                            cursor: "pointer",
                          }}
                          size="2x"
                          onClick={this.handleEditCustomerClick}
                        />
                      )}
                    </div>
                  )}
                  {customerSelectOptions && (
                    <Select
                      value={selectedCustomer}
                      options={customerSelectOptions}
                      styles={{
                        container: (provided) => ({
                          ...provided,
                          width: "100%",
                          maxWidth: "300px",
                        }),
                      }}
                      onChange={(option) =>
                        this.handleInputChange({
                          target: { name: "customerId", value: option.value },
                        })
                      }
                    />
                  )}
                  {/* {customers.items && <UncontrolledDropdown>
                                        <DropdownToggle caret style={{ width: '250px' }}>
                                            {customer ? customer.name : 'Choix du magasin concerné'}
                                        </DropdownToggle>
                                        <DropdownMenu style={{ maxHeight: '300px', overflow: 'auto' }}>
                                            {customers.items.map(customer => (
                                                <DropdownItem key={customer.id} onClick={() => this.handleInputChange({ target: { name: 'customerId', value: customer.id } })}>{customer.name}</DropdownItem>
                                            ))}
                                        </DropdownMenu>
                                    </UncontrolledDropdown>} */}
                </div>
                {customer && (
                  <div>
                    {isCustomerIncomplete && (
                      <div style={{ color: "red", fontWeight: "bold" }}>
                        Veuillez compléter les champs manquants en cliquant sur
                        le crayon bleu ci-dessus.
                      </div>
                    )}
                    <div
                      style={{
                        flex: "1",
                        overflowY: "auto",
                        fontSize: "14px",
                        color: "#222",
                      }}
                    >
                      <div>
                        <strong>Nom:</strong> {customer.name}
                      </div>
                      <div>
                        <strong>Code:</strong> {customer.code}
                      </div>
                      <div>
                        <strong>Adresse:</strong> {customer.address}{" "}
                        {customer.postalCode} {customer.city} {customer.country}
                      </div>
                      <div>
                        <strong>Tél du magasin:</strong> {customer.phone}
                      </div>
                      <div>
                        <strong>Tél du contact:</strong> {customer.contactPhone}
                      </div>
                      <div>
                        <strong>Nom du contact:</strong> {customer.managerName}
                      </div>
                      <div>
                        <strong>Fax PC:</strong> {customer.faxPC}
                      </div>
                      <div>
                        <strong>Email:</strong> {customer.email}
                      </div>
                      <div>
                        <strong>Fonction:</strong> {customer.function}
                      </div>
                      <div>
                        <strong>Groupe:</strong>{" "}
                        {customer.customerGroup
                          ? customer.customerGroup.name
                          : ""}
                      </div>
                      <div>
                        <strong>Terroir d'Ecosse:</strong>{" "}
                        {customer.isTerroirEcosse ? "Oui" : "Non"}
                      </div>
                    </div>
                  </div>
                )}
              </div>

              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  flex: "1",
                  border: "1px solid #CCC",
                  background: "white",
                  padding: "15px",
                  height: "350px",
                  margin: "5px",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    borderBottom: "1px solid #DDD",
                    paddingBottom: "10px",
                    marginBottom: "15px",
                  }}
                >
                  <div
                    style={{
                      flex: "1",
                      fontSize: "20px",
                      color: color.gold,
                      textTransform: "uppercase",
                    }}
                  >
                    Types de configurations
                  </div>
                  {hardwareTypes.items && (
                    <UncontrolledDropdown>
                      <DropdownToggle caret style={{ width: "250px" }}>
                        Choix des types de configurations
                      </DropdownToggle>
                      <DropdownMenu
                        style={{ maxHeight: "300px", overflow: "auto" }}
                      >
                        {hardwareTypes.items.map((hardwareType) => (
                          <DropdownItem
                            key={hardwareType.id}
                            onClick={() => this.addHardwareType(hardwareType)}
                          >
                            {hardwareType.name}
                          </DropdownItem>
                        ))}
                      </DropdownMenu>
                    </UncontrolledDropdown>
                  )}
                </div>

                <div>
                  {ticket.hardwareTypes &&
                    ticket.hardwareTypes.map((hardwareType) => (
                      <div
                        key={hardwareType._id || hardwareType.id}
                        style={{
                          padding: "10px",
                          borderRadius: "5px",
                          background: "#F0F0F0",
                          marginBottom: "5px",
                        }}
                      >
                        <FontAwesomeIcon
                          icon={faTimesCircle}
                          color="#DF0000"
                          style={{
                            fontSize: "13px",
                            cursor: "pointer",
                            marginRight: "10px",
                          }}
                          onClick={() => this.deleteHardwareType(hardwareType)}
                        />{" "}
                        {hardwareType.name}
                      </div>
                    ))}
                </div>
              </div>
            </div>

            <div
              style={{ display: "flex", marginBottom: "5px", flexWrap: "wrap" }}
            >
              <div
                style={{
                  display: "flex",
                  minWidth: "540px",
                  flexDirection: "column",
                  flex: "1 0",
                  border: "1px solid #CCC",
                  background: "white",
                  padding: "15px",
                  height: "400px",
                  margin: "5px",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    borderBottom: "1px solid #DDD",
                    paddingBottom: "10px",
                    marginBottom: "15px",
                  }}
                >
                  <div
                    style={{
                      flex: "1",
                      fontSize: "20px",
                      color: color.gold,
                      textTransform: "uppercase",
                    }}
                  >
                    Description
                  </div>
                </div>

                <CustomInput
                  id="moveTicketHasInternet"
                  name="hasInternetAccess"
                  type="checkbox"
                  style={{ paddingBottom: "5px" }}
                  label="Connecté à internet"
                  checked={this.state.ticket.hasInternetAccess ? true : false}
                  onChange={this.handleInputChange}
                />

                <CustomInput
                  id="gondoleRack"
                  name="gondoleRack"
                  type="checkbox"
                  style={{ paddingBottom: "5px" }}
                  label="Gondole Rack"
                  checked={this.state.ticket.gondoleRack ? true : false}
                  onChange={this.handleInputChange}
                />

                <Input
                  id="text"
                  type="textarea"
                  name="description"
                  placeholder="Précisions sur la demande de déplacement linéaire si nécessaire..."
                  value={ticket.description || ""}
                  onChange={this.handleInputChange}
                  style={{ flex: "1" }}
                />
              </div>

              <div
                style={{
                  display: "flex",
                  minWidth: "540px",
                  flexDirection: "column",
                  flex: "1",
                  border: "1px solid #CCC",
                  background: "white",
                  padding: "15px",
                  height: "400px",
                  margin: "5px",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    borderBottom: "1px solid #DDD",
                    paddingBottom: "10px",
                    marginBottom: "15px",
                  }}
                >
                  <div
                    style={{
                      flex: "1",
                      fontSize: "20px",
                      color: color.gold,
                      textTransform: "uppercase",
                    }}
                  >
                    Date / heure de démarrage souhaitée
                  </div>
                </div>

                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    flex: "1",
                    overflowY: "auto",
                    fontSize: "14px",
                    color: "#222",
                  }}
                >
                  <div style={{ flex: "1" }}>
                    <Calendar
                      name="requestedDate"
                      tileDisabled={({ activeStartDate, date, view }) => {
                        const isSameDayAndYear =
                          moment(date).format("DD/MM/YYYY") ===
                          moment(currentDateRequested).format("DD/MM/YYYY");
                        return (
                          ticket.id !== "new" &&
                          !hasPermission(
                            permissionNames,
                            permissions.UpdateTicketDates
                          ) &&
                          !isSameDayAndYear
                        );
                      }}
                      minDate={minDateRequested}
                      activeStartDate={minDateRequested}
                      value={currentDateRequested}
                      onChange={(date) =>
                        this.handleInputChange({
                          target: { name: "requestedDate", value: date },
                        })
                      }
                    />
                  </div>

                  <div
                    style={{ flex: "1", display: "flex", alignItems: "center" }}
                  >
                    <div style={{ margin: "0 10px" }}>Heure</div>
                    <Input
                      disabled={
                        ticket.id !== "new" &&
                        !hasPermission(
                          permissionNames,
                          permissions.UpdateTicketDates
                        )
                      }
                      type="time"
                      name="requestedTimeRangeStart"
                      value={ticket.requestedTimeRangeStart}
                      onChange={this.handleInputChange}
                    />
                    {/* <div style={{ margin: '0 10px' }}>et</div>
                                        <Input type="time" name="requestedTimeRangeEnd" value={ticket.requestedTimeRangeEnd || ''} onChange={this.handleInputChange} /> */}
                  </div>
                </div>
              </div>
            </div>

            {ticket.id !== "new" && (
              <div>
                <div style={{ display: "flex", marginBottom: "20px" }}>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      flex: "1",
                      border: "1px solid #CCC",
                      background: "white",
                      padding: "15px",
                      height: "400px",
                      marginRight: "10px",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        borderBottom: "1px solid #DDD",
                        paddingBottom: "10px",
                        marginBottom: "15px",
                      }}
                    >
                      <div
                        style={{
                          flex: "1",
                          fontSize: "20px",
                          color: color.gold,
                          textTransform: "uppercase",
                        }}
                      >
                        Date de déplacement linéaire planifiée
                      </div>
                    </div>

                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        flex: "1",
                        overflowY: "auto",
                        fontSize: "14px",
                        color: "#222",
                      }}
                    >
                      <Calendar
                        name="expectedDate"
                        tileDisabled={({ activeStartDate, date, view }) => {
                          const isSameDayAndYear =
                            moment(date).format("DD/MM/YYYY") ===
                            moment(currentDateExpected).format("DD/MM/YYYY");
                          return (
                            ticket.id !== "new" &&
                            !hasPermission(
                              permissionNames,
                              permissions.UpdateTicketDates
                            ) &&
                            !isSameDayAndYear
                          );
                        }}
                        minDate={minDateRequested}
                        activeStartDate={minDateRequested}
                        value={
                          ticket.expectedDateYear &&
                          ticket.expectedDateDayOfYear
                            ? moment()
                                .year(ticket.expectedDateYear)
                                .dayOfYear(ticket.expectedDateDayOfYear)
                                .toDate()
                            : null
                        }
                        onChange={(date) =>
                          this.handleInputChange({
                            target: { name: "expectedDate", value: date },
                          })
                        }
                      />
                    </div>
                  </div>

                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      flex: "1",
                      border: "1px solid #CCC",
                      background: "white",
                      padding: "15px",
                      height: "400px",
                      marginLeft: "10px",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        borderBottom: "1px solid #DDD",
                        paddingBottom: "10px",
                        marginBottom: "15px",
                      }}
                    >
                      <div
                        style={{
                          flex: "1",
                          fontSize: "20px",
                          color: color.gold,
                          textTransform: "uppercase",
                        }}
                      >
                        Créneau horaire planifié
                      </div>
                    </div>

                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        flex: "1",
                        overflowY: "auto",
                        fontSize: "14px",
                        color: "#222",
                      }}
                    >
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <div style={{ margin: "0 10px" }}>Heure</div>
                        <Input
                          disabled={
                            !hasPermission(
                              permissionNames,
                              permissions.UpdateTicketDates
                            )
                          }
                          type="time"
                          name="expectedTimeRangeStart"
                          value={ticket.expectedTimeRangeStart || ""}
                          onChange={this.handleInputChange}
                        />
                        {/* <div style={{ margin: '0 10px' }}>et</div>
                                                <Input disabled={!hasPermission(permissionNames, permissions.UpdateTicketDates)} type="time" name="expectedTimeRangeEnd" value={ticket.expectedTimeRangeEnd || ''} onChange={this.handleInputChange} /> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </Form>
        </div>
        {/* {ticket.id !== 'new' && <TicketCommentsSidePanel ticket={ticket} />} */}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  settings: state.settings,
  tickets: state.tickets,
  hardwareTypes: state.hardwareTypes,
  customers: state.customers,
  authentication: state.authentication,
});

export default connect(mapStateToProps)(Radium(MoveDetail));
