import { authenticate as authenticateApi } from '../api/authentication';
import { getResponseExceptionMessage } from '../api/apiSettings';
import { getProfile } from '../api/account';

export const authenticateWithToken = (login, token, encryptedToken) => {
    return async (dispatch, getState) => {
        dispatch(authenticateWithTokenRequest());
        const result = {};
        result.login = login;

        try {
            if (token){
                const apiResult = await getProfile(token);
                if (apiResult && apiResult.data && apiResult.data.result && apiResult.data.result.user){
                    result.token = token;
                    result.encryptedToken = encryptedToken;
                    result.user = apiResult.data.result.user;
                }

            }
        }
        catch (ex) {
            result.error = getResponseExceptionMessage(ex);
        }
        dispatch(authenticateWithTokenResponse(result));
    };
};

const authenticateWithTokenRequest = () => {
    return {
        type: 'AUTHENTICATE_WITH_TOKEN_REQUEST'
    };
};

const authenticateWithTokenResponse = (input) => {
    return {
        ...input,
        type: 'AUTHENTICATE_WITH_TOKEN_RESPONSE'
    };
};

export const authenticate = (login, password, tenancyName) => {

    return async (dispatch, getState) => {

        dispatch(authenticateRequest());

        const result = {};

        try {
            const apiResult = await authenticateApi(login, password, tenancyName);

            if (apiResult.data.result.accessToken) {
                
                localStorage.setItem('loginInfo', JSON.stringify({ token: apiResult.data.result.accessToken, encryptedToken: apiResult.data.result.encryptedAccessToken, login: login }));
                
                result.token = apiResult.data.result.accessToken;
                result.encryptedToken = apiResult.data.result.encryptedAccessToken;
                result.login = login;
                
                const userIdentityApiResult = await getProfile(result.token);

                if (userIdentityApiResult && userIdentityApiResult.data && userIdentityApiResult.data.result && userIdentityApiResult.data.result.user){
                    result.user = userIdentityApiResult.data.result.user;
                }
            }
        }
        catch (ex) {
            result.error = getResponseExceptionMessage(ex);
        }
        
        dispatch(authenticateResponse(result));
    };

};

const authenticateRequest = () => {
    return {
        type: 'AUTHENTICATE_REQUEST'
    };
};

const authenticateResponse = (input) => {
    return {
        ...input,
        type: 'AUTHENTICATE_RESPONSE'
    };
};

export const logout = () => {

    return (dispatch, getState) => {

        localStorage.removeItem('loginInfo');
        dispatch(logoutAction());

    };

};

const logoutAction = () => {
    return {
        type: 'LOGOUT'
    };
};

export const setUserProfile = (user) => {
    return {
        type: 'SET_USER_PROFILE',
        user
    };
};

export const updateAuthenticationUser = (input) => {
    return {
        ...input,
        type: 'UPDATE_AUTHENTICATION_USER'
    };
};