import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Button } from 'reactstrap';
import Radium from 'radium';
import ReactTable from 'react-table';
import { getCustomers } from '../../actions/customers';
import NewCustomerModal from '../../components/NewCustomerModal';
import { pushModalAsync, dismissModalAsync } from '../../actions/modals';

class Customer extends Component {

    componentDidMount() {
        this.props.dispatch(getCustomers());
    }

    handleAddCustomer = () => {

        this.props.dispatch(pushModalAsync(NewCustomerModal, {

            onClose: async(modalId, modalResult) => {

                this.props.dispatch(dismissModalAsync(modalId));
                
                if (modalResult){
                }
            }
        }));
    };

    render() {

        const { customers } = this.props;
        const styles = {
            title: {
                textAlign: 'center',
                padding: '10px'
            },
            addClient: {
                padding: '0 0 5px 0'
            }
        };
        
        const columns = [{
            Header: 'Nom du magasin',
            accessor: 'name'
          },
          {
            Header: 'Code',
            accessor: 'code'
          },
          {
            Header: 'Nom du contact magasin',
            accessor: 'managerName'
          },
          {
            Header: 'Fonction',
            accessor: 'function'
          },
          {
            Header: 'Adresse',
            accessor: 'address'
          },
          {
            Header: 'Code postal',
            accessor: 'postalCode'
          },
          {
            Header: 'Ville',
            accessor: 'city'
          },
          {
            Header: 'Pays',
            accessor: 'country'
          },
          {
            Header: 'Téléphone Magasin',
            accessor: 'phone'
          },
          {
            Header: 'Téléphone Contact',
            accessor: 'contactPhone'
          },
          {
            Header: 'Fax PC',
            accessor: 'faxPC'
          },
          {
            Header: 'Email',
            accessor: 'email'
          },
          {
            Header: 'Groupe',
            accessor: 'customerGroup.name'
          },
          {
            Header: 'Terroir d\'Écosse',
            accessor: 'isTerroirEcosse',
            Cell: props => (<div>{props.value ? 'Oui' : 'Non'}</div>),
            filterMethod: (filter, row) => {
                if (filter.value === "all") {
                  return true;
                }
                if (filter.value === "true") {
                    return row[filter.id] === true;
                }
                return row[filter.id] === false || row[filter.id] === null;
              },
              Filter: ({ filter, onChange }) =>
                <select onChange={event => onChange(event.target.value)} style={{ width: "100%" }} value={filter ? filter.value : "all"}>
                    <option value="all">Tous</option>
                    <option value="true">Oui</option>
                    <option value="false">Non</option>
                </select>
          }];

          return (
            <div style={{ padding: '20px' }}>
                <h1 style={styles.title}>Gestion des magasins</h1>
                <div style={styles.addClient}>
                    <Button color="primary" onClick={this.handleAddCustomer}>Ajouter un magasin</Button>
                </div>
                {customers && customers.items && (
                    <ReactTable 
                        data={customers.items}
                        columns={columns}
                        filterable
                        minRows={10}
                        noDataText="Aucun magasin"
                        ofText="sur"
                        nextText="Suivant"
                        previousText="Précédent"
                        rowsText="lignes"
                        defaultFilterMethod={(filter, row) => {
                            if (!filter.value) {
                                return true;
                            }
                            return row[filter.id] && row[filter.id].toLowerCase().indexOf(filter.value.toLowerCase()) !== -1;
                        }}
                        getTrProps={(state, rowInfo, column, instance) => ({
                            onClick: (e, handleOriginal) => {
                                this.props.dispatch(pushModalAsync(NewCustomerModal, {
                                    customer: rowInfo.original,
                                    onClose: async(modalId, modalResult) => {
                                        this.props.dispatch(dismissModalAsync(modalId));
                                        if (modalResult){
                                        }
                                    }
                                }));
                            }
                        })}
                    />
                )}
            </div>
        );
    }
}

const mapStateToProps = state => ({
    authentication: state.authentication,
    customers: state.customers
});

export default connect(mapStateToProps)(Radium(Customer));