import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap';
import { dismissModalAsync } from '../actions/modals';
import PropTypes from 'prop-types';

class DisplayContentModal extends Component{

    static defaultProps = {
        title: null,
        content: null,
        contentAlign: null,
        isOpen: true,
        modalId: null
    }

    dismiss = async () => {
        await this.props.dispatch(dismissModalAsync(this.props.modalId));
    };

    render(){
        const { isOpen, title, content, contentAlign } = this.props;
        const style = {
            body: {
                ...(contentAlign ? { textAlign: contentAlign } : null ),
                ...(contentAlign === 'center' ? { margin: "0 auto" } : null )
            }
        }
        return (
            <Modal isOpen={isOpen} toggle={this.dismiss} size="lg">
                {title &&
                    <ModalHeader>
                        {title}
                    </ModalHeader>
                }
                <ModalBody style={style.body}>
                    {content}
                </ModalBody>
                <ModalFooter>
                    <Button color="secondary" onClick={this.dismiss}>Fermer</Button>
                </ModalFooter>
            </Modal>
        );
    }
}

DisplayContentModal.propTypes = {
    title: PropTypes.string,
    content: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.object,
    ]),
    contentAlign: PropTypes.string,
    isOpen: PropTypes.bool,
    modalId: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
    ]),
};

export default connect() (DisplayContentModal);