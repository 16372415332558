export const afterSaleTicketStatusType = {
    pending: 1,
    scheduled: 2,
    // doing: 3,
    done: 4,
    rejected: 5
};

export const afterSaleTicketStatusTypeName = {
    [afterSaleTicketStatusType.pending]: 'En attente de réponse',
    [afterSaleTicketStatusType.scheduled]: 'Planifié',
    // [afterSaleTicketStatusType.doing]: 'En cours',
    [afterSaleTicketStatusType.done]: 'Terminé',
    [afterSaleTicketStatusType.rejected]: 'Annulé'
};