import axios from 'axios';
import * as ApiSettings from './apiSettings';
import { getDefaultHeaders } from './apiSettings';

export const authenticate = (login, password, tenancyName) => {
    
    return axios.post(`${ApiSettings.apiBaseUrl}/api/tokenauth/authenticate`, {
        userNameOrEmailAddress: login,
        password: password,
        rememberClient: true,
        tenancyName: tenancyName
    }, {
        headers: {
            ...getDefaultHeaders()
        }
    });

};