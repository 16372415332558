import { handleActions } from 'redux-actions';

const initialState = {
    customerGroup: null,
    customerGroups: null,
    countCustomerGroups: null
};

export const customerGroups = handleActions(
    {
        GET_CUSTOMER_GROUP_RESPONSE: (state, action) => ({
            ...state,
            customerGroup: action
        }),
        GET_ALL_CUSTOMER_GROUPS_RESPONSE: (state, action) => ({
            ...state,
            customerGroups: action.payload.data.items,
            countCustomerGroups: action.payload.data.totalCount
        }),
        UPDATE_CUSTOMER_GROUP_RESPONSE: (state, action) => ({
            ...state,
            customerGroup: action,
        })    
    },
    initialState
);