const initialState = {
    token: null,
    encryptedToken: null,
    login: '',
    password: '',
    isAuthenticating: false,
    error: null,
    user: null
};

export const authentication = (state = initialState, action) => {

    switch (action.type){

        case 'AUTHENTICATE_WITH_TOKEN_REQUEST':

            return {
                ...state,
                token: null,
                encryptedToken: null,
                error: null,
                isAuthenticating: true,
                user: null
            };

        case 'AUTHENTICATE_WITH_TOKEN_RESPONSE':

            return {
                ...state,
                login: action.login,
                token: (!action.error ? action.token : null),
                encryptedToken: (!action.error ? action.encryptedToken : null),
                error: action.error,
                isAuthenticating: false,
                user: action.user
            };

        case 'AUTHENTICATE_REQUEST':

            return {
                ...state,
                isAuthenticating: true,
                error: null
            };

        case 'AUTHENTICATE_RESPONSE':

            return {
                ...state,
                login: action.login,
                token: action.token,
                encryptedToken: action.encryptedToken,
                error: action.error,
                isAuthenticating: false,
                user: action.user
            };

        case 'LOGOUT':

            return {
                ...state,
                token: null,
                encryptedToken: null,
                error: null,
                isAuthenticating: false,
                user: null,
            };

        case 'SET_USER_PROFILE':

            return {
                ...state,
                user: action.user
            };

        case 'UPDATE_AUTHENTICATION_USER':

            return {
                ...state,
                user: {
                    ...state.user,
                    userName: action.userName,
                    surname: action.surname,
                    name: action.name,
                    emailAddress: action.emailAddress,
                    roleNames: action.roleNames,
                } 
            };

        default:
            break;
    }

    return state;

};