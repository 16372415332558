export const installationRequestStatusType = {
  pending: 1,
  validated: 2,
  rejected: 3,
};

export const installationRequestStatusTypeName = {
  [installationRequestStatusType.pending]: "En attente de réponse MHD",
  [installationRequestStatusType.validated]: "Demande validée par MHD",
  [installationRequestStatusType.rejected]: "Demande rejetée par MHD",
};

export const installationRequestInstoreStatusType = {
  pending: 1,
  scheduled: 2,
  done: 3,
  rejected: 4,
  removed: 5,
};

export const installationRequestInstoreStatusTypeName = {
  [installationRequestInstoreStatusType.pending]: "En attente de réponse",
  [installationRequestInstoreStatusType.scheduled]: "Planifiée",
  [installationRequestInstoreStatusType.done]: "Réalisée",
  [installationRequestInstoreStatusType.rejected]: "Annulée",
  [installationRequestInstoreStatusType.removed]: "Retirée",
};
