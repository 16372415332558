import React from 'react';

class ImageComponent extends React.Component{

    state = { displayedSrc: null, opacitySwitch: true };
    
    image = null;

    componentDidMount(){

        if (this.props.src) this.preload(this.props.src);

    }

    componentDidUpdate(prevProps, prevState, prevContext){

        if (this.props.src !== prevProps.src){

            if (this.props.src) this.preload(this.props.src);

        }

    }

    componentWillUnmount(){

        this.image = null;

    }

    preload(src){

        this.setState({ displayedSrc: this.props.placeholder });

        this.image = new Image();
        this.image.onload = this.imageLoaded;
        this.image.src = src;
        
    }

    imageLoaded = () => {
        
        if (this.image){
            
            if (this.props.onLoad){
                this.props.onLoad({ naturalWidth: this.image.naturalWidth, naturalHeight: this.image.naturalHeight });
            }

            this.setState({ opacitySwitch: false, displayedSrc: this.image.src });

            setTimeout(() => {
                if (this.image){
                    this.setState({ opacitySwitch: true })
                }
            });

        }
    }

    render(){
        
        const style = {
            opacity: this.state.opacitySwitch ? 1 : 0,
            transition: this.state.opacitySwitch ? 'opacity 1s' : 'opacity 0s', 
            backgroundImage: this.state.displayedSrc ? `url('${this.state.displayedSrc}')` : 'none',
            backgroundRepeat: 'no-repeat',
            backgroundSize: this.state.displayedSrc === this.props.placeholder ? '25%' : this.props.aspect || 'contain',
            backgroundPosition: '50% 50%',
            ...this.props.style
        };

        return (
            <div {...this.props} style={style}></div>
        );

    }

}

export default ImageComponent;