import Enumerable from 'linq';

const initialState = {
    items: []
};

export const modals = (state = initialState, action) => {

    switch (action.type){

        // case '@@router/LOCATION_CHANGE':

        //     return {
        //         ...state,
        //         items: []
        //     };

        case 'PUSH_MODAL':

            const item = { 
                id: action.id, 
                component: action.component, 
                props: {
                    ...action.props, 
                    modalId: action.id,
                    isOpen: true
                } 
            };

            if (action.props.onClose) {
                item.props.onClose =  (parameter) => {
                    action.props.onClose(item.id, parameter);
                };
            }

            return {
                ...state,
                items: [...state.items, item]
            };

        case 'DISMISS_MODAL_START': {

            const items = [...state.items];

            const item = Enumerable.from(items).firstOrDefault(o => o.id === action.modalId);
            const itemIndex = items.indexOf(item);

            items.splice(itemIndex, 1, {...item, props: { ...item.props, isOpen: false }});

            return {
                ...state,
                items: items
            };
        }

        case 'DISMISS_MODAL_END': {

            const items = [...state.items];

            const item = Enumerable.from(items).firstOrDefault(o => o.id === action.modalId);
            const itemIndex = items.indexOf(item);

            items.splice(itemIndex, 1);

            return {
                ...state,
                items: items
            };
        }

        default:
        break;
    }

    return state;

};