import React, { Component } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap';

class ConfirmModal extends Component{

    dismiss = (modalResult) => {

        this.props.onClose(modalResult);

    };

    render(){
        
        const { isOpen, title, message, yesButtonLabel, noButtonLabel } = this.props;

        return (
            <Modal isOpen={isOpen} toggle={() => this.dismiss(false)}>

                <ModalHeader>
                    {title}
                </ModalHeader>

                <ModalBody>

                    {message}

                </ModalBody>

                <ModalFooter>

                    <Button color="secondary" onClick={() => this.dismiss(false)}>{noButtonLabel}</Button>{' '}
                    <Button color="primary" onClick={() => this.dismiss(true)}>{yesButtonLabel}</Button>

                </ModalFooter>

            </Modal>
        );

    }

}


export default ConfirmModal;