import React, { Component } from 'react';
import { connect } from 'react-redux';
import { CustomInput, Modal, ModalHeader, ModalBody, ModalFooter, Input, Label, Form, FormGroup, Button } from 'reactstrap';
import { getHardwareTypes, createHardwareType, updateHardwareType, deleteHardwareType } from '../actions/hardwareTypes';
import Radium from 'radium';
import { pushModalAsync, dismissModalAsync } from '../actions/modals';
import ConfirmModal from '../components/ConfirmModal';
import { getAllCustomerGroups } from '../actions/customerGroups';
import { getAllRoles } from '../actions/roles';

const emptyHardwareType = {
    id: 'new',
    name: '',
    hardwareTypeRoleIds: []
};

class NewHardwareTypeModal extends Component{

    state = {
        isBusy: false,
        error: null,
        hardwareType: {
            ...emptyHardwareType,
            ...this.props.hardwareType
        }
    };

    componentDidMount() {
        this.props.dispatch(getAllCustomerGroups());
        this.props.dispatch(getAllRoles());
    }

    dismiss = (modalResult) => {
        this.props.onClose(modalResult);
    };

    handleInputChange = (e) => {
        const propName = e.target.name;
        let propValue = e.target.value;
        this.setState((prevState, prevProps) => ({
            hardwareType: { 
                ...prevState.hardwareType,
                [propName]: propValue 
            }
        }));
    };

    handleSubmit = (e) => {
        e.preventDefault();

        if (this.isFormInvalid() || this.state.isBusy) {
            return;
        }

        this.setState({ isBusy: true, error: null }, async() => {
            let saveResult = null;
            if(this.state.hardwareType.id !== "new"){
                saveResult = await this.props.dispatch(updateHardwareType(this.state.hardwareType));
            }
            else{
                saveResult = await this.props.dispatch(createHardwareType(this.state.hardwareType));
            }

            if (!saveResult.error){
                this.props.dispatch(getHardwareTypes());
                this.props.onClose();
            }
            else {
                console.log(saveResult.error);
                this.setState({ error: saveResult.error });
            }
            this.setState({ isBusy: false });
        });
    };

    handleDelete = (e) => {
        e.preventDefault();

        if (this.isFormInvalid() || this.state.isBusy) {
            return;
        }

        this.props.dispatch(pushModalAsync(ConfirmModal, {
            title: 'Supprimer un type de configuration',
            message: 'Êtes-vous sûr de vouloir supprimer ce type de configuration ?',
            yesButtonLabel: 'OUI',
            noButtonLabel: 'NON',
            onClose: (modalId, modalResult) => {

                this.props.dispatch(dismissModalAsync(modalId));

                if (modalResult){

                    this.setState({
                        error: null, 
                        isBusy : true
            
                    }, async() => {
                        
                        const deleteResponse = await this.props.dispatch(deleteHardwareType(this.state.hardwareType.id));
            
                        if (!deleteResponse.error){
                            this.props.dispatch(getHardwareTypes());
                            this.props.onClose();
                        }
                        else {
                            this.setState({ error: deleteResponse.error, isBusy: false });
                        }
                    });
                }
            }
        }));
    };

    hasRole = (roleId) => {
        if (this.state.hardwareType && this.state.hardwareType.hardwareTypeRoleIds){
            return this.state.hardwareType.hardwareTypeRoleIds.indexOf(roleId) !== -1;
        }
        return false;
    };

    handleRoleCheckboxChange = (e, roleId) => {
        const propValue = e.target.checked;
        const roleIds = [
            ...this.state.hardwareType.hardwareTypeRoleIds
        ];

        if (propValue){
            roleIds.push(roleId);
        }
        else {
            const roleIndex = roleIds.indexOf(roleId);
            if (roleIndex !== -1) {
                roleIds.splice(roleIndex, 1);
            }
        }

        this.setState((prevState, props) => ({ 
            hardwareType: {
                ...prevState.hardwareType,
                hardwareTypeRoleIds: roleIds
            }
        }));
    };

    isFormInvalid = () => !this.state.hardwareType.name;

    render(){
        const isFormInvalid = this.isFormInvalid();
        const { isOpen, roles } = this.props;

        return (
            <Modal isOpen={isOpen} toggle={() => this.dismiss(false)}>

                <ModalHeader>
                    Ajouter un type de configuration
                </ModalHeader>

                <ModalBody>
                    <Form onSubmit={this.handleSubmit}>
                        <FormGroup>
                            <Label for="text">Nom</Label>
                            <Input id="text" type='text' name="name" value={this.state.hardwareType.name} onChange={this.handleInputChange} autoFocus />
                        </FormGroup>
                        <FormGroup>
                            <Label for="roles">Roles qui recevront les emails</Label>
                            {roles && roles.items && roles.count > 0 && 
                                roles.items.map((role, key) => {
                                    if(role.normalizedName === 'ADMIN'){
                                        return null;
                                    }
                                    return(
                                        <CustomInput key={role.id} id={role.id} type="checkbox" label={role.displayName} 
                                            checked={this.hasRole(role.id)}
                                            onChange={(e) => this.handleRoleCheckboxChange(e, role.id)}
                                        />
                                    )
                                }
                            )}
                        </FormGroup>
                    </Form>
                </ModalBody>

                <ModalFooter>
                    {this.state.hardwareType.id !== "new" && <Button type="button" color="danger" onClick={this.handleDelete}>Supprimer le matériel</Button>}
                    <Button color="secondary" onClick={this.dismiss}>Annuler</Button>{' '}
                    <Button color="primary" onClick={this.handleSubmit} disabled={isFormInvalid}>Valider</Button>
                </ModalFooter>

            </Modal>
        );
    }
}

const mapStateToProps = state => ({
    authentication: state.authentication,
    customerGroups: state.customerGroups,
    roles: state.roles
});

export default connect(mapStateToProps)(Radium(NewHardwareTypeModal));