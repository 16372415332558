import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Form, FormGroup, Label, Input, Alert, CustomInput } from 'reactstrap';
import { getAllUsers, createUser, updateUser, deleteUser } from '../../actions/users';
import { updateAuthenticationUser } from '../../actions/authentication';
import Radium from 'radium';
import { pushModalAsync, dismissModalAsync } from '../../actions/modals';
import ConfirmModal from '../../components/ConfirmModal';
import { getAllRolesApi } from '../../api/roles';
import { getAllCustomerGroups } from '../../actions/customerGroups';

class EditUserModal extends Component {

    state = { 
        user: {
            ...this.props.user,
            password: '',
            passwordConfirmation: '',
        },
        mustFillPassword: !this.props.user.id,
        roles: null,
        userGroups: this.props.user.userGroups
    };

    componentWillMount = (e) => {
        this.getAllRoles();
        this.props.dispatch(getAllCustomerGroups());
        let userGroupIdsOfUser = [];
        this.state.userGroups.forEach(userGroup => {
            userGroupIdsOfUser.push(userGroup.customerGroupId);
        });
        this.setState((prevState, props) => ({ 
            user: {
                ...prevState.user,
                userGroups: userGroupIdsOfUser
            }
        }));
    }

    handleInputChange = (e) => {
        const propName = e.target.name;
        const propValue = e.target.value;
        if (propName === 'isActive') {
            this.setState((prevState, prevProps) => ({
                user: {
                    ...prevState.user,
                    'isActive': !prevState.user.isActive,
                }
            }));
        }
        else{
            this.setState((prevState, props) => ({ 
                user: {
                    ...prevState.user,
                    [propName]: propValue 
                }
            }));
        }
    };

    hasRole = (role) => {
        if (this.state.user && this.state.user.roleNames){
            return this.state.user.roleNames.indexOf(role) !== -1;
        }
        return false;
    };

    hasGroup = (group) => {
        if (this.state.user && this.state.user.userGroups){
            return this.state.user.userGroups.indexOf(group.id) !== -1;
        }
        return false;
    };

    handleRoleCheckboxChange = (e, role) => {
        const propValue = e.target.checked;
        const roles = [
            ...this.state.user.roleNames
        ];

        if (propValue){
            roles.push(role);
        }
        else {
            const roleIndex = roles.indexOf(role);
            if (roleIndex !== -1) {
                roles.splice(roleIndex, 1);
            }
        }

        this.setState((prevState, props) => ({ 
            user: {
                ...prevState.user,
                roleNames: roles
            }
        }));
    };

    handleGroupCheckboxChange = (e, group) => {
        const propValue = e.target.checked;
        const groups = [
            ...this.state.user.userGroups
        ];

        if (propValue){
            groups.push(group);
        }
        else {
            const groupIndex = groups.indexOf(group);
            if (groupIndex !== -1) {
                groups.splice(groupIndex, 1);
            }
        }

        this.setState((prevState, props) => ({ 
            user: {
                ...prevState.user,
                userGroups: groups
            }
        }));
    };

    deleteUser = () => {
        this.props.dispatch(pushModalAsync(ConfirmModal, {
            title: 'Supprimer utilisateur',
            message: 'Êtes-vous sûr de vouloir supprimer cet utilisateur ?',
            yesButtonLabel: 'OUI',
            noButtonLabel: 'NON',
            onClose: (modalId, modalResult) => {
                this.props.dispatch(dismissModalAsync(modalId));
                if (modalResult){
                    this.setState({
                        error: null, 
                        isBusy : true
                    }, async() => {
                        const deleteResponse = await this.props.dispatch(deleteUser(this.state.user.id));
                        if (!deleteResponse.error){
                            this.props.dispatch(getAllUsers());
                            this.props.onClose();
                        }
                        else {
                            this.setState({ error: deleteResponse.error, isBusy: false });
                        }
                    });
                }
            }
        }));
    };

    handleSubmit = (e) => {
        e.preventDefault();
        if (this.isFormInvalid() || this.state.isBusy){
            return;
        }
        this.setState({
            error: null, 
            isBusy : true
        }, async() => {
            const user = {
                ...this.state.user
            };
            let saveResponse = null;

            if (user.id){
                saveResponse = await this.props.dispatch(updateUser(user));
                if(saveResponse.data && this.props.authentication && this.props.authentication.user
                && user.id && user.id === this.props.authentication.user.id){
                    await this.props.dispatch(updateAuthenticationUser(user));
                }
            }
            else {
                saveResponse = await this.props.dispatch(createUser(user));
            }

            if (!saveResponse.error){
                this.props.dispatch(getAllUsers());
                this.props.onClose();
            }
            else {
                this.setState({ error: saveResponse.error, isBusy: false });
            }
        });
    };

    getAllRoles = async () => {
        try {
            const { authentication } = this.props;  
            const getAllRolesResult = await getAllRolesApi(authentication.token);
            if(getAllRolesResult && getAllRolesResult.data && getAllRolesResult.data.result){
                this.setState({ roles: getAllRolesResult.data.result });
            }
        } catch (error) {
            console.log(error);
        }
    };

    isFormInvalid = () => !this.state.user.userName || !this.state.user.emailAddress || (this.state.mustFillPassword && !this.state.user.password) || (this.state.mustFillPassword && !this.state.user.passwordConfirmation) || (this.state.mustFillPassword && this.state.user.password !== this.state.user.passwordConfirmation);

    render() {
        const { roles } = this.state;
        const { isOpen, onClose, customerGroups, authentication } = this.props;
        const isFormInvalid = this.isFormInvalid();
        // Disable form if we want to update "ADMIN" users except if current user has the role "ADMIN"
        const currentUserIsAdmin = authentication.user.roleNames.indexOf("ADMIN") !== -1;
        const updateUserIsAdmin = this.hasRole("ADMIN");

        return (
            <Modal isOpen={isOpen} toggle={!this.state.isBusy ? onClose : null} size="lg">
                <ModalHeader toggle={!this.state.isBusy ? onClose : null}>
                    {this.state.user.id ? 'Détails utilisateur' : 'Nouvel utilisateur'}
                </ModalHeader>

                <ModalBody>
                    <Form onSubmit={this.handleSubmit}>
                        <fieldset disabled={updateUserIsAdmin && !currentUserIsAdmin}>
                            <div style={{width: '50%', float: 'left', padding: '0 10px'}}>
                                <FormGroup>
                                    <Label for="userName">Nom d'utilisateur</Label>
                                    <Input id="userName" name="userName" placeholder="Nom d'utilisateur" value={this.state.user.userName} onChange={this.handleInputChange}
                                        disabled={this.state.isBusy} />
                                </FormGroup>

                                <FormGroup>
                                    <Label for="surname">Nom</Label>
                                    <Input id="surname" name="surname" placeholder="Nom" value={this.state.user.surname} onChange={this.handleInputChange}
                                        disabled={this.state.isBusy} />
                                </FormGroup>

                                <FormGroup>
                                    <Label for="name">Prénom</Label>
                                    <Input id="name" name="name" placeholder="Prénom" value={this.state.user.name} onChange={this.handleInputChange}
                                        disabled={this.state.isBusy} />
                                </FormGroup>

                                <FormGroup>
                                    <Label for="phoneNumber">N° de téléphone</Label>
                                    <Input id="phoneNumber" name="phoneNumber" placeholder="N° de téléphone" value={this.state.user.phoneNumber} onChange={this.handleInputChange}
                                        disabled={this.state.isBusy} />
                                </FormGroup>

                                <FormGroup>
                                    <Label for="emailAddress">E-mail</Label>
                                    <Input id="emailAddress" name="emailAddress" type="email" placeholder="E-mail" value={this.state.user.emailAddress} onChange={this.handleInputChange}
                                        disabled={this.state.isBusy} />
                                </FormGroup>

                                {this.state.user.id && !this.state.mustFillPassword && (
                                    <Button type="button" style={{ margin: '15px 0' }} color="warning" size="sm" onClick={() => this.setState({ mustFillPassword: true}) }>Modifier mot de passe</Button>
                                )}

                                {this.state.mustFillPassword && <FormGroup>
                                    <Label for="password">Mot de passe</Label>
                                    <Input id="password" name="password" type="password" placeholder="Mot de passe" value={this.state.user.password} onChange={this.handleInputChange}
                                        disabled={this.state.isBusy} />
                                </FormGroup>}

                                {this.state.mustFillPassword && <FormGroup>
                                    <Label for="passwordConfirmation">Confirmation mot de passe</Label>
                                    <Input id="passwordConfirmation" name="passwordConfirmation" type="password" placeholder="Confirmation mot de passe" value={this.state.user.passwordConfirmation} onChange={this.handleInputChange}
                                        disabled={this.state.isBusy} />
                                </FormGroup>}

                                {this.state.mustFillPassword && this.state.user.password && this.state.user.passwordConfirmation && this.state.user.password !== this.state.user.passwordConfirmation && (
                                    <Alert color="danger" style={{ margin: '20px 0' }}>
                                        Le mot de passe et sa confirmation doivent être identiques
                                    </Alert>
                                )}
                            </div>
                        
                            <div style={{width: '50%', float: 'right', padding: '0 10px'}}>
                                <FormGroup>
                                    <Label for="roles">Roles</Label>
                                    {roles && roles.items && roles.totalCount > 0 && 
                                        roles.items.map((role, key) => {
                                            if(role.normalizedName === 'ADMIN'){
                                                return null;
                                            }
                                            return(
                                                <CustomInput key={role.id} id={role.id} type="checkbox" label={role.displayName} 
                                                    checked={this.hasRole(role.normalizedName)} onChange={(e) => this.handleRoleCheckboxChange(e, role.normalizedName)} />
                                            )
                                        }
                                    )}
                                </FormGroup>

                                <FormGroup>
                                    <Label for="roles">Actif</Label>
                                    <CustomInput name='isActive' id='isActive' type="checkbox" label="Oui" 
                                                    checked={this.state.user.isActive} onChange={this.handleInputChange} />
                                </FormGroup>

                                <FormGroup>
                                    <Label for="roles">Groupes</Label>
                                    {customerGroups && customerGroups.customerGroups && 
                                        customerGroups.customerGroups.map((customerGroup, key) => {
                                            return(
                                                <CustomInput key={customerGroup.id} id={customerGroup.id} type="checkbox" label={customerGroup.name} 
                                                    checked={this.hasGroup(customerGroup)} onChange={(e) => this.handleGroupCheckboxChange(e, customerGroup.id)} />
                                            )
                                        }
                                    )}
                                </FormGroup>
                            </div>
                          
                            <div style={{ clear: 'both', marginTop: '20px' }}>
                                {this.state.isBusy && (
                                    <Alert color="info">
                                        Merci de patienter...
                                    </Alert>
                                )}

                                {this.state.error && (
                                    <Alert color="danger">
                                        {this.state.error}
                                    </Alert>
                                )}
                            </div>
                        </fieldset>
                    </Form>
                </ModalBody>

                <ModalFooter>
                    {this.state.user.id && 
                        (
                            <Button type="button" color="danger" onClick={this.deleteUser}>Supprimer utilisateur</Button>
                        )
                    }
                    <Button color="secondary" onClick={onClose} disabled={this.state.isBusy}>Annuler</Button>{' '}
                    <Button color="primary" onClick={this.handleSubmit} disabled={isFormInvalid || this.state.isBusy}>Valider</Button>
                </ModalFooter>
            </Modal>
        );
    }
}

const mapStateToProps = state => ({
    authentication: state.authentication,
    customerGroups: state.customerGroups
});

export default connect(mapStateToProps)(Radium(EditUserModal));