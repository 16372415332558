import { handleActions } from 'redux-actions';

const initialState = {
    user: null,
    users: null,
    countUsers: null
};

export const users = handleActions(
    {
        GET_USER_RESPONSE: (state, action) => ({
            ...state,
            user: action
        }),
        GET_ALL_USERS_RESPONSE: (state, action) => ({
            ...state,
            users: action.payload.data.items,
            countUsers: action.payload.data.totalCount
        }),
        UPDATE_USER_RESPONSE: (state, action) => ({
            ...state,
            user: action,
        })    
    },
    initialState
);