import React, { Component } from "react";
import { connect } from "react-redux";
import { Switch, Route } from "react-router-dom";
import { push } from "connected-react-router";
import Image from "../components/ImageComponent";
import {
  DropdownMenu,
  UncontrolledDropdown,
  DropdownItem,
  DropdownToggle,
} from "reactstrap";
import { logout } from "../actions/authentication";
import Radium from "radium";
import LeftMenuItem from "./ManagerChromeLeftMenuItem";
import {
  faBars,
  faTachometerAlt,
  faList,
  faPlusCircle,
  faPhone,
  faMailBulk,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Breadcrumb from "./Breadcrumb";
import { pushModalAsync, dismissModalAsync } from "../actions/modals";
import EditProfileModal from "../routes/profile/EditProfileModal";
import { faUser } from "@fortawesome/free-regular-svg-icons";
import AfterSaleList from "../routes/sav/AfterSaleList";
import Customer from "../routes/customer/Customer";
import Hardware from "../routes/hardware/Hardware";
import HardwareType from "../routes/hardware_type/HardwareType";
import AfterSaleDetail from "../routes/sav/AfterSaleDetail";
import Dashboard from "../routes/dashboard/Dashboard";
import InstallationList from "../routes/installation/InstallationList";
import InstallationDetail from "../routes/installation/InstallationDetail";
import InstallationTracking from "../routes/installation/InstallationTracking";
import MoveList from "../routes/move/MoveList";
import MoveDetail from "../routes/move/MoveDetail";
import MoveTracking from "../routes/move/MoveTracking";
import Users from "../routes/users/Users";
import CustomerGroup from "../routes/customer_group/CustomerGroup";
import Planning from "../routes/planning/Planning";
import { permissions } from "../helpers/permissions";
import Contacts from "../routes/contacts/Contacts";
import { getAllContacts } from "../actions/contacts";

class ManagerChrome extends Component {
  state = { isHamburgerMenuVisible: false };

  componentDidMount() {
    this.props.dispatch(getAllContacts());
  }

  editProfile = () => {
    this.props.dispatch(
      pushModalAsync(EditProfileModal, {
        user: this.props.authentication.user,
        onClose: (modalId, modalResult) => {
          this.props.dispatch(dismissModalAsync(modalId));
        },
      })
    );
  };

  render() {
    const { settings, authentication, contacts } = this.props;

    const styles = {
      root: {
        position: "absolute",
        width: "100%",
        height: "100%",
        overflow: "hidden",
        display: "flex",
        flexDirection: "column",
        "@media print": {
          height: "auto",
        },
      },

      header: {
        flex: "0 0 65px",
        backgroundColor: settings.data.chromeHeaderBackgroundColor,
        display: "flex",
        //boxShadow: '0px 5px 10px -5px #999',
        zIndex: 2,
        "@media(max-width: 800px)": {
          display: "none",
        },
      },

      mobileHeader: {
        flex: "0 0 50px",
        backgroundColor: settings.data.chromeHeaderBackgroundColor,
        display: "none",
        "@media(max-width: 800px)": {
          display: "flex",
        },
      },

      panelContainer: {
        flex: "1",
        display: "flex",
        overflow: "hidden",
      },

      leftPanel: {
        position: "relative",
        flex: "0 0 280px",
        backgroundColor: "#000",
        overflowY: "auto",
        paddingTop: "20px",
        "@media(max-width: 800px)": {
          position: "fixed",
          width: "80%",
          height: "100%",
          zIndex: 9999,
          display: this.state.isHamburgerMenuVisible ? "block" : "none",
        },
      },

      mainPanel: {
        flex: 1,
        overflow: "auto",
        backgroundColor: "#f5f5f5",
      },

      notificationsContainer: {
        position: "absolute",
        bottom: "10px",
        right: "10px",
      },

      menuBlockTitle: {
        color: "#B59759",
        padding: "5px 0 5px 10px",
      },
    };

    return (
      <div style={styles.root}>
        <div style={styles.header}>
          <div style={{ width: "240px", height: "100%", display: "flex" }}>
            <Image
              src={settings.data.logo}
              style={{
                width: "150px",
                height: "39px",
                alignSelf: "center",
                marginLeft: "20px",
              }}
            />

            <div style={{ margin: "20px", color: "white" }}>MagPlaner</div>
          </div>

          <Breadcrumb style={{ flex: "1" }} />

          <UncontrolledDropdown
            style={{
              alignSelf: "center",
              marginLeft: "auto",
              marginRight: "80px",
            }}
          >
            <DropdownToggle caret>
              {authentication.user
                ? `${authentication.user.name} ${authentication.user.surname}`
                : "Utilisateur"}
            </DropdownToggle>
            <DropdownMenu>
              {authentication.user &&
                authentication.user.canUserCreateProject && (
                  <DropdownItem
                    onClick={() => this.props.dispatch(push("/projects/new"))}
                  >
                    Nouveau projet
                  </DropdownItem>
                )}

              <DropdownItem onClick={this.editProfile}>Mon profil</DropdownItem>

              <DropdownItem onClick={() => this.props.dispatch(logout())}>
                Déconnexion
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
        </div>

        <div style={styles.mobileHeader}>
          <FontAwesomeIcon
            icon={faBars}
            style={{
              alignSelf: "center",
              color: "white",
              margin: "0 0 0 10px",
              cursor: "pointer",
            }}
            size="lg"
            onClick={() =>
              this.setState((prevState, props) => ({
                isHamburgerMenuVisible: !prevState.isHamburgerMenuVisible,
              }))
            }
          />

          <Image
            src={settings.data.logo}
            style={{
              flex: "1",
              width: "100px",
              height: "30px",
              alignSelf: "center",
            }}
          />

          <div style={{ margin: "20px", color: "white", alignSelf: "center" }}>
            MagPlaner
          </div>
        </div>

        <div style={styles.panelContainer}>
          <div style={styles.leftPanel}>
            <Route
              path="/"
              children={(routeProps) => (
                <LeftMenuItem
                  onClick={() => this.props.dispatch(push("/"))}
                  isActive={routeProps.match.isExact}
                >
                  <FontAwesomeIcon
                    icon={faTachometerAlt}
                    style={{ marginRight: "10px" }}
                  />
                  Tableau de bord
                </LeftMenuItem>
              )}
            />

            <Route
              path="/planning"
              children={(routeProps) => (
                <LeftMenuItem
                  onClick={() => this.props.dispatch(push("/planning"))}
                  isActive={routeProps.match && routeProps.match.isExact}
                >
                  <FontAwesomeIcon
                    icon={faTachometerAlt}
                    style={{ marginRight: "10px" }}
                  />
                  Planning
                </LeftMenuItem>
              )}
            />

            {authentication.user &&
              authentication.user.permissionNames &&
              (authentication.user.permissionNames.indexOf(
                permissions.CreateInstallationRequestTicket
              ) !== -1 ||
                authentication.user.permissionNames.indexOf(
                  permissions.PageInstallationRequestTickets
                ) !== -1 ||
                authentication.user.permissionNames.indexOf(
                  permissions.PageProgressTrackingTickets
                ) !== -1) && (
                <div style={styles.menuBlockTitle}>Installation</div>
              )}

            {authentication.user &&
              authentication.user.permissionNames &&
              authentication.user.permissionNames.indexOf(
                permissions.CreateInstallationRequestTicket
              ) !== -1 && (
                <Route
                  path="/installation/new"
                  children={(routeProps) => (
                    <LeftMenuItem
                      onClick={() =>
                        this.props.dispatch(push("/installation/new"))
                      }
                      isActive={routeProps.match && routeProps.match.isExact}
                    >
                      <FontAwesomeIcon
                        icon={faPlusCircle}
                        style={{ marginRight: "10px" }}
                      />
                      Demande d'installation
                    </LeftMenuItem>
                  )}
                />
              )}

            {authentication.user &&
              authentication.user.permissionNames &&
              authentication.user.permissionNames.indexOf(
                permissions.PageInstallationRequestTickets
              ) !== -1 && (
                <Route
                  path="/installation/list"
                  children={(routeProps) => (
                    <LeftMenuItem
                      onClick={() =>
                        this.props.dispatch(push("/installation/list"))
                      }
                      isActive={routeProps.match && routeProps.match.isExact}
                    >
                      <FontAwesomeIcon
                        icon={faList}
                        style={{ marginRight: "10px" }}
                      />
                      Voir les demandes d'installations
                    </LeftMenuItem>
                  )}
                />
              )}

            {authentication.user &&
              authentication.user.permissionNames &&
              authentication.user.permissionNames.indexOf(
                permissions.PageProgressTrackingTickets
              ) !== -1 && (
                <Route
                  path="/installation/tracking"
                  children={(routeProps) => (
                    <LeftMenuItem
                      onClick={() =>
                        this.props.dispatch(push("/installation/tracking"))
                      }
                      isActive={routeProps.match && routeProps.match.isExact}
                    >
                      <FontAwesomeIcon
                        icon={faList}
                        style={{ marginRight: "10px" }}
                      />
                      Tickets - Installations
                    </LeftMenuItem>
                  )}
                />
              )}

            {authentication.user &&
              authentication.user.permissionNames &&
              (authentication.user.permissionNames.indexOf(
                permissions.CreateMoveRequestTicket
              ) !== -1 ||
                authentication.user.permissionNames.indexOf(
                  permissions.PageMoveRequestTickets
                ) !== -1 ||
                authentication.user.permissionNames.indexOf(
                  permissions.PageProgressMoveRequestTickets
                ) !== -1) && (
                <div style={styles.menuBlockTitle}>Déplacement linéaire</div>
              )}

            {authentication.user &&
              authentication.user.permissionNames &&
              authentication.user.permissionNames.indexOf(
                permissions.CreateMoveRequestTicket
              ) !== -1 && (
                <Route
                  path="/move/new"
                  children={(routeProps) => (
                    <LeftMenuItem
                      onClick={() => this.props.dispatch(push("/move/new"))}
                      isActive={routeProps.match && routeProps.match.isExact}
                    >
                      <FontAwesomeIcon
                        icon={faPlusCircle}
                        style={{ marginRight: "10px" }}
                      />
                      Demande de déplacement linéaire
                    </LeftMenuItem>
                  )}
                />
              )}

            {authentication.user &&
              authentication.user.permissionNames &&
              authentication.user.permissionNames.indexOf(
                permissions.PageMoveRequestTickets
              ) !== -1 && (
                <Route
                  path="/move/list"
                  children={(routeProps) => (
                    <LeftMenuItem
                      onClick={() => this.props.dispatch(push("/move/list"))}
                      isActive={routeProps.match && routeProps.match.isExact}
                    >
                      <FontAwesomeIcon
                        icon={faList}
                        style={{ marginRight: "10px" }}
                      />
                      Voir les demandes de déplacements linéaires
                    </LeftMenuItem>
                  )}
                />
              )}

            {authentication.user &&
              authentication.user.permissionNames &&
              authentication.user.permissionNames.indexOf(
                permissions.PageProgressMoveRequestTickets
              ) !== -1 && (
                <Route
                  path="/move/tracking"
                  children={(routeProps) => (
                    <LeftMenuItem
                      onClick={() =>
                        this.props.dispatch(push("/move/tracking"))
                      }
                      isActive={routeProps.match && routeProps.match.isExact}
                    >
                      <FontAwesomeIcon
                        icon={faList}
                        style={{ marginRight: "10px" }}
                      />
                      Tickets - Déplacements linéaires
                    </LeftMenuItem>
                  )}
                />
              )}

            {authentication.user &&
              authentication.user.permissionNames &&
              (authentication.user.permissionNames.indexOf(
                permissions.CreateAfterSaleTicket
              ) !== -1 ||
                authentication.user.permissionNames.indexOf(
                  permissions.PageAfterSaleTickets
                ) !== -1) && <div style={styles.menuBlockTitle}>SAV</div>}

            {authentication.user &&
              authentication.user.permissionNames &&
              authentication.user.permissionNames.indexOf(
                permissions.CreateAfterSaleTicket
              ) !== -1 && (
                <Route
                  path="/sav/new"
                  children={(routeProps) => (
                    <LeftMenuItem
                      onClick={() => this.props.dispatch(push("/sav/new"))}
                      isActive={routeProps.match && routeProps.match.isExact}
                    >
                      <FontAwesomeIcon
                        icon={faPlusCircle}
                        style={{ marginRight: "10px" }}
                      />
                      Demande d'intervention SAV
                    </LeftMenuItem>
                  )}
                />
              )}

            {authentication.user &&
              authentication.user.permissionNames &&
              authentication.user.permissionNames.indexOf(
                permissions.PageAfterSaleTickets
              ) !== -1 && (
                <Route
                  path="/sav/list"
                  children={(routeProps) => (
                    <LeftMenuItem
                      onClick={() => this.props.dispatch(push("/sav/list"))}
                      isActive={routeProps.match && routeProps.match.isExact}
                    >
                      <FontAwesomeIcon
                        icon={faList}
                        style={{ marginRight: "10px" }}
                      />
                      Voir les interventions SAV
                    </LeftMenuItem>
                  )}
                />
              )}

            {authentication.user &&
              authentication.user.permissionNames &&
              (authentication.user.permissionNames.indexOf(
                permissions.ManageCustomers
              ) !== -1 ||
                authentication.user.permissionNames.indexOf(
                  permissions.ManageHardwares
                ) !== -1 ||
                authentication.user.permissionNames.indexOf(
                  permissions.ManageHardwareTypes
                ) !== -1 ||
                authentication.user.roleNames.indexOf(permissions.Admin) !==
                  -1) && <div style={styles.menuBlockTitle}>Gestion admin</div>}

            {authentication.user &&
              authentication.user.permissionNames &&
              authentication.user.permissionNames.indexOf(
                permissions.ManageCustomers
              ) !== -1 && (
                <Route
                  path="/customer-group/list"
                  children={(routeProps) => (
                    <LeftMenuItem
                      onClick={() =>
                        this.props.dispatch(push("/customer-group/list"))
                      }
                      isActive={routeProps.match && routeProps.match.isExact}
                    >
                      <FontAwesomeIcon
                        icon={faList}
                        style={{ marginRight: "10px" }}
                      />
                      Gestion des groupes de magasins
                    </LeftMenuItem>
                  )}
                />
              )}

            {authentication.user &&
              authentication.user.permissionNames &&
              authentication.user.permissionNames.indexOf(
                permissions.ManageCustomers
              ) !== -1 && (
                <Route
                  path="/customer/list"
                  children={(routeProps) => (
                    <LeftMenuItem
                      onClick={() =>
                        this.props.dispatch(push("/customer/list"))
                      }
                      isActive={routeProps.match && routeProps.match.isExact}
                    >
                      <FontAwesomeIcon
                        icon={faList}
                        style={{ marginRight: "10px" }}
                      />
                      Gestion des magasins
                    </LeftMenuItem>
                  )}
                />
              )}

            {authentication.user &&
              authentication.user.permissionNames &&
              authentication.user.permissionNames.indexOf(
                permissions.ManageHardwareTypes
              ) !== -1 && (
                <Route
                  path="/hardware-type/list"
                  children={(routeProps) => (
                    <LeftMenuItem
                      onClick={() =>
                        this.props.dispatch(push("/hardware-type/list"))
                      }
                      isActive={routeProps.match && routeProps.match.isExact}
                    >
                      <FontAwesomeIcon
                        icon={faList}
                        style={{ marginRight: "10px" }}
                      />
                      Gestion des types de configurations
                    </LeftMenuItem>
                  )}
                />
              )}

            {authentication.user &&
              authentication.user.permissionNames &&
              authentication.user.permissionNames.indexOf(
                permissions.ManageHardwares
              ) !== -1 && (
                <Route
                  path="/hardware/list"
                  children={(routeProps) => (
                    <LeftMenuItem
                      onClick={() =>
                        this.props.dispatch(push("/hardware/list"))
                      }
                      isActive={routeProps.match && routeProps.match.isExact}
                    >
                      <FontAwesomeIcon
                        icon={faList}
                        style={{ marginRight: "10px" }}
                      />
                      Gestion des matériels
                    </LeftMenuItem>
                  )}
                />
              )}

            <Switch />

            {authentication.user &&
              authentication.user.roleNames &&
              authentication.user.permissionNames.indexOf(
                permissions.PagesUsers
              ) !== -1 && (
                <Route
                  path="/users"
                  children={(routeProps) => (
                    <LeftMenuItem
                      onClick={() => this.props.dispatch(push("/users"))}
                      isActive={routeProps.match}
                    >
                      <FontAwesomeIcon
                        icon={faUser}
                        style={{ marginRight: "10px" }}
                      />
                      Utilisateurs
                    </LeftMenuItem>
                  )}
                />
              )}

            {authentication.user &&
              authentication.user.roleNames &&
              authentication.user.permissionNames.indexOf(
                permissions.PagesUsers
              ) !== -1 && (
                <Route
                  path="/contacts"
                  children={(routeProps) => (
                    <LeftMenuItem
                      onClick={() => this.props.dispatch(push("/contacts"))}
                      isActive={routeProps.match}
                    >
                      <FontAwesomeIcon
                        icon={faUser}
                        style={{ marginRight: "10px" }}
                      />
                      Contacts
                    </LeftMenuItem>
                  )}
                />
              )}

            {contacts && contacts.contacts && (
              <div style={styles.menuBlockTitle}>
                En cas d’urgence, vous pouvez contacter :
              </div>
            )}

            {contacts &&
              contacts.contacts &&
              contacts.contacts.map((contact) => (
                <div key={contact.id}>
                  <div
                    style={{
                      fontSize: "16px",
                      color: "rgb(181, 151, 89)",
                      padding: "0 20px",
                    }}
                  >
                    {contact.lastname} {contact.firstname}
                  </div>

                  <LeftMenuItem>
                    <FontAwesomeIcon
                      icon={faMailBulk}
                      style={{ marginRight: "10px" }}
                    />
                    Mail :{" "}
                    <a
                      href={"mailto:" + contact.email}
                      style={{ color: "white" }}
                    >
                      {contact.email}
                    </a>
                  </LeftMenuItem>
                  <LeftMenuItem>
                    <FontAwesomeIcon
                      icon={faPhone}
                      style={{ marginRight: "10px" }}
                    />
                    Tel : {contact.phone}
                  </LeftMenuItem>
                </div>
              ))}
          </div>

          <div style={styles.mainPanel}>
            <Switch location={this.props.location}>
              <Route path="/sav/list" component={AfterSaleList} />
              <Route path="/sav/:id" component={AfterSaleDetail} />
              <Route
                path="/installation/list/:status"
                component={InstallationList}
              />
              <Route path="/installation/list/" component={InstallationList} />
              <Route
                path="/installation/tracking/:status"
                component={InstallationTracking}
              />
              <Route
                path="/installation/tracking/"
                component={InstallationTracking}
              />
              <Route path="/installation/:id" component={InstallationDetail} />
              <Route path="/move/list" component={MoveList} />
              <Route path="/move/tracking" component={MoveTracking} />
              <Route path="/move/:id" component={MoveDetail} />
              <Route path="/customer/list" component={Customer} />
              <Route path="/customer-group/list" component={CustomerGroup} />
              <Route path="/hardware/list" component={Hardware} />
              <Route path="/hardware-type/list" component={HardwareType} />
              <Route path="/users" component={Users} />
              <Route path="/contacts" component={Contacts} />
              <Route path="/planning" component={Planning} />
              <Route path="/" component={Dashboard} />
            </Switch>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  router: state.router,
  authentication: state.authentication,
  settings: state.settings,
  contacts: state.contacts,
});

export default connect(mapStateToProps)(Radium(ManagerChrome));
