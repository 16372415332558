import React, { Component } from 'react';
import Radium from 'radium';

class ManagerChromeLeftMenuItem extends Component {

    render() {

        const { children, isActive, onClick, style } = this.props;

        const styles = {

            base: {
                fontSize: '13px',
                fontWeight: '500',
                color: 'white',
                padding: '8px 5px 8px 20px',
                cursor: 'pointer',
                // ':hover': {
                //     backgroundColor: '#151E38'
                // },
                ...style
            },

            active: {
                color: '#B59759',
                backgroundColor: '#151E28',
                // ':hover': {
                //     backgroundColor: '#666'
                // }
            }
        };

        return (

            <div style={[ styles.base, isActive && styles.active ]} onClick={onClick}>{children}</div>
                      
        );
    }
}

export default Radium(ManagerChromeLeftMenuItem);
