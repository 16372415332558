import React, { Component } from "react";
import { connect } from "react-redux";
import { Button } from "reactstrap";
import Radium from "radium";
import ReactTable from "react-table";
import { getAllContacts } from "../../actions/contacts";
import EditContactModal from "./EditContactModal";
import { pushModalAsync, dismissModalAsync } from "../../actions/modals";

class Contacts extends Component {
  componentDidMount() {
    this.props.dispatch(getAllContacts());
  }

  createContact = () => {
    this.props.dispatch(
      pushModalAsync(EditContactModal, {
        contact: {
          lastname: "",
          firstname: "",
          phone: "",
          role: "",
          email: "",
          weight: 100,
        },
        onClose: async (modalId, modalResult) => {
          this.props.dispatch(dismissModalAsync(modalId));
        },
      })
    );
  };

  editContact = (contact) => {
    this.props.dispatch(
      pushModalAsync(EditContactModal, {
        contact,
        onClose: async (modalId, modalResult) => {
          this.props.dispatch(dismissModalAsync(modalId));
        },
      })
    );
  };

  render() {
    const { contacts } = this.props;
    const styles = {
      title: {
        textAlign: "center",
        padding: "10px",
      },
      addClient: {
        padding: "0 0 5px 0",
      },
    };

    return (
      <div style={{ padding: "20px" }}>
        <h1 style={styles.title}>Gestion des contacts</h1>
        <div style={styles.addClient}>
          <Button color="primary" onClick={this.createContact}>
            Ajouter un contact
          </Button>
        </div>

        <div style={{ display: "flex", flex: "1" }}>
          {contacts && contacts.contacts && (
            <ReactTable
              data={contacts.contacts}
              filterable
              defaultPageSize={10}
              style={{ border: "none", flex: "1" }}
              noDataText="Aucun contact"
              ofText="sur"
              nextText="Suivant"
              previousText="Précédent"
              rowsText="lignes"
              defaultFilterMethod={(filter, row) => {
                if (!filter.value) {
                  return true;
                }
                return (
                  row[filter.id] &&
                  row[filter.id]
                    .toLowerCase()
                    .indexOf(filter.value.toLowerCase()) !== -1
                );
              }}
              columns={[
                {
                  Header: "Nom",
                  accessor: "lastname",
                  Cell: (props) => (
                    <div style={styles.cellStyle}>{props.value}</div>
                  ),
                },
                {
                  Header: "Prénom",
                  accessor: "firstname",
                  Cell: (props) => (
                    <div style={styles.cellStyle}>{props.value}</div>
                  ),
                },
                {
                  Header: `Email`,
                  accessor: "email",
                  Cell: (props) => (
                    <div style={styles.cellStyle}>{props.value}</div>
                  ),
                },
                {
                  Header: `Role`,
                  accessor: "role",
                  Cell: (props) => (
                    <div style={styles.cellStyle}>{props.value}</div>
                  ),
                  filterable: false,
                },
                {
                  Header: `Ordre`,
                  accessor: "weight",
                  Cell: (props) => (
                    <div style={styles.cellStyle}>{props.value}</div>
                  ),
                  filterable: false,
                },
              ]}
              getTrProps={(state, rowInfo, column, instance) => ({
                onClick: (e, handleOriginal) => {
                  this.editContact(rowInfo.original);
                  // IMPORTANT! React-Table uses onClick internally to trigger
                  // events like expanding SubComponents and pivots.
                  // By default a custom 'onClick' handler will override this functionality.
                  // If you want to fire the original onClick handler, call the
                  // 'handleOriginal' function.
                  // if (handleOriginal) {
                  //     handleOriginal();
                  // }
                },
              })}
            />
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  authentication: state.authentication,
  contacts: state.contacts,
});

export default connect(mapStateToProps)(Radium(Contacts));
