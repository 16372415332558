import React, { Component } from "react";
import { connect } from "react-redux";
import Radium from "radium";
import BreadcrumbItem from "./BreadcrumbItem";
import { Switch, Route } from "react-router-dom";

class Breadcrumb extends Component {
  render() {
    const styles = {
      root: {
        ...this.props.style,
        display: "flex",
        alignItems: "center",
        padding: "0px 30px",
      },
    };

    return (
      <div style={styles.root}>
        <BreadcrumbItem title="Accueil" to="/" hideCaret />

        <Switch>
          <Route
            path="/installation/list"
            render={(routeProps) => (
              <BreadcrumbItem
                title="Liste des tickets d'installations"
                to="/installation/list"
              />
            )}
          />

          <Route
            path="/installation/tracking"
            render={(routeProps) => (
              <BreadcrumbItem
                title="Tickets - Installations"
                to="/installation/tracking"
              />
            )}
          />

          <Route
            path="/installation/:ticketId"
            render={(routeProps) => (
              <BreadcrumbItem
                title="Détail installation"
                to={`/installation/${routeProps.match.params.ticketId}`}
              />
            )}
          />

          <Route
            path="/move/list"
            render={(routeProps) => (
              <BreadcrumbItem
                title="Liste des tickets de déplacements linéaires"
                to="/move/list"
              />
            )}
          />

          <Route
            path="/move/tracking"
            render={(routeProps) => (
              <BreadcrumbItem
                title="Déplacements linéaires"
                to="/move/tracking"
              />
            )}
          />

          <Route
            path="/move/:ticketId"
            render={(routeProps) => (
              <BreadcrumbItem
                title="Détail déplacement linéaire"
                to={`/move/${routeProps.match.params.ticketId}`}
              />
            )}
          />

          <Route
            path="/sav/list"
            render={(routeProps) => (
              <BreadcrumbItem title="Liste des tickets SAV" to="/sav/list" />
            )}
          />

          <Route
            path="/sav/new"
            render={(routeProps) => (
              <BreadcrumbItem title="Nouveau ticket SAV" to="/sav/new" />
            )}
          />

          <Route
            path="/sav/:ticketId"
            render={(routeProps) => (
              <BreadcrumbItem
                title="Détail ticket SAV"
                to={`/sav/${routeProps.match.params.ticketId}`}
              />
            )}
          />

          <Route
            path="/customer/list"
            render={(routeProps) => (
              <BreadcrumbItem
                title="Gestion des magasins"
                to="/customer/list"
              />
            )}
          />

          <Route
            path="/hardware/list"
            render={(routeProps) => (
              <BreadcrumbItem
                title="Gestion des matériels"
                to="/hardware/list"
              />
            )}
          />

          <Route
            path="/hardware-type/list"
            render={(routeProps) => (
              <BreadcrumbItem
                title="Gestion des types de configurations"
                to="/hardware-type/list"
              />
            )}
          />

          <Route
            path="/customer-group/list"
            render={(routeProps) => (
              <BreadcrumbItem
                title="Gestion des groupes de magasins"
                to="/customer-group/list"
              />
            )}
          />

          <Route
            path="/users"
            render={(routeProps) => (
              <BreadcrumbItem title="Gestion des utilisateurs" to="/users" />
            )}
          />

          <Route
            path="/contacts"
            render={(routeProps) => (
              <BreadcrumbItem title="Gestion des contacts" to="/contacts" />
            )}
          />

          <Route
            path="/"
            render={(routeProps) => (
              <BreadcrumbItem title="Tableau de bord" to="/" />
            )}
          />
        </Switch>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  router: state.router,
});

export default connect(mapStateToProps)(Radium(Breadcrumb));
