import { handleActions } from "redux-actions";

const initialState = {
  contact: null,
  contacts: null,
  countContacts: null,
};

export const contacts = handleActions(
  {
    GET_CONTACT_RESPONSE: (state, action) => ({
      ...state,
      contact: action,
    }),
    GET_ALL_CONTACTS_RESPONSE: (state, action) => ({
      ...state,
      contacts: action.payload.data.items,
      countContacts: action.payload.data.totalCount,
    }),
    UPDATE_CONTACT_RESPONSE: (state, action) => ({
      ...state,
      contact: action,
    }),
  },
  initialState
);
