import React, { Component } from 'react';
import { connect } from 'react-redux';
import { CustomInput, Modal, ModalHeader, ModalBody, ModalFooter, Input, Label, Form, FormGroup, Button } from 'reactstrap';
import { getHardwares, createHardware, updateHardware, deleteHardware } from '../actions/hardwares';
import Radium from 'radium';
import { pushModalAsync, dismissModalAsync } from '../actions/modals';
import ConfirmModal from '../components/ConfirmModal';
import { getHardwareTypes } from '../actions/hardwareTypes';
import { getAllRoles } from '../actions/roles';
import Select from 'react-select';

const emptyHardware = {
    id: 'new',
    name: '',
    hardwareTypeId: null,
    hardwareHardwareTypes: null,
    applicableTicketType: 0,
    hardwareRoleIds: []
};

class NewHardwareModal extends Component{

    state = {
        isBusy: false,
        error: null,
        hardware: {
            ...emptyHardware,
            ...this.props.hardware
        },
    };

    componentDidMount() {
        this.props.dispatch(getHardwareTypes());
        this.props.dispatch(getAllRoles());
    }

    dismiss = (modalResult) => {
        this.props.onClose(modalResult);
    };

    handleHardwareTypesChange = (selectedOptions) => {
        let hardwareTypes = []; 
        selectedOptions.forEach(selectedOption => {
            hardwareTypes[hardwareTypes.length] = {hardwareTypeId: selectedOption.value, hardwareTypeName: selectedOption.label};
        });

        this.setState((prevState, prevProps) => ({
            hardware: { 
                ...prevState.hardware,
                hardwareHardwareTypes: hardwareTypes
            }
        }));
    }

    handleInputChange = (e) => {
        const propName = e.target.name;
        let propValue = e.target.value;
        this.setState((prevState, prevProps) => ({
            hardware: { 
                ...prevState.hardware,
                [propName]: propValue 
            }
        }));
    };

    handleSubmit = (e) => {
        e.preventDefault();

        if (this.isFormInvalid() || this.state.isBusy) {
            return;
        }

        this.setState({ isBusy: true, error: null }, async() => {

            let saveResult = null;
            if(this.state.hardware.id !== "new"){
                saveResult = await this.props.dispatch(updateHardware(this.state.hardware));
            }
            else{
                saveResult = await this.props.dispatch(createHardware(this.state.hardware));
            }

            if (!saveResult.error){
                this.props.dispatch(getHardwares());
                this.props.onClose();
            }
            else {
                this.setState({ error: saveResult.error });
            }

            this.setState({ isBusy: false });
        });
    };

    handleDelete = (e) => {
        e.preventDefault();

        if (this.isFormInvalid() || this.state.isBusy) {
            return;
        }

        this.props.dispatch(pushModalAsync(ConfirmModal, {
            title: 'Supprimer un matériel',
            message: 'Êtes-vous sûr de vouloir supprimer ce matériel ?',
            yesButtonLabel: 'OUI',
            noButtonLabel: 'NON',
            onClose: (modalId, modalResult) => {

                this.props.dispatch(dismissModalAsync(modalId));

                if (modalResult){

                    this.setState({
                        error: null, 
                        isBusy : true
            
                    }, async() => {
                        
                        const deleteResponse = await this.props.dispatch(deleteHardware(this.state.hardware.id));
            
                        if (!deleteResponse.error){
                            this.props.dispatch(getHardwares());
                            this.props.onClose();
                        }
                        else {
                            this.setState({ error: deleteResponse.error, isBusy: false });
                        }
                    });
                }
            }
        }));
    };

    handleApplicableTicketType = (e, applicableTicketType) => {

        const propValue = e.target.checked;

        let currentApplicableTicketType = this.state.hardware.applicableTicketType;

        if (!propValue){//((currentApplicableTicketType & applicableTicketType) === applicableTicketType){
            currentApplicableTicketType = currentApplicableTicketType ^ applicableTicketType;
        }
        else {
            currentApplicableTicketType = currentApplicableTicketType | applicableTicketType;
        }
        this.setState((prevState, prevProps) => ({

            hardware: {
                ...prevState.hardware,
                applicableTicketType: currentApplicableTicketType
            }

        }));
    };
    
    hasRole = (roleId) => {
        if (this.state.hardware && this.state.hardware.hardwareRoleIds){
            return this.state.hardware.hardwareRoleIds.indexOf(roleId) !== -1;
        }
        return false;
    };

    handleRoleCheckboxChange = (e, roleId) => {
        const propValue = e.target.checked;
        const roleIds = [
            ...this.state.hardware.hardwareRoleIds
        ];

        if (propValue){
            roleIds.push(roleId);
        }
        else {
            const roleIndex = roleIds.indexOf(roleId);
            if (roleIndex !== -1) {
                roleIds.splice(roleIndex, 1);
            }
        }

        this.setState((prevState, props) => ({ 
            hardware: {
                ...prevState.hardware,
                hardwareRoleIds: roleIds
            }
        }));
    };

    isFormInvalid = () => !this.state.hardware.name || !this.state.hardware.hardwareHardwareTypes;

    render(){
        const { hardware } = this.state;
        const isFormInvalid = this.isFormInvalid();
        const { isOpen, hardwareTypes, roles } = this.props;

        let hardwareTypeSelectOptions = [];
        if(hardwareTypes && hardwareTypes.items){
            Object.keys(hardwareTypes.items).map(key => {
                const hardwareType = hardwareTypes.items[key];
                return hardwareTypeSelectOptions.push({value: hardwareType.id, label: hardwareType.name});
            })
        }

        let hardwareTypeSelected = [];
        if(hardware && hardware.hardwareHardwareTypes){
            hardware.hardwareHardwareTypes.forEach(hardwareHardwareType => {
                hardwareTypeSelected.push({value: hardwareHardwareType.hardwareTypeId, label: hardwareHardwareType.hardwareTypeName});
            });
        }

        return (
            <Modal isOpen={isOpen} toggle={() => this.dismiss(false)}>

                <ModalHeader>
                    Ajouter un matériel
                </ModalHeader>

                <ModalBody>
                    <Form onSubmit={this.handleSubmit}>
                        <FormGroup>
                            <Label for="text">Nom</Label>
                            <Input id="text" type='text' name="name" value={this.state.hardware.name} onChange={this.handleInputChange} autoFocus />
                        </FormGroup>
                            {/* {hardwareTypes.items && 
                            <UncontrolledDropdown>
                                <div>Type de configuration</div>
                                <DropdownToggle caret style={{ marginTop: '10px', width: '200px' }}>
                                    {hardwareType ? hardwareType.name : 'Choix du type de configuration'}
                                </DropdownToggle>
                                <DropdownMenu>
                                    {hardwareTypes.items.map(hardwareType => (
                                        <DropdownItem key={hardwareType.id}
                                        onClick={() => this.handleInputChange({ target: { name: 'hardwareTypeId', value: hardwareType.id } })}>
                                            {hardwareType.name}
                                        </DropdownItem>
                                    ))}
                                </DropdownMenu>
                            </UncontrolledDropdown>} */}
                            {hardwareTypeSelectOptions && 
                            <FormGroup>
                            <Label for="text">Type de configuration(s)</Label>
                                <Select
                                    isMulti
                                    value={hardwareTypeSelected}
                                    onChange={this.handleHardwareTypesChange}
                                    options={hardwareTypeSelectOptions}
                                />
                            </FormGroup>}
                        {/* <FormGroup>
                            <div>Types de tickets</div>
                            <CustomInput id="afterSaleTicketTypeCheckbox" type="checkbox"
                             label={applicableTicketTypeName[applicableTicketType.afterSale]}
                             value={(hardware.applicableTicketType & applicableTicketType.afterSale) === applicableTicketType.afterSale}
                             checked={(hardware.applicableTicketType & applicableTicketType.afterSale) === applicableTicketType.afterSale}
                             onChange={(e) => this.handleApplicableTicketType(e, applicableTicketType.afterSale)} />
                            <CustomInput id="installationRequestTicketTypeCheckbox" type="checkbox"
                            label={applicableTicketTypeName[applicableTicketType.installationRequest]}
                            value={(hardware.applicableTicketType & applicableTicketType.installationRequest) === applicableTicketType.installationRequest} 
                            checked={(hardware.applicableTicketType & applicableTicketType.installationRequest) === applicableTicketType.installationRequest} 
                            onChange={(e) => this.handleApplicableTicketType(e, applicableTicketType.installationRequest)} />
                            <CustomInput id="moveRequestTicketTypeCheckbox" type="checkbox"
                            label={applicableTicketTypeName[applicableTicketType.moveRequest]}
                            value={(hardware.applicableTicketType & applicableTicketType.moveRequest) === applicableTicketType.moveRequest} 
                            checked={(hardware.applicableTicketType & applicableTicketType.moveRequest) === applicableTicketType.moveRequest} 
                            onChange={(e) => this.handleApplicableTicketType(e, applicableTicketType.moveRequest)} />
                        </FormGroup> */}
                        <FormGroup>
                            <Label for="roles">Roles qui recevront les emails</Label>
                            {roles && roles.items && roles.count > 0 && 
                                roles.items.map((role, key) => {
                                    if(role.normalizedName === 'ADMIN'){
                                        return null;
                                    }
                                    return(
                                        <CustomInput key={role.id} id={role.id} type="checkbox" label={role.displayName} 
                                            checked={this.hasRole(role.id)}
                                            onChange={(e) => this.handleRoleCheckboxChange(e, role.id)}
                                        />
                                    )
                                }
                            )}
                        </FormGroup>
                    </Form>
                </ModalBody>

                <ModalFooter>
                    {this.state.hardware.id !== "new" && <Button type="button" color="danger" onClick={this.handleDelete}>Supprimer le matériel</Button>}
                    <Button color="secondary" onClick={this.dismiss}>Annuler</Button>{' '}
                    <Button color="primary" onClick={this.handleSubmit} disabled={isFormInvalid}>Valider</Button>
                </ModalFooter>

            </Modal>
        );
    }
}

const mapStateToProps = state => ({
    authentication: state.authentication,
    hardwareTypes: state.hardwareTypes,
    roles: state.roles
});

export default connect(mapStateToProps)(Radium(NewHardwareModal));