import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Button } from 'reactstrap';
import Radium from 'radium';
import ReactTable from 'react-table';
import { getHardwareTypes } from '../../actions/hardwareTypes';
import { pushModalAsync, dismissModalAsync } from '../../actions/modals';
import NewHardwareTypeModal from '../../components/NewHardwareTypeModal';

class HardwareType extends Component {

    componentDidMount() {
        this.props.dispatch(getHardwareTypes());
    }
    
    handleAddHardwareType = () => {
        this.props.dispatch(pushModalAsync(NewHardwareTypeModal, {
            onClose: async(modalId, modalResult) => {
                this.props.dispatch(dismissModalAsync(modalId));
                if (modalResult){
                }
            }
        }));
    };

    render() {
        const { hardwareTypes } = this.props;
        const columns = [{
            Header: 'Nom',
            accessor: 'name'
           },
           {
             Header: 'Role(s)',
             accessor: 'hardwareTypeRoles',
             filterable: false,
             Cell: props => {
                 let result = "";
                 props.value.forEach(hardwareTypeRole => {
                     result += result ? ', ' + hardwareTypeRole.role.displayName : hardwareTypeRole.role.displayName;
                 });
                 return result;
             }
           }
        ];

        const styles = {
            title: {
                textAlign: 'center',
                padding: '10px'
            },
            addHardwareType: {
                padding: '0 0 5px 0'
            }
        };

        return (
            <div style={{ padding: '20px' }}>
                <h1 style={styles.title}>Gestion des types de configurations</h1>
                <div style={styles.addHardwareType}>
                    <Button color="primary" onClick={this.handleAddHardwareType}>Ajouter un type de configuration</Button>
                </div>
                {hardwareTypes && hardwareTypes.items && (
                    <ReactTable 
                        data={hardwareTypes.items}
                        columns={columns}
                        filterable
                        minRows={10}
                        noDataText="Aucun type de configuration"
                        ofText="sur"
                        nextText="Suivant"
                        previousText="Précédent"
                        rowsText="lignes"
                        defaultFilterMethod={(filter, row) => {
                            if (!filter.value) {
                                return true;
                            }
                            return row[filter.id] && row[filter.id].toLowerCase().indexOf(filter.value.toLowerCase()) !== -1;
                        }}
                        getTrProps={(state, rowInfo, column, instance) => ({
                            onClick: (e, handleOriginal) => {
                                this.props.dispatch(pushModalAsync(NewHardwareTypeModal, {
                                    hardwareType: rowInfo.original,
                                    onClose: async(modalId, modalResult) => {
                                        this.props.dispatch(dismissModalAsync(modalId));
                                    }
                                }));
                            }
                        })}
                    />
                )}
            </div>

        );
    }
}

const mapStateToProps = state => ({
    authentication: state.authentication,
    hardwareTypes: state.hardwareTypes,
});

export default connect(mapStateToProps)(Radium(HardwareType));