import React, { Component } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { push } from 'connected-react-router';
import { connect } from 'react-redux';
import Radium from 'radium';

class EventPreviewModal extends Component {

    state = {};

    seeMoreClick = () => {
        const { event } = this.props;
        if(event.type){
            this.props.onClose();
            this.props.dispatch(push('/' + event.type + '/' + event.ticket.id));
        }
    }

    render() {
        const { event, isOpen, onClose } = this.props;
        const ticket = event.ticket;
        return (
            <Modal isOpen={isOpen} toggle={onClose} size="lg">
                <ModalHeader toggle={onClose}>
                    Informations sur le ticket <strong>{ticket.friendlyNum}</strong> 
                </ModalHeader>

                <ModalBody>
                    <p><strong>Créé par:</strong> {ticket.creatorName}</p>
                    <p><strong>Magasin:</strong> {ticket.customer.name}</p>
                    <p><strong>Description:</strong> {ticket.description}</p>
                </ModalBody>

                <ModalFooter>
                    <Button color="primary" onClick={this.seeMoreClick}>Voir le détail complet</Button>
                    <Button color="primary" onClick={onClose}>Fermer</Button>
                </ModalFooter>
            </Modal>
        );
    }
}

export default connect()(Radium(EventPreviewModal));
