import React, { Component } from 'react';
import Radium from 'radium';
import { push } from 'connected-react-router';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretRight } from '@fortawesome/free-solid-svg-icons';


class BreadcrumbItem extends Component{

    render(){
        
        const { title, to, hideCaret } = this.props;

        const styles = {

            root: {
                color: 'white', 
                fontSize: '12px',
                cursor: 'pointer',
                marginRight: '20px',
                ...this.props.style
            }

        };

        return (
            <div style={styles.root} onClick={() => this.props.dispatch(push(to))}>
            
                {!hideCaret && <FontAwesomeIcon icon={faCaretRight} style={{ marginRight: '10px' }} />}
                {title}

            </div>
        );

    }

}

const mapStateToProps = state => ({
    router: state.router
});

export default connect(mapStateToProps)(Radium(BreadcrumbItem));