import Enumerable from 'linq';

const initialState = {
    items: []
};

export const notifications = (state = initialState, action) => {

    switch (action.type){

        case 'CREATE_NOTIFICATION':

            return {
                ...state,
                items: [{ id: new Date().getTime(), text: action.text, type: action.notificationType }, ...state.items]
            };

        case 'DISMISS_NOTIFICATION':

            const notification = Enumerable.from(state.items).firstOrDefault(o => o.id === action.notificationId);
            const notificationIndex = state.items.indexOf(notification);

            const items = [...state.items];

            items.splice(notificationIndex, 1);

            return {
                ...state,
                items: items
            };

        case 'GET_AFTER_SALE_TICKETS_RESPONSE':

            if (action.error) {
                return {
                    ...state,
                    items: [{ id: new Date().getTime(), text: action.error, type: 'danger' }, ...state.items]
                };
            }
            break;

        case 'CREATE_AFTER_SALE_TICKET_RESPONSE':

            return {
                ...state,
                items: [{ id: new Date().getTime(), text: action.error || `Demande d'intervention SAV créée avec succès`, type: action.error ? 'danger' : 'success' }, ...state.items]
            };

        case 'UPDATE_AFTER_SALE_TICKET_RESPONSE':

            return {
                ...state,
                items: [{ id: new Date().getTime(), text: action.error || `Modifications enregistrées avec succès`, type: action.error ? 'danger' : 'success' }, ...state.items]
            };

        case 'CREATE_INSTALLATION_REQUEST_TICKET_RESPONSE':

            return {
                ...state,
                items: [{ id: new Date().getTime(), text: action.error || `Demande d'installation créée avec succès`, type: action.error ? 'danger' : 'success' }, ...state.items]
            };

        case 'UPDATE_INSTALLATION_REQUEST_TICKET_RESPONSE':

            return {
                ...state,
                items: [{ id: new Date().getTime(), text: action.error || `Modifications enregistrées avec succès`, type: action.error ? 'danger' : 'success' }, ...state.items]
            };

        case 'CREATE_MOVE_REQUEST_TICKET_RESPONSE':

            return {
                ...state,
                items: [{ id: new Date().getTime(), text: action.error || `Demande de déplacement linéaire créée avec succès`, type: action.error ? 'danger' : 'success' }, ...state.items]
            };

        case 'UPDATE_MOVE_REQUEST_TICKET_RESPONSE':

            return {
                ...state,
                items: [{ id: new Date().getTime(), text: action.error || `Modifications enregistrées avec succès`, type: action.error ? 'danger' : 'success' }, ...state.items]
            };

            default:
            break;
    }

    return state;

};