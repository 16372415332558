import moment from 'moment';

export const formatDatetime = (datetime, format = 'DD/MM/YYYY') => {
    if(datetime && format){
        return moment(datetime).format(format)
    }
};

export const getDate = (year = 2018, dayOfYear = 1, hours = 0, minutes = 0, seconds = 0) => {
    return moment()
        .year(year)
        .dayOfYear(dayOfYear)
        .hours(hours)
        .minutes(minutes)
        .seconds(seconds)
        .toDate();
}

/** Return object with props "hours, minutes, seconds" or false if format isn't correct */
export const getTimeWithProp = (time = '00:00:00') => {
    let timeObj = {
        hours: 0,
        minutes: 0,
        seconds: 0
    };
    const timeSplit = time.split(':');
    if(timeSplit[0] && Number.isInteger(parseInt(timeSplit[0]))){
        timeObj.hours = timeSplit[0];
    }
    else{
        return false;
    }
    if(timeSplit[1] && Number.isInteger(parseInt(timeSplit[1]))){
        timeObj.minutes = timeSplit[1];
    }
    if(timeSplit[2] && Number.isInteger(parseInt(timeSplit[2]))){
        timeObj.seconds = timeSplit[2];
    }
    return timeObj;
}