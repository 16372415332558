const initialState = {
    items: null,
};

export const customers = (state = initialState, action) => {
    switch (action.type){

        case 'GET_CUSTOMERS_RESPONSE':

            return {
                ...state,
                items: action.data.items,
            };

            default:
            break;
    }

    return state;

};