const initialState = {
    items: null,
};

export const hardwareTypes = (state = initialState, action) => {
    switch (action.type){

        case 'GET_HARDWARE_TYPES_RESPONSE':

            return {
                ...state,
                items: action.data.items,
            };

            default:
            break;
    }

    return state;

};