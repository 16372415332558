import React from 'react';
import Radium from 'radium';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

class DashboardBlock extends React.Component {

    render(){

        const { block, onClick, style } = this.props;

        const styles = {

            root: {
                ...style,
                background: 'white',
                boxShadow: '0 0 5px #999',
                borderRadius: '3px',
                display: 'flex',
                flexDirection: 'column',
                cursor: 'pointer',
                overflow: 'hidden',
                textAlign: 'center'
            }
        };

        return (

            <div style={styles.root} onClick={onClick}>

                <div style={{ height: '120px', backgroundColor: '#000', position: 'relative', overflow: 'hidden', display: 'flex' }}>
                    {!block.photo && <FontAwesomeIcon icon={block.icon} color="#B59759" style={{ margin: 'auto' }} size="3x" />}
                </div>

                <div style={{ flex: '1', padding: '5px' }}>
                    <div style={{ color: '#010101', fontSize: '17px', marginTop: '5px' }}>{block.title}</div>
                    <div style={{ color: '#888', fontSize: '13px', textTransform: 'uppercase' }}>{block.subtitle}</div>
                    <div style={{ fontSize: '20px', textTransform: 'uppercase', marginTop: '20px' }}>{block.description}</div>
                </div>

                <div style={{ height: '5px', backgroundColor: '#eeeeee', display: 'flex' }}>
                    <div style={{ height: '100%', backgroundColor: `${block.progressColor ? block.progressColor : '#fc682d'}`, width: `${block.progressPercent}%` }}></div>
                </div>

            </div>

        );

    }

}


export default Radium(DashboardBlock);