import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  FormGroup,
  Label,
  Input,
  Alert,
} from "reactstrap";
import Radium from "radium";
import { saveProfile } from "../../actions/account";

class EditProfileModal extends Component {
  state = {
    user: {
      ...this.props.user,
      password: "",
      passwordConfirmation: "",
    },
    mustFillPassword: false,
  };

  handleInputChange = (e) => {
    const propName = e.target.name;
    const propValue = e.target.value;

    this.setState((prevState, props) => ({
      user: {
        ...prevState.user,
        [propName]: propValue,
      },
    }));
  };

  handleSubmit = (e) => {
    e.preventDefault();

    if (this.isFormInvalid() || this.state.isBusy) {
      return;
    }

    this.setState({ error: null, isBusy: true }, async () => {
      const user = {
        ...this.state.user,
      };

      const saveResponse = await this.props.dispatch(saveProfile(user));

      if (!saveResponse.error) {
        //TODO: dispatch complete user with roleNames and permissionNames,
        // the code below is not working because these informations are not set
        // so we need to reload the page

        //this.props.dispatch(setUserProfile(saveResponse.data));
        //this.props.onClose();
        window.location.reload();
      } else {
        this.setState({ error: saveResponse.error, isBusy: false });
      }
    });
  };

  isFormInvalid = () =>
    !this.state.user.userName ||
    !this.state.user.emailAddress ||
    (this.state.mustFillPassword && !this.state.user.password) ||
    (this.state.mustFillPassword && !this.state.user.passwordConfirmation) ||
    (this.state.mustFillPassword &&
      this.state.user.password !== this.state.user.passwordConfirmation);

  render() {
    const { isOpen, onClose } = this.props;

    const isFormInvalid = this.isFormInvalid();

    return (
      <Modal isOpen={isOpen} toggle={!this.state.isBusy ? onClose : null}>
        <ModalHeader toggle={!this.state.isBusy ? onClose : null}>
          {this.state.user.id ? "Détails utilisateur" : "Nouvel utilisateur"}
        </ModalHeader>

        <ModalBody>
          <Form onSubmit={this.handleSubmit}>
            <FormGroup>
              <Label for="userName">Nom d'utilisateur</Label>
              <Input
                id="userName"
                name="userName"
                placeholder="Nom d'utilisateur"
                value={this.state.user.userName || ""}
                onChange={this.handleInputChange}
                disabled={this.state.isBusy}
              />
            </FormGroup>

            <FormGroup>
              <Label for="surname">Nom</Label>
              <Input
                id="surname"
                name="surname"
                placeholder="Nom"
                value={this.state.user.surname || ""}
                onChange={this.handleInputChange}
                disabled={this.state.isBusy}
              />
            </FormGroup>

            <FormGroup>
              <Label for="name">Prénom</Label>
              <Input
                id="name"
                name="name"
                placeholder="Prénom"
                value={this.state.user.name || ""}
                onChange={this.handleInputChange}
                disabled={this.state.isBusy}
              />
            </FormGroup>

            <FormGroup>
              <Label for="emailAddress">E-mail</Label>
              <Input
                id="emailAddress"
                name="emailAddress"
                type="email"
                placeholder="E-mail"
                value={this.state.user.emailAddress || ""}
                onChange={this.handleInputChange}
                disabled={this.state.isBusy}
              />
            </FormGroup>

            {this.state.user.id && !this.state.mustFillPassword && (
              <Button
                type="button"
                style={{ margin: "15px 0" }}
                color="warning"
                size="sm"
                disabled={this.state.isBusy}
                onClick={() => this.setState({ mustFillPassword: true })}
              >
                Modifier mot de passe
              </Button>
            )}

            {this.state.mustFillPassword && (
              <FormGroup>
                <Label for="password">Mot de passe</Label>
                <Input
                  id="password"
                  name="password"
                  type="password"
                  placeholder="Mot de passe"
                  value={this.state.user.password}
                  onChange={this.handleInputChange}
                  disabled={this.state.isBusy}
                />
              </FormGroup>
            )}

            {this.state.mustFillPassword && (
              <FormGroup>
                <Label for="passwordConfirmation">
                  Confirmation mot de passe
                </Label>
                <Input
                  id="passwordConfirmation"
                  name="passwordConfirmation"
                  type="password"
                  placeholder="Confirmation mot de passe"
                  value={this.state.user.passwordConfirmation}
                  onChange={this.handleInputChange}
                  disabled={this.state.isBusy}
                />
              </FormGroup>
            )}

            {this.state.mustFillPassword &&
              this.state.user.password &&
              this.state.user.passwordConfirmation &&
              this.state.user.password !==
                this.state.user.passwordConfirmation && (
                <Alert color="danger" style={{ margin: "20px 0" }}>
                  Le mot de passe et sa confirmation doivent être identiques
                </Alert>
              )}

            <input type="submit" style={{ display: "none" }} />
          </Form>

          {this.state.isBusy && (
            <Alert color="info" style={{ marginTop: "20px" }}>
              Merci de patienter...
            </Alert>
          )}

          {this.state.error && (
            <Alert color="danger" style={{ marginTop: "20px" }}>
              {this.state.error}
            </Alert>
          )}
        </ModalBody>

        <ModalFooter>
          <Button
            color="secondary"
            onClick={onClose}
            disabled={this.state.isBusy}
          >
            Annuler
          </Button>{" "}
          <Button
            color="primary"
            onClick={this.handleSubmit}
            disabled={isFormInvalid || this.state.isBusy}
          >
            Valider
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps)(Radium(EditProfileModal));
