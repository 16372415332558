export const applicableTicketType = {
    afterSale: 1,
    installationRequest: 2,
    moveRequest: 4
};

export const applicableTicketTypeName = {
    [applicableTicketType.afterSale]: 'Intervention SAV',
    [applicableTicketType.installationRequest]: 'Demande d\'installation',
    [applicableTicketType.moveRequest]: `Demande de déplacement linéaire`
};

// Used for className
export const ticketTypeId = {
    [applicableTicketType.afterSale]: 'sav',
    [applicableTicketType.installationRequest]: 'installation',
    [applicableTicketType.moveRequest]: 'move'
}