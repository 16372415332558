import axios from 'axios';
import * as ApiSettings from './apiSettings';
import { getDefaultHeaders, getAllDefaultParams } from './apiSettings';

const servicePath = '/api/services/app/Customer';

export const getCustomersApi = (authToken) => {
    return axios.get(`${ApiSettings.apiBaseUrl}${servicePath}/GetAll`, {
        params: { ...getAllDefaultParams() },
        headers: {
            ...getDefaultHeaders(),
            'Authorization': `Bearer ${authToken}`
        }
    });
};

export const createCustomerApi = (authToken, customer) => {
    return axios.post(`${ApiSettings.apiBaseUrl}${servicePath}/Create`, {
        ...customer
    }, {
        headers: {
            ...getDefaultHeaders(),
            'Authorization': `Bearer ${authToken}`
        }
    });
};

export const updateCustomerApi = (authToken, customer) => {
    return axios.put(`${ApiSettings.apiBaseUrl}${servicePath}/Update`, {
        ...customer
    }, {
        headers: {
            ...getDefaultHeaders(),
            'Authorization': `Bearer ${authToken}`
        }
    });
};

export const deleteCustomerApi = (authToken, customerId) => {
    return axios.delete(`${ApiSettings.apiBaseUrl}${servicePath}/Delete`, {
        headers: {
            ...getDefaultHeaders(),
            'Authorization': `Bearer ${authToken}`
        },
        params: {
            Id: customerId
        }
    });
};