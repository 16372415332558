import React, { Component } from "react";
import { connect } from "react-redux";
import Radium from "radium";
import { getInstallationRequestTickets } from "../../actions/tickets";
import ReactTable from "react-table";
import moment from "moment";
import { push } from "connected-react-router";
import Enumerable from "linq";
import "./installation.css";
// import { downloadCSV } from "../../helpers/csv";
import { CSVLink } from "react-csv";

const columns = [
  {
    Header: "N° de demande",
    accessor: "friendlyNum",
  },
  {
    Header: "Nom du demandeur",
    accessor: "creatorName",
  },
  {
    Header: "Date de la demande",
    accessor: "creationTime",
    Cell: (props) => <div>{moment(props.value).format("DD-MM-YYYY")}</div>,
    filterMethod: (filter, row) =>
      moment(row[filter.id])
        .format("DD-MM-YYYY")
        .indexOf(filter.value) !== -1,
  },
  {
    Header: "Nom du magasin",
    accessor: "customer.name",
  },
  {
    Header: "Nom du contact magasin",
    accessor: "customer.managerName",
  },
  {
    Header: "Type de configuration",
    accessor: "hardwareTypes",
  },
  {
    Header: "Connectivité",
    accessor: "hasInternetAccess",
    Cell: (props) => <div>{props.value ? "Oui" : "Non"}</div>,
    filterMethod: (filter, row) => {
      if (filter.value === "all") {
        return true;
      }
      if (filter.value === "true") {
        return row[filter.id] === true;
      }
      return row[filter.id] === false || row[filter.id] === null;
    },
    Filter: ({ filter, onChange }) => (
      <select
        onChange={(event) => onChange(event.target.value)}
        style={{ width: "100%" }}
        value={filter ? filter.value : "all"}
      >
        <option value="all">Tous</option>
        <option value="true">Oui</option>
        <option value="false">Non</option>
      </select>
    ),
  },
  {
    Header: "Adresse postale",
    accessor: "customer.address",
  },
  {
    Header: `Date d'intervention souhaitée`,
    accessor: "requestedDate",

    Cell: (props) => (
      <div>{props.value ? moment(props.value).format("DD-MM-YYYY") : ""}</div>
    ),
    filterMethod: (filter, row) =>
      moment(row[filter.id])
        .format("DD-MM-YYYY")
        .indexOf(filter.value) !== -1,
  },
  {
    Header: `Heure d'intervention souhaitée`,
    accessor: "requestedTimeRangeStart",
  },
  {
    Header: "Commentaires",
    accessor: "description",
  },
];

const formatCSVValue = (accessor, value, original) => {
  let result = value;
  switch (accessor) {
    case "creationTime":
      result = moment(value).format("DD-MM-YYYY");
      break;
    case "hasInternetAccess":
      result = value === true ? "Oui" : "Non";
      break;
    case "requestedDate":
      result = moment(value).format("DD-MM-YYYY");
      break;
    case "description":
      result = value && value.replaceAll('"', "'");
      break;
    default:
      result = value;
      break;
  }
  return result;
};

class InstallationList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dataToDownload: [],
    };
  }

  componentDidMount() {
    this.props.dispatch(getInstallationRequestTickets());
  }

  handleDownload = () => {
    const currentRecords = this.reactTable.getResolvedState().sortedData;
    var data = [];
    for (var index = 0; index < currentRecords.length; index++) {
      let record_to_download = {};
      for (var colIndex = 0; colIndex < columns.length; colIndex++) {
        record_to_download[columns[colIndex].Header] = formatCSVValue(
          columns[colIndex].accessor,
          currentRecords[index][columns[colIndex].accessor],
          currentRecords[index]._original
        );
      }
      data.push(record_to_download);
    }
    this.setState({ dataToDownload: data }, () => {
      // downloadCSV(this.state.dataForDownload, "installationList.csv");
      setTimeout(() => {
        this.csvLink.link.click();
      }, 500);
    });
  };

  render() {
    const { tickets } = this.props;
    let formattedTickets = null;
    if (tickets && tickets.items) {
      // order by creationTime
      formattedTickets = Enumerable.from(tickets.items)
        .orderByDescending((o) => o.creationTime)
        .toArray();

      // hardwareTypes format
      formattedTickets = formattedTickets.map((ticket) => {
        let newTicket = { ...ticket };
        let hardwareTypesString = "";
        newTicket.hardwareTypes &&
          newTicket.hardwareTypes.map((hardwareType) => {
            return (hardwareTypesString += hardwareTypesString
              ? ", " + hardwareType.name
              : hardwareType.name);
          });
        newTicket.hardwareTypes = hardwareTypesString;

        // requestedDate format
        newTicket.requestedDate =
          newTicket.requestedDateYear && newTicket.requestedDateDayOfYear
            ? moment()
                .year(newTicket.requestedDateYear)
                .dayOfYear(newTicket.requestedDateDayOfYear)
                .toDate()
            : null;
        return newTicket;
      });
    }

    const styles = {
      title: {
        textAlign: "center",
        padding: "10px",
      },
    };

    return (
      <div style={{ margin: "20px" }}>
        <h1 style={styles.title}>Tickets - Demandes d'installations</h1>
        {/* <div style={{ marginBottom: '10px' }}>
                    <a className="btn btn-warning btn-sm" href={`${apiBaseUrl}/api/excelExport/exportAllInstallationRequests?enc_auth_token=${encodeURIComponent(this.props.authentication.encryptedToken)}`}>Export</a>
                </div> */}
        {formattedTickets && (
          <button
            onClick={this.handleDownload}
            style={{ marginBottom: 5, cursor: "pointer" }}
          >
            Export CSV
          </button>
        )}
        <CSVLink
          data={this.state.dataToDownload}
          separator={";"}
          ref={(r) => (this.csvLink = r)}
          style={{ display: "none" }}
          filename={"installationList.csv"}
        >
          Download me
        </CSVLink>

        {formattedTickets && (
          <ReactTable
            ref={(r) => (this.reactTable = r)}
            data={formattedTickets}
            columns={columns}
            minRows={10}
            noDataText="Aucun ticket"
            ofText="sur"
            nextText="Suivant"
            previousText="Précédent"
            rowsText="lignes"
            filterable
            defaultFilterMethod={(filter, row) => {
              // remove spaces from strings because you can have multiple spaces
              // stored in database but CSS will not necessarily render them all
              return (
                String(row[filter.id])
                  .toLowerCase()
                  .replace(/\s/g, "")
                  .indexOf(filter.value.toLowerCase().replace(/\s/g, "")) !== -1
              );
            }}
            getTrProps={(state, rowInfo, column, instance) => ({
              onClick: (e, handleOriginal) => {
                this.props.dispatch(
                  push("/installation/" + rowInfo.original.id)
                );
              },
            })}
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  authentication: state.authentication,
  settings: state.settings,
  tickets: state.tickets.installationTickets,
});

export default connect(mapStateToProps)(Radium(InstallationList));
