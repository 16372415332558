export const apiBaseUrl = window.ticketingManager.apiBaseUrl;
// export const apiBaseUrl = 'https://apitickets.sys1v2.instore-solution.fr';

export const getDefaultHeaders = () => {
    return {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
    };
};

export const getAllDefaultParams = () => {
    return {
        'MaxResultCount': 2147483647
    };
};

export const getResponseExceptionMessage = (ex) => {

    if (ex.response && ex.response.data && ex.response.data.error){

        return ex.response.data.error.message;

    }

    else {
        return ex.message;
    }

};