export const permissions = {
    PagesTenant: "Pages.Tenants",
    PagesUsers: "Pages.Users",
    PagesRoles: "Pages.Roles",

    UpdateTickets: "TicketingManager.UpdateTickets",
    UpdateTicketDates: "TicketingManager.UpdateTicketDates",
    UpdateTicketStatus: "TicketingManager.UpdateTicketStatus",
    UpdateTicketInstoreStatus: "TicketingManager.UpdateTicketInstoreStatus",
    
    CreateAfterSaleTicket: "TicketingManager.CreateAfterSaleTicket",
    CreateInstallationRequestTicket: "TicketingManager.CreateInstallationRequestTicket",
    CreateMoveRequestTicket: "TicketingManager.CreateMoveRequestTicket",
    
    PageInstallationRequestTickets: "TicketingManager.PageInstallationRequestTickets",
    PageProgressTrackingTickets: "TicketingManager.PageProgressTrackingTickets",
    PageAfterSaleTickets: "TicketingManager.PageAfterSaleTickets",
    PageMoveRequestTickets: "TicketingManager.PageMoveRequestTickets",
    PageProgressMoveRequestTickets: "TicketingManager.PageProgressMoveRequestTickets",
    
    ManageCustomers: "TicketingManager.ManageCustomers",
    ManageHardwares: "TicketingManager.ManageHardwares",
    ManageHardwareTypes: "TicketingManager.ManageHardwareTypes",
};

export const hasPermission = (permissions, permissionToCheck) => {
    if(permissions.indexOf(permissionToCheck) !== -1){
        return true;
    }
    return false;
}