import axios from 'axios';
import * as ApiSettings from './apiSettings';

const servicePath = '/api/services/app/account';

export const resetPassword = (userNameOrEmailAddress, tenancyName, authToken) => {
    
    return axios.post(`${ApiSettings.apiBaseUrl}${servicePath}/resetPassword`, {

        userNameOrEmailAddress,
        tenancyName

    }, {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${authToken}`
        }
    });

};

export const getProfile = (authToken) => {
    
    return axios.get(`${ApiSettings.apiBaseUrl}${servicePath}/getProfile`, {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${authToken}`
        }
    });

};

export const saveProfile = (user, authToken) => {
    
    return axios.post(`${ApiSettings.apiBaseUrl}${servicePath}/saveProfile`, {

        user

    }, {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${authToken}`
        }
    });

};