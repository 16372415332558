import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";
import { authentication } from "./authentication";
import { notifications } from "./notifications";
import { modals } from "./modals";
import { settings } from "./settings";
import { hardwares } from "./hardwares";
import { hardwareTypes } from "./hardwareTypes";
import { customers } from "./customers";
import { tickets } from "./tickets";
import { users } from "./users";
import { contacts } from "./contacts";
import { customerGroups } from "./customerGroups";
import { roles } from "./roles";

// Do not put router or settings because on re-init it causes crash
const initialState = {
  authentication: {},
  hardwares: {},
  hardwareTypes: {},
  customers: {},
  tickets: {},
  users: {},
  contacts: {},
  roles: {},
  customerGroups: {},
};

const appReducer = (history) =>
  combineReducers({
    router: connectRouter(history),
    authentication,
    notifications,
    modals,
    settings,
    hardwares,
    hardwareTypes,
    customers,
    tickets,
    users,
    contacts,
    roles,
    customerGroups,
  });

const createRootReducer = (history) => (state, action) => {
  if (action.type === "LOGOUT") {
    return {
      ...state,
      ...initialState,
    };
  }
  return appReducer(history)(state, action);
};

export default createRootReducer;
