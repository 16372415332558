import React, { Component } from 'react';
import { connect } from 'react-redux';
import backgroundImage from '../../images/diageo_background.jpg';
import Image from '../../components/ImageComponent';
import { Button, Form, Input } from 'reactstrap';
import Radium from 'radium';
import { resetPassword } from '../../actions/account';
import { createNotification } from '../../actions/notifications';
import { push } from 'connected-react-router';

class ForgotPassword extends Component {

    state = { userNameOrEmailAddress: '', isBusy: false, error: null };

    handleInputChange = (e) => {

        const propName = e.target.name;
        const propValue = e.target.value;

        this.setState({ 
            [propName]: propValue 
        });

    };

    handleSubmit = (e) => {

        e.preventDefault();

        if (this.isFormInvalid() || this.state.isBusy){
            return;
        }

        this.setState({ isBusy: true, error: null }, async() => {

            const resetResult = await this.props.dispatch(resetPassword(this.state.userNameOrEmailAddress));

            this.setState({ isBusy: false, error: resetResult.error }, () => {
                if (!resetResult.error){
                    this.props.dispatch(createNotification('Un e-mail contenant votre nouveau mot de passe vient de vous être envoyé', 'success'));
                    this.props.dispatch(push('/signin'))
                }
                else{
                    this.props.dispatch(createNotification(resetResult.error, 'danger'));
                }
            });

        });
    };

    isFormInvalid = () => !this.state.userNameOrEmailAddress;

    render() {

        const { settings } = this.props;

        const isFormInvalid = this.isFormInvalid();

        const styles = {
            root: {
                position: 'absolute',
                width: '100%',
                height: '100%',
                backgroundImage: `url(${backgroundImage})`,
                backgroundRepeat:  'no-repeat',
                backgroundSize: 'cover',
                overflow: 'hidden',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
            },

            rootOverlay: {
                background: 'black',
                opacity: '0.5',
                position: 'absolute',
                top: '0',
                left: '0',
                width: '100%',
                height: '100%'
            },

            loginBox: {
                position: 'relative',
                zIndex: 2,
                background: '#FFF',
                maxWidth: '315px',
                width: '100%',
                borderRadius: '10px',
                boxShadow: '0 0 10px #000',
                overflow: 'hidden'
            },

            loginButton: {
                borderRadius: '20px',
                width: '100%', 
                fontSize: '17px',
                fontWeight: '500',
                padding: '8px',
                border: 'none',
                background: '#B59759',
                color: '#FFF'
            },

            fieldLabel: {
                color: '#000',
                fontSize: '12px',
                padding: '0 50px',
            },

            textField: {
                border: '1px solid',
                boxShadow: 'none',
                fontSize: '17px',
                color: '#000',
                padding: '5px 0',
                borderColor: '#B59759'
            }
        };

        return (

            <div style={styles.root}>

                <div style={styles.rootOverlay}></div>

                <div style={styles.loginBox}>

                    <div style={{ background: settings.data.logoBackgroundColor, padding: '20px 80px' }}>
                        <Image src={settings.data.logo} style={{ width: '100%', height: '100px' }} />
                    </div>

                    <div style={{ fontSize: '16px', fontWeight: '500', color: '#000', textAlign: 'center'}}>
                        Mot de passe oublié
                    </div>

                    <Form onSubmit={this.handleSubmit}>

                        <div style={{ ...styles.fieldLabel, marginTop: '30px' }}>
                            Nom d'utilisateur ou adresse e-mail
                        </div>

                        <div style={{ padding: '2px 50px', marginBottom: '40px' }}>
                            <Input type="text" name="userNameOrEmailAddress" style={styles.textField} value={this.state.userNameOrEmailAddress} onChange={this.handleInputChange} disabled={this.state.isBusy} />
                        </div>

                        <div style={{ padding: '0 50px', marginBottom: '30px' }}>
                            <Button type="submit" style={styles.loginButton} disabled={isFormInvalid || this.state.isBusy}>Valider</Button>
                        </div>

                    </Form>

                    {this.state.isBusy && (<div style={{ textAlign: 'center', padding: '10px' }}>

                        Veuillez patienter...

                    </div>)}

                    {this.state.error && (<div style={{ color: 'red', textAlign: 'center', padding: '10px' }}>

                        {this.state.error}

                    </div>)}

                </div>

            </div>

        );
    }
}

const mapStateToProps = state => ({
    authentication: state.authentication,
    settings: state.settings
});

export default connect(mapStateToProps)(Radium(ForgotPassword));
