import React, { Component } from 'react';
import { connect } from 'react-redux';
import { authenticate, authenticateWithToken } from '../../actions/authentication';
import { push } from 'connected-react-router';
import backgroundImage from '../../images/login_background.jpg';
import Image from '../../components/ImageComponent';
import { Button, Form, Input } from 'reactstrap';
import Radium from 'radium';

class Authenticate extends Component {

    state = { login: this.props.authentication.login, password: '' };

    async componentDidMount(){
        
        try {
            const loginInfo = localStorage.getItem('loginInfo');

            if (loginInfo && !this.props.authentication.token){
                
                const lastLoginInfo = JSON.parse(loginInfo);

                if (lastLoginInfo){
                    await this.props.dispatch(authenticateWithToken(lastLoginInfo.login, lastLoginInfo.token, lastLoginInfo.encryptedToken));
                }
            }
        }
        catch(error){
            console.error(error);
        }

    }

    componentDidUpdate() {

        if (this.props.authentication.token && !this.props.authentication.isAuthenticating && !this.props.authentication.error) {
            this.props.dispatch(push('/'));
        }

    }

    handleInputChange = (e) => {

        this.setState({ [e.target.name]: e.target.value });

    };

    handleSubmit = (e) => {

        e.preventDefault();

        if (this.isFormDisabled()){
            return;
        }

        this.props.dispatch(authenticate(this.state.login, this.state.password, this.props.settings.data.tenancyName));

    };

    isFormDisabled = () => this.props.authentication.isAuthenticating || !this.state.login || !this.state.password;

    render() {

        const { authentication, settings } = this.props;

        const isFormDisabled = this.isFormDisabled();

        const styles = {
            root: {
                position: 'absolute',
                width: '100%',
                height: '100%',
                backgroundImage: `url(${backgroundImage})`,
                backgroundRepeat:  'no-repeat',
                backgroundSize: 'cover',
                overflow: 'hidden',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
            },

            rootOverlay: {
                background: 'black',
                opacity: '0.5',
                position: 'absolute',
                top: '0',
                left: '0',
                width: '100%',
                height: '100%'
            },

            loginBox: {
                position: 'relative',
                zIndex: 2,
                background: '#FFF',
                maxWidth: '315px',
                width: '100%',
                borderRadius: '10px',
                boxShadow: '0 0 10px #000',
                overflow: 'hidden'
            },

            loginButton: {
                borderRadius: '20px',
                width: '100%', 
                fontSize: '17px',
                fontWeight: '500',
                padding: '8px',
                border: 'none',
                background: '#B59759',
                color: '#FFF'
            },

            fieldLabel: {
                color: '#000',
                fontSize: '12px',
                padding: '0 50px'
            },

            textField: {
                border: '1px solid',
                boxShadow: 'none',
                fontSize: '17px',
                color: '#000',
                padding: '5px 0',
                borderColor: '#B59759'
            }
        };

        return (

            <div style={styles.root}>

                <div style={styles.rootOverlay}></div>

                <div style={styles.loginBox}>

                    <div style={{ background: settings.data.logoBackgroundColor, padding: '20px 80px 0px 80px' }}>
                        <Image src={settings.data.logo} style={{ width: '100%', height: '100px' }} />
                    </div>

                    <div style={{ margin: '15px 0', fontSize: '20px', textAlign: 'center' }}>
                        MagPlaner
                    </div>

                    <Form onSubmit={this.handleSubmit}>

                        <div style={styles.fieldLabel}>
                            Nom d'utilisateur
                        </div>

                        <div style={{ padding: '0 50px', marginBottom: '20px' }}>
                            <Input type="text" name="login" style={styles.textField} value={this.state.login} onChange={this.handleInputChange} disabled={authentication.isAuthenticating} />
                        </div>

                        <div style={styles.fieldLabel}>
                            Mot de passe
                        </div>

                        <div style={{ padding: '0 50px', marginBottom: '50px' }}>
                            <Input type="password" name="password" style={styles.textField} value={this.state.password} onChange={this.handleInputChange} disabled={authentication.isAuthenticating} />
                        </div>

                        <div style={{ padding: '0 50px', marginBottom: '30px' }}>
                            <Button type="submit" style={styles.loginButton} disabled={isFormDisabled}>Connexion</Button>
                        </div>

                        <div onClick={() => this.props.dispatch(push('/forgotpassword'))} style={{ fontSize: '12px', color: '#000', textAlign: 'center', cursor: 'pointer', marginBottom: '30px' }}>
                            Mot de passe oublié ?
                        </div>

                    </Form>

                    {authentication.isAuthenticating && (<div style={{ color: '#000', textAlign: 'center', padding: '10px' }}>

                        Connexion en cours...

                    </div>)}

                    {authentication.error && (<div style={{ color: 'red', textAlign: 'center', padding: '10px' }}>

                        {authentication.error}

                    </div>)}

                </div>

            </div>

        );
    }
}

const mapStateToProps = state => ({
    authentication: state.authentication,
    settings: state.settings
});

export default connect(mapStateToProps)(Radium(Authenticate));
