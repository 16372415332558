const initialState = {
    data: null,
    isLoading: false,
    error: null
};

export const settings = (state = initialState, action) => {

    switch (action.type){

        case 'LOAD_SETTINGS_REQUEST':

            return {
                ...state,
                isLoading: true
            };

        case 'LOAD_SETTINGS_RESPONSE':

            return {
                ...state,
                data: action.data,
                error: action.error,
                isLoading: false
            };

            default:
            break;
    }

    return state;

};