import React, { Component } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  FormGroup,
  Label,
  Input,
  Alert,
} from "reactstrap";
import {
  getAllContacts,
  createContact,
  updateContact,
  deleteContact,
} from "../../actions/contacts";
import Radium from "radium";
import { pushModalAsync, dismissModalAsync } from "../../actions/modals";
import ConfirmModal from "../../components/ConfirmModal";
import { connect } from "react-redux";

class EditContactModal extends Component {
  state = {
    contact: {
      ...this.props.contact,
    },
  };

  componentWillMount = (e) => {};

  handleInputChange = (e) => {
    const propName = e.target.name;
    const propValue = e.target.value;
    this.setState((prevState, props) => ({
      contact: {
        ...prevState.contact,
        [propName]: propValue,
      },
    }));
  };

  deleteContact = () => {
    this.props.dispatch(
      pushModalAsync(ConfirmModal, {
        title: "Supprimer contact",
        message: "Êtes-vous sûr de vouloir supprimer ce contact ?",
        yesButtonLabel: "OUI",
        noButtonLabel: "NON",
        onClose: (modalId, modalResult) => {
          this.props.dispatch(dismissModalAsync(modalId));
          if (modalResult) {
            this.setState(
              {
                error: null,
                isBusy: true,
              },
              async () => {
                const deleteResponse = await this.props.dispatch(
                  deleteContact(this.state.contact.id)
                );
                if (!deleteResponse.error) {
                  this.props.dispatch(getAllContacts());
                  this.props.onClose();
                } else {
                  this.setState({ error: deleteResponse.error, isBusy: false });
                }
              }
            );
          }
        },
      })
    );
  };

  handleSubmit = (e) => {
    e.preventDefault();
    if (this.isFormInvalid() || this.state.isBusy) {
      return;
    }
    this.setState(
      {
        error: null,
        isBusy: true,
      },
      async () => {
        const contact = {
          ...this.state.contact,
        };
        let saveResponse = null;

        if (contact.id) {
          saveResponse = await this.props.dispatch(updateContact(contact));
        } else {
          saveResponse = await this.props.dispatch(createContact(contact));
        }

        if (!saveResponse.error) {
          this.props.dispatch(getAllContacts());
          this.props.onClose();
        } else {
          this.setState({ error: saveResponse.error, isBusy: false });
        }
      }
    );
  };

  isFormInvalid = () =>
    !this.state.contact.lastname ||
    !this.state.contact.firstname ||
    !this.state.contact.email ||
    !this.state.contact.phone;

  render() {
    const { isOpen, onClose } = this.props;
    const isFormInvalid = this.isFormInvalid();

    return (
      <Modal
        isOpen={isOpen}
        toggle={!this.state.isBusy ? onClose : null}
        size="lg"
      >
        <ModalHeader toggle={!this.state.isBusy ? onClose : null}>
          {this.state.contact.id ? "Détails contact" : "Nouveau contact"}
        </ModalHeader>

        <ModalBody>
          <Form onSubmit={this.handleSubmit}>
            <fieldset>
              <div style={{ width: "50%", float: "left", padding: "0 10px" }}>
                <FormGroup>
                  <Label for="lastname">Nom</Label>
                  <Input
                    id="lastname"
                    name="lastname"
                    placeholder="Nom"
                    value={this.state.contact.lastname}
                    onChange={this.handleInputChange}
                    disabled={this.state.isBusy}
                  />
                </FormGroup>

                <FormGroup>
                  <Label for="firstname">Prénom</Label>
                  <Input
                    id="firstname"
                    name="firstname"
                    placeholder="Prénom"
                    value={this.state.contact.firstname}
                    onChange={this.handleInputChange}
                    disabled={this.state.isBusy}
                  />
                </FormGroup>

                <FormGroup>
                  <Label for="email">Email</Label>
                  <Input
                    id="email"
                    name="email"
                    placeholder="Email"
                    type="email"
                    value={this.state.contact.email}
                    onChange={this.handleInputChange}
                    disabled={this.state.isBusy}
                  />
                </FormGroup>
              </div>

              <div style={{ width: "50%", float: "right", padding: "0 10px" }}>
                <FormGroup>
                  <Label for="role">Rôle</Label>
                  <Input
                    id="role"
                    name="role"
                    placeholder="Rôle"
                    value={this.state.contact.role}
                    onChange={this.handleInputChange}
                    disabled={this.state.isBusy}
                  />
                </FormGroup>

                <FormGroup>
                  <Label for="phone">Téléphone</Label>
                  <Input
                    id="phone"
                    name="phone"
                    value={this.state.contact.phone}
                    onChange={this.handleInputChange}
                    disabled={this.state.isBusy}
                  />
                </FormGroup>

                <FormGroup>
                  <Label for="weight">Ordre</Label>
                  <Input
                    id="weight"
                    name="weight"
                    type="number"
                    value={this.state.contact.weight}
                    onChange={this.handleInputChange}
                    disabled={this.state.isBusy}
                  />
                </FormGroup>
              </div>

              <div style={{ clear: "both", marginTop: "20px" }}>
                {this.state.isBusy && (
                  <Alert color="info">Merci de patienter...</Alert>
                )}

                {this.state.error && (
                  <Alert color="danger">{this.state.error}</Alert>
                )}
              </div>
            </fieldset>
          </Form>
        </ModalBody>

        <ModalFooter>
          {this.state.contact.id && (
            <Button type="button" color="danger" onClick={this.deleteContact}>
              Supprimer contact
            </Button>
          )}
          <Button
            color="secondary"
            onClick={onClose}
            disabled={this.state.isBusy}
          >
            Annuler
          </Button>{" "}
          <Button
            color="primary"
            onClick={this.handleSubmit}
            disabled={isFormInvalid || this.state.isBusy}
          >
            Valider
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}

export default connect()(Radium(EditContactModal));
