import {
  getContactApi,
  getAllContactsApi,
  createContactApi,
  updateContactApi,
  deleteContactApi,
} from "../api/contacts";
import { getResponseExceptionMessage } from "../api/apiSettings";
import { createActions } from "redux-actions";

export const {
  getContactRequest,
  getContactResponse,
  getAllContactsRequest,
  getAllContactsResponse,
  createContactRequest,
  createContactResponse,
  updateContactRequest,
  updateContactResponse,
  deleteContactRequest,
  deleteContactResponse,
} = createActions({
  GET_CONTACT_REQUEST: () => ({}),
  GET_CONTACT_RESPONSE: (input) => ({
    data: input.data,
    error: input.error,
  }),
  GET_ALL_CONTACTS_REQUEST: () => ({}),
  GET_ALL_CONTACTS_RESPONSE: (input) => ({
    data: input.data,
    error: input.error,
  }),
  CREATE_CONTACT_REQUEST: () => ({}),
  CREATE_CONTACT_RESPONSE: (input) => ({
    data: input.data,
    error: input.error,
  }),
  UPDATE_CONTACT_REQUEST: () => ({}),
  UPDATE_CONTACT_RESPONSE: (input) => ({
    data: input.data,
    error: input.error,
  }),
  DELETE_CONTACT_REQUEST: () => ({}),
  DELETE_CONTACT_RESPONSE: (input) => ({
    data: input.data,
    error: input.error,
  }),
});

export const getContact = (id) => {
  return async (dispatch, getState) => {
    dispatch(getContactRequest());
    const result = {};
    try {
      const apiResult = await getContactApi(
        getState().authentication.token,
        id
      );
      if (
        apiResult &&
        apiResult.data &&
        apiResult.data.success &&
        apiResult.data.result
      ) {
        result.data = apiResult.data.result;
      } else {
        result.error = `Une erreur est survenue`;
      }
    } catch (ex) {
      result.error = getResponseExceptionMessage(ex);
    }
    dispatch(getContactResponse(result));
    return result;
  };
};

export const getAllContacts = () => {
  return async (dispatch, getState) => {
    dispatch(getAllContactsRequest());
    const result = {};
    try {
      const apiResult = await getAllContactsApi(
        getState().authentication.token
      );
      if (
        apiResult &&
        apiResult.data &&
        apiResult.data.success &&
        apiResult.data.result
      ) {
        result.data = apiResult.data.result;
      } else {
        result.error = `Une erreur est survenue`;
      }
    } catch (ex) {
      result.error = getResponseExceptionMessage(ex);
    }
    dispatch(getAllContactsResponse(result));
    return result;
  };
};

export const createContact = (contact) => {
  return async (dispatch, getState) => {
    dispatch(createContactRequest());
    const result = {};
    try {
      const apiResult = await createContactApi(
        contact,
        getState().authentication.token
      );
      if (
        apiResult &&
        apiResult.data &&
        apiResult.data.success &&
        apiResult.data.result
      ) {
        result.data = true;
      } else {
        result.error = `Une erreur est survenue`;
      }
    } catch (ex) {
      result.error = getResponseExceptionMessage(ex);
    }
    dispatch(createContactResponse(result));
    return result;
  };
};

export const updateContact = (contact) => {
  return async (dispatch, getState) => {
    dispatch(updateContactRequest());
    const result = {};
    try {
      const apiResult = await updateContactApi(
        contact,
        getState().authentication.token
      );
      if (
        apiResult &&
        apiResult.data &&
        apiResult.data.success &&
        apiResult.data.result
      ) {
        result.data = true;
      } else {
        result.error = `Une erreur est survenue`;
      }
    } catch (ex) {
      result.error = getResponseExceptionMessage(ex);
    }
    dispatch(updateContactResponse(result));
    return result;
  };
};

export const deleteContact = (contactId) => {
  return async (dispatch, getState) => {
    dispatch(deleteContactRequest());
    const result = {};
    try {
      const apiResult = await deleteContactApi(
        contactId,
        getState().authentication.token
      );
      if (apiResult && apiResult.data && apiResult.data.success) {
        result.data = true;
      } else {
        result.error = `Une erreur est survenue`;
      }
    } catch (ex) {
      result.error = getResponseExceptionMessage(ex);
    }
    dispatch(deleteContactResponse(result));
    return result;
  };
};
