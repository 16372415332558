import sleep from "../helpers/sleep";

export const pushModalAsync = (component, props) => {

    return (dispatch, getState) => {

        const pushModalAction = pushModal(component, props);

        dispatch(pushModalAction);

        return pushModalAction.id;

    }

};

const pushModal = (component, props) => ({
    type: 'PUSH_MODAL',
    id: props.id || new Date().getTime(),
    component,
    props
});

export const dismissModalAsync = (modalId) => {

    return async(dispatch, getState) => {

        dispatch(dismissModalStart(modalId));
        await sleep(300);
        dispatch(dismissModalEnd(modalId));

    }
};

const dismissModalStart = (modalId) => ({
    type: 'DISMISS_MODAL_START',
    modalId
});

const dismissModalEnd = (modalId) => ({
    type: 'DISMISS_MODAL_END',
    modalId
});


