import axios from "axios";
import * as ApiSettings from "./apiSettings";
import { getAllDefaultParams } from "./apiSettings";

const servicePath = "/api/services/app/Contact";

export const getContactApi = (authToken, id) => {
  return axios.get(`${ApiSettings.apiBaseUrl}${servicePath}/Get`, {
    params: {
      Id: id,
    },
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${authToken}`,
    },
  });
};

export const getAllContactsApi = (authToken) => {
  return axios.get(`${ApiSettings.apiBaseUrl}${servicePath}/GetAll`, {
    params: { ...getAllDefaultParams() },
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${authToken}`,
    },
  });
};

export const createContactApi = (contact, authToken) => {
  return axios.post(
    `${ApiSettings.apiBaseUrl}${servicePath}/Create`,
    {
      ...contact,
    },
    {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${authToken}`,
      },
    }
  );
};

export const updateContactApi = (contact, authToken) => {
  return axios.put(
    `${ApiSettings.apiBaseUrl}${servicePath}/Update`,
    {
      ...contact,
    },
    {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${authToken}`,
      },
    }
  );
};

export const deleteContactApi = (contactId, authToken) => {
  return axios.delete(`${ApiSettings.apiBaseUrl}${servicePath}/Delete`, {
    params: {
      Id: contactId,
    },
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${authToken}`,
    },
  });
};
