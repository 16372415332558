import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Switch, Route, Redirect } from 'react-router-dom';
import Authenticate from './routes/authenticate/Authenticate';
import Chrome from './components/ManagerChrome';
import Radium from 'radium';
import { loadSettings } from './actions/settings';
import ForgotPassword from './routes/authenticate/ForgotPassword';
import { push } from 'connected-react-router';
import Notification from './components/Notification';

class App extends Component {

    state = {
        urlToRedirect: null,
        redirected: false
    }

    componentDidMount() {
        if(!window.location.href.endsWith('/#/') && this.state.urlToRedirect === null){
            this.setState({ urlToRedirect: window.location.href });
        }
        if (!this.props.settings.data){
            const host = window.location.host;
            this.props.dispatch(loadSettings(host));
        }
    }

    componentDidUpdate(){
        if(this.state.urlToRedirect && !this.state.redirected && !window.location.href.endsWith('signin') && !window.location.href.endsWith('forgotpassword')){
            var urlSplit = this.state.urlToRedirect.split('#');
            this.props.dispatch(push(urlSplit[1]));
            this.setState({ redirected: true });
        }
    }

    render() {
        const { authentication, modals, settings, notifications } = this.props;
        const styles = {
            notificationsContainer: {
                position: 'absolute',
                bottom: '10px',
                right: '10px'
            }
        };
        if (settings.data){
            return (
                <div>
                    {/* <Switch location={this.props.location}> */}
                    <Switch>
                        <Route exact={true} path="/signin" component={Authenticate} />
                        <Route exact={true} path="/forgotpassword" component={ForgotPassword} />
                        <Route path="/" render={props => authentication.token ? (<Chrome {...props} />) : <Redirect to="/signin" /> } />
                    </Switch>
                    
                    {modals.items && modals.items.map((modal, index) => (
                        <modal.component key={modal.id} {...modal.props} />
                    ))}

                    <div style={styles.notificationsContainer}>
                        {notifications && notifications.items && notifications.items.map((notification, index) => (
                            <Notification key={notification.id} notification={notification} />
                        ))}
                    </div>
                </div>
            );
        }
        else {
            return (
                <div>
                </div>
            );
        }
    }
}

const mapStateToProps = state => ({
    router: state.router,
    authentication: state.authentication,
    notifications: state.notifications,
    modals: state.modals,
    settings: state.settings
});

export default connect(mapStateToProps)(Radium(App));