import { getCustomersApi, createCustomerApi, updateCustomerApi, deleteCustomerApi } from '../api/customers';
// import { getResponseExceptionMessage } from '../api/apiSettings';

// GetAll
const getCustomersRequest = () => {
    return {
        type: 'GET_CUSTOMERS_REQUEST'
    };
};

const getCustomersResponse = (input) => {
    return {
        type: 'GET_CUSTOMERS_RESPONSE',
        data: input.data,
        error: input.error
    };
};

export const getCustomers = () => {

    return async (dispatch, getState) => {

        dispatch(getCustomersRequest());
        const result = {};

        try {
            const apiResult = await getCustomersApi(getState().authentication.token);
            result.data = apiResult.data.result;
        }
        catch (ex) {
            result.error = ex.message || 'Une erreur est survenue';
        }

        dispatch(getCustomersResponse(result));
    };

};

// Create
const createCustomerRequest = () => {
    return {
        type: 'CREATE_CUSTOMER_REQUEST'
    };
};

const createCustomerResponse = (input) => {
    return {
        type: 'CREATE_CUSTOMER_RESPONSE',
        data: input.data,
        error: input.error
    };
};

export const createCustomer = (customer) => {

    return async (dispatch, getState) => {

        dispatch(createCustomerRequest());
        const result = {};

        try {
            const apiResult = await createCustomerApi(getState().authentication.token, customer);
            result.data = apiResult.data.result;
        }
        catch (ex) {
            result.error = ex.message || 'Une erreur est survenue';
        }

        dispatch(createCustomerResponse(result));
        return result;
    };

};

// Update
const updateCustomerRequest = () => {
    return {
        type: 'UPDATE_CUSTOMER_REQUEST'
    };
};

const updateCustomerResponse = (input) => {
    return {
        type: 'UPDATE_CUSTOMER_RESPONSE',
        data: input.data,
        error: input.error
    };
};

export const updateCustomer = (customer) => {

    return async (dispatch, getState) => {

        dispatch(updateCustomerRequest());
        const result = {};

        try {
            const apiResult = await updateCustomerApi(getState().authentication.token, customer);
            result.data = apiResult.data.result;
        }
        catch (ex) {
            result.error = ex.message || 'Une erreur est survenue';
        }

        dispatch(updateCustomerResponse(result));
        return result;
    };

};

// Delete
const deleteCustomerRequest = () => {
    return {
        type: 'DELETE_CUSTOMER_REQUEST'
    };
};

const deleteCustomerResponse = (input) => {
    return {
        type: 'DELETE_CUSTOMER_RESPONSE',
        data: input.data,
        error: input.error
    };
};

export const deleteCustomer = (customerId) => {

    return async (dispatch, getState) => {

        dispatch(deleteCustomerRequest());
        const result = {};

        try {
            const apiResult = await deleteCustomerApi(getState().authentication.token, customerId);
            result.data = apiResult.data.result;
        }
        catch (ex) {
            result.error = ex.message || 'Une erreur est survenue';
        }

        dispatch(deleteCustomerResponse(result));
        return result;
    };
};