import axios from 'axios';
import * as ApiSettings from './apiSettings';

const servicePath = '/api/file';


export const upload = (formData, onUploadProgress, authToken) => {
    
    return axios.post(`${ApiSettings.apiBaseUrl}${servicePath}/upload`, formData, {
        
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'multipart/form-data',
            'Authorization': `Bearer ${authToken}`
        },
        onUploadProgress
        
    });

};