import React, { Component } from "react";
import { connect } from "react-redux";
import Radium from "radium";
import "react-big-calendar/lib/css/react-big-calendar.css";
import BigCalendar from "react-big-calendar";
import {
  getAfterSaleTickets,
  getInstallationRequestTickets,
  getMoveRequestTickets,
} from "../../actions/tickets";
import moment from "moment";
import { getDate, getTimeWithProp } from "../../helpers/date";
import { applicableTicketType, ticketTypeId } from "../../helpers/ticketTypes";
import { pushModalAsync, dismissModalAsync } from "../../actions/modals";
import "./planning.css";
import EventPreviewModal from "../../components/EventPreviewModal";
import { permissions } from "../../helpers/permissions";

class Planning extends Component {
  componentDidMount() {
    const { authentication } = this.props;
    if (authentication.user && authentication.user.permissionNames) {
      if (
        authentication.user.permissionNames.indexOf(
          permissions.PageInstallationRequestTickets
        ) !== -1
      ) {
        this.props.dispatch(getInstallationRequestTickets());
      }
      if (
        authentication.user.permissionNames.indexOf(
          permissions.PageAfterSaleTickets
        ) !== -1
      ) {
        this.props.dispatch(getAfterSaleTickets());
      }
      if (
        authentication.user.permissionNames.indexOf(
          permissions.PageMoveRequestTickets
        ) !== -1
      ) {
        this.props.dispatch(getMoveRequestTickets());
      }
    }
  }

  initCalendar = (tickets, type = null) => {
    if (tickets) {
      let newEvents = [];
      tickets.forEach((ticket) => {
        const year = ticket.expectedDateYear;
        const dayOfYear = ticket.expectedDateDayOfYear;
        const timeStart = ticket.expectedTimeRangeStart;
        const timeEnd = ticket.expectedTimeRangeEnd;
        let expectedDateStart = null;
        // let expectedDateEnd = null;
        if (year && dayOfYear) {
          if (timeStart) {
            const timeStartObj = getTimeWithProp(timeStart);
            expectedDateStart = getDate(
              year,
              dayOfYear,
              timeStartObj.hours,
              timeStartObj.minutes
            );
            if (timeEnd) {
              // const timeEndObj = getTimeWithProp(timeEnd);
              // expectedDateEnd = getDate(year, dayOfYear, timeEndObj.hours, timeEndObj.minutes);
            } else {
              // expectedDateEnd = getDate(year, dayOfYear, parseInt(timeStartObj.hours) + 1, timeStartObj.minutes);
            }
          } else {
            expectedDateStart = getDate(year, dayOfYear);
            // expectedDateEnd = getDate(year, dayOfYear);
          }
          const event = {
            id: ticket.id,
            title: ticket.customer.name,
            start: expectedDateStart,
            end: expectedDateStart, // expectedDateEnd
            ticket: ticket,
            type: type,
          };
          newEvents.push(event);
        }
      });
      return newEvents;
    }
  };

  handleClickEvent = (event, e) => {
    this.props.dispatch(
      pushModalAsync(EventPreviewModal, {
        event: event,
        onClose: (modalId, modalResult) => {
          this.props.dispatch(dismissModalAsync(modalId));
        },
      })
    );
  };

  eventStyleGetter = (event, start, end, isSelected) => {
    if (event.type) {
      return {
        className: "event-" + event.type,
        style: null,
      };
    }
  };

  render() {
    const localizer = BigCalendar.momentLocalizer(moment);
    const { tickets } = this.props;
    let events = [];

    if (tickets.savTickets && tickets.savTickets.items) {
      let trimmedTickets = [];
      tickets.savTickets.items.forEach((ticket) => {
        // Ticket should appear if status = scheduled || status = done
        if (ticket.currentStatus === 2 || ticket.currentStatus === 4) {
          trimmedTickets.push(ticket);
        }
      });
      const savEvents =
        this.initCalendar(
          [...trimmedTickets],
          ticketTypeId[applicableTicketType.afterSale]
        ) || [];
      events = [...events, ...savEvents];
    }
    if (tickets.installationTickets && tickets.installationTickets.items) {
      let trimmedTickets = [];
      tickets.installationTickets.items.forEach((ticket) => {
        // Ticket should appear if status = validated && instoreStatus = scheduled or done
        if (
          ticket.currentStatus === 2 &&
          (ticket.instoreStatus === 2 || ticket.instoreStatus === 3)
        ) {
          trimmedTickets.push(ticket);
        }
      });
      const installationEvents =
        this.initCalendar(
          [...trimmedTickets],
          ticketTypeId[applicableTicketType.installationRequest]
        ) || [];
      events = [...events, ...installationEvents];
    }
    if (tickets.moveTickets && tickets.moveTickets.items) {
      let trimmedTickets = [];
      tickets.moveTickets.items.forEach((ticket) => {
        // Ticket should appear if status = validated && instoreStatus = scheduled or done
        if (
          ticket.currentStatus === 2 &&
          (ticket.instoreStatus === 2 || ticket.instoreStatus === 3)
        ) {
          trimmedTickets.push(ticket);
        }
      });
      const moveEvents =
        this.initCalendar(
          [...trimmedTickets],
          ticketTypeId[applicableTicketType.moveRequest]
        ) || [];
      events = [...events, ...moveEvents];
    }

    if (!events || !events[0]) {
      return (
        <div>
          <h2 style={{ textAlign: "center", marginTop: "20px" }}>
            Pas de ticket au planning
          </h2>
        </div>
      );
    }

    return (
      <div className="planning">
        <div className="planning-calendar">
          <BigCalendar
            localizer={localizer}
            events={events}
            startAccessor="start"
            endAccessor="end"
            defaultView="month"
            onSelectEvent={this.handleClickEvent}
            eventPropGetter={this.eventStyleGetter}
            messages={{
              date: "Date",
              time: "Temps",
              event: "Événement",
              allDay: "Toute la journée",
              week: "Semaine",
              work_week: "Jour ouvrable",
              day: "Jour",
              month: "Mois",
              previous: "Précédent",
              next: "Suivant",
              yesterday: "Hier",
              tomorrow: "Demain",
              today: "Aujourd'hui",
              agenda: "Agenda",
              noEventsInRange: "Aucun évènement.",
              showMore: (total) => `+${total} de plus`,
            }}
          />
        </div>
        <div className="planning-legend">
          <div
            className={
              "planning-legend-item planning-legend-item-" +
              ticketTypeId[applicableTicketType.installationRequest]
            }
          >
            Installation
          </div>
          <div
            className={
              "planning-legend-item planning-legend-item-" +
              ticketTypeId[applicableTicketType.afterSale]
            }
          >
            SAV
          </div>
          <div
            className={
              "planning-legend-item planning-legend-item-" +
              ticketTypeId[applicableTicketType.moveRequest]
            }
          >
            Déplacement linéaire
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  authentication: state.authentication,
  tickets: state.tickets,
});

export default connect(mapStateToProps)(Radium(Planning));
