import { getAllRolesApi } from '../api/roles';
import { getResponseExceptionMessage } from '../api/apiSettings';
import { createActions } from 'redux-actions';

export const { getAllRolesRequest, getAllRolesResponse } = createActions({
    GET_ALL_ROLES_REQUEST: () => ({ }),
    GET_ALL_ROLES_RESPONSE: (input) => (
        {
            data: input.data,
            error: input.error 
        }
    )
});

export const getAllRoles = () => {
    return async (dispatch, getState) => {
        dispatch(getAllRolesRequest());
        const result = {};
        try {
            const apiResult = await getAllRolesApi(getState().authentication.token);
            if (apiResult && apiResult.data && apiResult.data.success && apiResult.data.result){
                result.data = apiResult.data.result;
            }
            else {
                result.error = `Une erreur est survenue`;
            }
        }
        catch (ex) {
            result.error = getResponseExceptionMessage(ex);
        }
        dispatch(getAllRolesResponse(result));
        return result;
    };
};