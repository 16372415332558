import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Button } from 'reactstrap';
import Radium from 'radium';
import ReactTable from 'react-table';
import { getHardwares } from '../../actions/hardwares';
import { pushModalAsync, dismissModalAsync } from '../../actions/modals';
import NewHardwareModal from '../../components/NewHardwareModal';
import { getAllRoles } from '../../actions/roles';

class Hardware extends Component {

    componentDidMount() {
        this.props.dispatch(getHardwares());
        this.props.dispatch(getAllRoles());
    }

    handleAddHardware = () => {
        this.props.dispatch(pushModalAsync(NewHardwareModal, {
            onClose: async(modalId, modalResult) => {
                this.props.dispatch(dismissModalAsync(modalId));
                if (modalResult){
                }
            }
        }));
    };

    render() {
        const { hardwares } = this.props;
        const columns = [{
            Header: 'Nom',
            accessor: 'name'
          },
          {
            Header: 'Type de configuration',
            accessor: 'hardwareHardwareTypes',
            filterable: false,
            Cell: props => {
                let result = "";
                props.value.forEach(hardwareHardwareType => {
                    result += result ? ', ' + hardwareHardwareType.hardwareTypeName : hardwareHardwareType.hardwareTypeName;
                });
                return result;
            }
        },
          {
            Header: 'Role(s)',
            accessor: 'hardwareRoles',
            filterable: false,
            Cell: props => {
                let result = "";
                props.value.forEach(hardwareRole => {
                    result += result ? ', ' + hardwareRole.role.displayName : hardwareRole.role.displayName;
                });
                return result;
            }
          }];

          const styles = {
            title: {
                textAlign: 'center',
                padding: '10px'
            },
            addHardware: {
                padding: '0 0 5px 0'
            }
        };

        return (

            <div style={{ padding: '20px' }}>
                <h1 style={styles.title}>Gestion des matériels</h1>
                <div style={styles.addHardware}>
                    <Button color="primary" onClick={this.handleAddHardware}>Ajouter un matériel</Button>
                </div>
                {hardwares && hardwares.items && (
                    <ReactTable 
                        data={hardwares.items}
                        columns={columns}
                        filterable
                        minRows={10}
                        noDataText="Aucun matériel"
                        ofText="sur"
                        nextText="Suivant"
                        previousText="Précédent"
                        rowsText="lignes"
                        defaultFilterMethod={(filter, row) => {
                            if (!filter.value) {
                                return true;
                            }
                            return row[filter.id] && row[filter.id].toLowerCase().indexOf(filter.value.toLowerCase()) !== -1;
                        }}
                        getTrProps={(state, rowInfo, column, instance) => ({
                            onClick: (e, handleOriginal) => {
                                this.props.dispatch(pushModalAsync(NewHardwareModal, {
                                    hardware: rowInfo.original,
                                    onClose: async(modalId, modalResult) => {
                                        this.props.dispatch(dismissModalAsync(modalId));
                                        if (modalResult){
                                        }
                                    }
                                }));
                            }
                        })}
                    />
                )}
            </div>
        );
    }
}

const mapStateToProps = state => ({
    authentication: state.authentication,
    hardwares: state.hardwares,
    roles: state.roles
});

export default connect(mapStateToProps)(Radium(Hardware));