import axios from 'axios';
import * as ApiSettings from './apiSettings';
import { getAllDefaultParams } from './apiSettings';

const servicePath = '/api/services/app/Role';

export const getAllRolesApi = (authToken) => {
    return axios.get(`${ApiSettings.apiBaseUrl}${servicePath}/GetAll`, {
        params: { ...getAllDefaultParams() },
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${authToken}`
        }
    });
};