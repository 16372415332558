import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Modal, ModalHeader, ModalBody, ModalFooter, Input, Label, Form, FormGroup, Button, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem, CustomInput } from 'reactstrap';
import { getCustomers, createCustomer, updateCustomer, deleteCustomer } from '../actions/customers';
import Radium from 'radium';
import { pushModalAsync, dismissModalAsync } from '../actions/modals';
import ConfirmModal from '../components/ConfirmModal';
import { getAllCustomerGroups } from '../actions/customerGroups';
import Enumerable from 'linq';

class NewCustomerModal extends Component{

    // this.state.customer: 
    // name
    // code
    // managerName
    // function
    // address
    // city
    // postalCode
    // country
    // phone
    // contactPhone
    // faxPC
    // isTerroirEcosse
    // email
    // customerGroup
    // customerGroupId
    state = {
        isBusy: false,
        error: null,
        customer: {
            ...this.props.customer
        }
    };

    componentDidMount() {
        this.props.dispatch(getAllCustomerGroups());
    }

    dismiss = (modalResult) => {
        this.props.onClose(modalResult);
    };

    handleInputChange = (e) => {
        const propName = e.target.name;
        let propValue = e.target.value;
        let changes = {};
        switch(propName){
            case 'name':
            case 'managerName':
                changes = {
                    [propName]: propValue.toUpperCase()
                }
            break;
            case 'isTerroirEcosse':
                changes = {
                    [propName]: !this.state.customer.isTerroirEcosse,
                }
            break;
            default:
                changes = {
                    [propName]: propValue
                }
            break;
        }

        this.setState((prevState, prevProps) => ({
            customer: { 
                ...prevState.customer,
                ...changes
            }
        }));
    };

    handleSubmit = (e) => {
        e.preventDefault();

        if (this.isFormInvalid() || this.state.isBusy) {
            return;
        }

        this.setState({ isBusy: true, error: null }, async() => {

            let saveResult = null;
            if(this.state.customer.id  != null){
                saveResult = await this.props.dispatch(updateCustomer(this.state.customer));
            }
            else{
                saveResult = await this.props.dispatch(createCustomer(this.state.customer));
            }

            if (!saveResult.error){
                this.props.dispatch(getCustomers());
                this.props.onClose(saveResult);
            }
            else {
                this.setState({ error: saveResult.error });
            }

            this.setState({ isBusy: false });
        });
    };

    handleDelete = (e) => {
        e.preventDefault();

        if (this.state.isBusy) {
            return;
        }

        this.props.dispatch(pushModalAsync(ConfirmModal, {
            title: 'Supprimer un magasin',
            message: 'Êtes-vous sûr de vouloir supprimer ce magasin ?',
            yesButtonLabel: 'OUI',
            noButtonLabel: 'NON',
            onClose: (modalId, modalResult) => {

                this.props.dispatch(dismissModalAsync(modalId));

                if (modalResult){

                    this.setState({
                        error: null, 
                        isBusy : true
            
                    }, async() => {
                        
                        const deleteResponse = await this.props.dispatch(deleteCustomer(this.state.customer.id));
            
                        if (!deleteResponse.error){
                            this.props.dispatch(getCustomers());
                            this.props.onClose({isDeleted: true});
                        }
                        else {
                            this.setState({ error: deleteResponse.error, isBusy: false });
                        }
                    });
                }
            }
        }));
    };

    isFormInvalid = () => {
        const isFormInvalid = !this.state.customer.name || !this.state.customer.address || !this.state.customer.code
        || !this.state.customer.city || !this.state.customer.postalCode || !this.state.customer.country
        || !this.state.customer.phone || !this.state.customer.email || !this.state.customer.managerName
        || !this.state.customer.function || !this.state.customer.customerGroupId || !this.state.customer.contactPhone
        || !this.state.customer.faxPC;
        
        // If we have a customerGroupId, check if not deleted
        if(this.state.customer.customerGroupId){
            return isFormInvalid || !Enumerable.from(this.props.customerGroups.customerGroups).where(o => o.id === this.state.customer.customerGroupId).firstOrDefault();
        }
        return isFormInvalid;
    }
    
    render(){
        const { customer } = this.state;
        const { customerGroups, authentication } = this.props;
        const { isOpen } = this.props;
        let isFormInvalid = this.isFormInvalid();
        const customerGroup = customer && customer.customerGroupId && customerGroups.customerGroups ? Enumerable.from(customerGroups.customerGroups).firstOrDefault(o => o.id === customer.customerGroupId) : null;
        
        // Check for restricting rights on specific fields
        // Fields without restriction: nom/prénom/tel/mail du contact en magasin
        let userHasLimitedRights = true;
        if(authentication && authentication.user && authentication.user.roleNames){
            // If only 1 role (RS) restrict rights
            if(authentication.user.roleNames.length === 1 && authentication.user.roleNames[0] === "RS"){
                userHasLimitedRights = true;
            }
            else{
                userHasLimitedRights = false;
            }
        }

        return (
            <Modal isOpen={isOpen} toggle={() => this.dismiss(false)} size="lg">

                <ModalHeader>
                    {Object.keys(customer).length !== 0
                    && <span>Modification du magasin "{customer.name}"</span>
                    }
                    {Object.keys(customer).length === 0
                    && <span>Ajouter un magasin</span>
                    }
                </ModalHeader>

                <ModalBody>
                    <Form style={{display: 'flex'}} onSubmit={this.handleSubmit}>
                        <div style={{width: '50%', float: 'left', padding: '0 10px'}}>
                            <FormGroup>
                                <Label for="text">Nom du magasin*</Label>
                                <Input style={{textTransform: 'uppercase'}} type='text' name="name" value={this.state.customer.name || ''} onChange={this.handleInputChange} autoFocus />
                            </FormGroup>
                            <FormGroup>
                                <Label for="text">Adresse*</Label>
                                <Input disabled={userHasLimitedRights} type='text' name="address" value={this.state.customer.address || ''} onChange={this.handleInputChange} />
                            </FormGroup>
                            <FormGroup>
                                <Label for="text">Code postal*</Label>
                                <Input disabled={userHasLimitedRights} type='text' name="postalCode" value={this.state.customer.postalCode || ''} onChange={this.handleInputChange} />
                            </FormGroup>
                            <FormGroup>
                                <Label for="text">Téléphone*</Label>
                                <Input id="text" type='text' name="phone" value={this.state.customer.phone || ''} onChange={this.handleInputChange} />
                            </FormGroup>
                            <FormGroup>
                                <Label for="text">Nom du contact magasin*</Label>
                                <Input style={{textTransform: 'uppercase'}} type='text' name="managerName" value={this.state.customer.managerName || ''} onChange={this.handleInputChange} />
                            </FormGroup>
                            <FormGroup>
                                <Label for="text">Téléphone du contact magasin*</Label>
                                <Input id="text" type='text' name="contactPhone" value={this.state.customer.contactPhone || ''} onChange={this.handleInputChange} />
                            </FormGroup>
                            <FormGroup>
                                <CustomInput id="isTerroirEcosse" type="checkbox" label="Terroir d'Écosse" name="isTerroirEcosse"
                                    checked={(this.state.customer.isTerroirEcosse) ? true : false} onChange={this.handleInputChange}
                                />
                            </FormGroup>
                        </div>
                        <div style={{width: '50%', float: 'right', padding: '0 10px'}}>
                            <FormGroup>
                                <Label for="text">Code*</Label>
                                <Input disabled={userHasLimitedRights} type='text' name="code" value={this.state.customer.code || ''} onChange={this.handleInputChange} />
                            </FormGroup>
                            <FormGroup>
                                <Label for="text">Ville*</Label>
                                <Input disabled={userHasLimitedRights} type='text' name="city" value={this.state.customer.city || ''} onChange={this.handleInputChange} />
                            </FormGroup>
                            <FormGroup>
                                <Label for="text">Pays*</Label>
                                <Input disabled={userHasLimitedRights} type='text' name="country" value={this.state.customer.country || ''} onChange={this.handleInputChange} />
                            </FormGroup>
                            <FormGroup>
                                <Label for="text">Fax PC sécurité du magasin*</Label>
                                <Input id="text" type='text' name="faxPC" value={this.state.customer.faxPC || ''} onChange={this.handleInputChange} />
                            </FormGroup>
                            <FormGroup>
                                <Label for="text">Fonction*</Label>
                                <Input type='text' name="function" value={this.state.customer.function || ''} onChange={this.handleInputChange} />
                            </FormGroup>
                            <FormGroup>
                                <Label for="text">Email*</Label>
                                <Input id="text" type='text' name="email" value={this.state.customer.email || ''} onChange={this.handleInputChange} />
                            </FormGroup>
                            <FormGroup>
                                {customerGroups.customerGroups && 
                                    <UncontrolledDropdown>
                                        <div>Groupe*</div>
                                        <DropdownToggle caret style={{ marginTop: '10px', width: '200px' }} disabled={userHasLimitedRights}>
                                            {customerGroup ? customerGroup.name : 'Choix du groupe'}
                                        </DropdownToggle>
                                        <DropdownMenu style={{ maxHeight: '300px', overflow: 'auto' }}>
                                            {customerGroups.customerGroups.map(customerGroup => (
                                                <DropdownItem key={customerGroup.id}
                                                onClick={() => this.handleInputChange({ target: { name: 'customerGroupId', value: customerGroup.id } })}>
                                                    {customerGroup.name}
                                                </DropdownItem>
                                            ))}
                                        </DropdownMenu>
                                    </UncontrolledDropdown>}
                            </FormGroup>
                        </div>
                    </Form>
                    <div style={{textAlign: 'right'}}>Tous les champs doivent être renseignés*</div>
                </ModalBody>

                <ModalFooter>
                    {this.state.customer.id && <Button type="button" color="danger" onClick={this.handleDelete}>Supprimer le magasin</Button>}
                    <Button color="secondary" onClick={this.dismiss}>Annuler</Button>{' '}
                    <Button color="primary" onClick={this.handleSubmit} disabled={isFormInvalid}>Valider</Button>
                </ModalFooter>

            </Modal>
        );
    }
}

const mapStateToProps = state => ({
    authentication: state.authentication,
    customerGroups: state.customerGroups
});

export default connect(mapStateToProps)(Radium(NewCustomerModal));