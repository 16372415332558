import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Button } from 'reactstrap';
import Radium from 'radium';
import ReactTable from 'react-table';
import { getAllCustomerGroups } from '../../actions/customerGroups';
import NewCustomerGroupModal from '../../components/NewCustomerGroupModal';
import { pushModalAsync, dismissModalAsync } from '../../actions/modals';

class CustomerGroup extends Component {

    componentDidMount() {
        this.props.dispatch(getAllCustomerGroups());
    }

    handleAddCustomerGroup = () => {
        this.props.dispatch(pushModalAsync(NewCustomerGroupModal, {
            onClose: async(modalId, modalResult) => {
                this.props.dispatch(dismissModalAsync(modalId));
                if (modalResult){
                }
            }
        }));
    };

    render() {
        const { customerGroups } = this.props;
        const styles = {
            title: {
                textAlign: 'center',
                padding: '10px'
            },
            addClient: {
                padding: '0 0 5px 0'
            }
        };
        
        const columns = [{
            Header: 'Nom du groupe',
            accessor: 'name'
          }
        ];

        return (
            <div style={{ padding: '20px' }}>
                <h1 style={styles.title}>Gestion des groupes de magasins</h1>
                <div style={styles.addClient}>
                    <Button color="primary" onClick={this.handleAddCustomerGroup}>Ajouter un groupe</Button>
                </div>
                {customerGroups && customerGroups.customerGroups && (
                    <ReactTable 
                        data={customerGroups.customerGroups}
                        columns={columns}
                        filterable
                        minRows={10}
                        noDataText="Aucun groupe"
                        ofText="sur"
                        nextText="Suivant"
                        previousText="Précédent"
                        rowsText="lignes"
                        defaultFilterMethod={(filter, row) => {
                            if (!filter.value) {
                                return true;
                            }
                            return row[filter.id] && row[filter.id].toLowerCase().indexOf(filter.value.toLowerCase()) !== -1;
                        }}
                        getTrProps={(state, rowInfo, column, instance) => ({
                            onClick: (e, handleOriginal) => {
                                this.props.dispatch(pushModalAsync(NewCustomerGroupModal, {
                                    customerGroup: rowInfo.original,
                                    onClose: async(modalId, modalResult) => {
                                        this.props.dispatch(dismissModalAsync(modalId));
                                        if (modalResult){
                                        }
                                    }
                                }));
                            }
                        })}
                    />
                )}
            </div>
        );
    }
}

const mapStateToProps = state => ({
    authentication: state.authentication,
    customerGroups: state.customerGroups
});

export default connect(mapStateToProps)(Radium(CustomerGroup));