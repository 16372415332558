import { getUserApi, getAllUsersApi, createUserApi, updateUserApi, deleteUserApi } from '../api/users';
import { getResponseExceptionMessage } from '../api/apiSettings';
import { createActions } from 'redux-actions';

export const { getUserRequest, getUserResponse, getAllUsersRequest, getAllUsersResponse,
    createUserRequest, createUserResponse, updateUserRequest, updateUserResponse,
    deleteUserRequest, deleteUserResponse } = createActions({
    GET_USER_REQUEST: () => ({ }),
    GET_USER_RESPONSE: (input) => (
        {
            data: input.data,
            error: input.error 
        }
    ),
    GET_ALL_USERS_REQUEST: () => ({ }),
    GET_ALL_USERS_RESPONSE: (input) => (
        {
            data: input.data,
            error: input.error 
        }
    ),
    CREATE_USER_REQUEST: () => ({ }),
    CREATE_USER_RESPONSE: (input) => (
        {
            data: input.data,
            error: input.error 
        }
    ),
    UPDATE_USER_REQUEST: () => ({ }),
    UPDATE_USER_RESPONSE: (input) => (
        {
            data: input.data,
            error: input.error 
        }
    ),
    DELETE_USER_REQUEST: () => ({ }),
    DELETE_USER_RESPONSE: (input) => (
        {
            data: input.data,
            error: input.error 
        }
    )
});

export const getUser = (id) => {
    return async (dispatch, getState) => {
        dispatch(getUserRequest());
        const result = {};
        try {
            const apiResult = await getUserApi(getState().authentication.token, id);
            if (apiResult && apiResult.data && apiResult.data.success && apiResult.data.result){
                result.data = apiResult.data.result;
            }
            else {
                result.error = `Une erreur est survenue`;
            }
        }
        catch (ex) {
            result.error = getResponseExceptionMessage(ex);
        }
        dispatch(getUserResponse(result));
        return result;
    };
};

export const getAllUsers = () => {
    return async (dispatch, getState) => {
        dispatch(getAllUsersRequest());
        const result = {};
        try {
            const apiResult = await getAllUsersApi(getState().authentication.token);
            if (apiResult && apiResult.data && apiResult.data.success && apiResult.data.result){
                result.data = apiResult.data.result;
            }
            else {
                result.error = `Une erreur est survenue`;
            }
        }
        catch (ex) {
            result.error = getResponseExceptionMessage(ex);
        }
        dispatch(getAllUsersResponse(result));
        return result;
    };
};

export const createUser = (user) => {
    return async (dispatch, getState) => {
        dispatch(createUserRequest());
        const result = {};
        try {
            const apiResult = await createUserApi(user, getState().authentication.token);
            if (apiResult && apiResult.data && apiResult.data.success && apiResult.data.result){
                result.data = true;
            }
            else {
                result.error = `Une erreur est survenue`;
            }
        }
        catch (ex) {
            result.error = getResponseExceptionMessage(ex);
        }
        dispatch(createUserResponse(result));
        return result;
    };
};

export const updateUser = (user) => {
    return async (dispatch, getState) => {
        dispatch(updateUserRequest());
        const result = {};
        try {
            const apiResult = await updateUserApi(user, getState().authentication.token);
            if (apiResult && apiResult.data && apiResult.data.success && apiResult.data.result){
                result.data = true;
            }
            else {
                result.error = `Une erreur est survenue`;
            }
        }
        catch (ex) {
            result.error = getResponseExceptionMessage(ex);
        }
        dispatch(updateUserResponse(result));
        return result;
    };
};

export const deleteUser = (userId) => {
    return async (dispatch, getState) => {
        dispatch(deleteUserRequest());
        const result = {};
        try {
            const apiResult = await deleteUserApi(userId, getState().authentication.token);
            if (apiResult && apiResult.data && apiResult.data.success){
                result.data = true;
            }
            else {
                result.error = `Une erreur est survenue`;
            }
        }
        catch (ex) {
            result.error = getResponseExceptionMessage(ex);
        }
        dispatch(deleteUserResponse(result));
        return result;
    };
};