import { getHardwaresApi, createHardwareApi, updateHardwareApi, deleteHardwareApi } from '../api/hardwares';
// import { getResponseExceptionMessage } from '../api/apiSettings';

//GetAll
const getHardwaresRequest = () => {
    return {
        type: 'GET_HARDWARES_REQUEST'
    };
};

const getHardwaresResponse = (input) => {
    return {
        type: 'GET_HARDWARES_RESPONSE',
        data: input.data,
        error: input.error
    };
};

export const getHardwares = () => {

    return async (dispatch, getState) => {

        dispatch(getHardwaresRequest());
        const result = {};

        try {
            const apiResult = await getHardwaresApi(getState().authentication.token);
            result.data = apiResult.data.result;
        }
        catch (ex) {
            result.error = ex.message || 'Une erreur est survenue';
        }

        dispatch(getHardwaresResponse(result));
    };

};

// Create
const createHardwareRequest = () => {
    return {
        type: 'CREATE_HARDWARE_REQUEST'
    };
};

const createHardwareResponse = (input) => {
    return {
        type: 'CREATE_HARDWARE_RESPONSE',
        data: input.data,
        error: input.error
    };
};

export const createHardware = (hardware) => {

    return async (dispatch, getState) => {

        dispatch(createHardwareRequest());
        const result = {};

        try {
            const apiResult = await createHardwareApi(getState().authentication.token, hardware);
            result.data = apiResult.data.result;
        }
        catch (ex) {
            result.error = ex.message || 'Une erreur est survenue';
        }

        dispatch(createHardwareResponse(result));

        return result;
    };

};

// Update
const updateHardwareRequest = () => {
    return {
        type: 'UPDATE_HARDWARE_REQUEST'
    };
};

const updateHardwareResponse = (input) => {
    return {
        type: 'UPDATE_HARDWARE_RESPONSE',
        data: input.data,
        error: input.error
    };
};

export const updateHardware = (hardware) => {

    return async (dispatch, getState) => {

        dispatch(updateHardwareRequest());
        const result = {};

        try {
            const apiResult = await updateHardwareApi(getState().authentication.token, hardware);
            result.data = apiResult.data.result;
        }
        catch (ex) {
            result.error = ex.message || 'Une erreur est survenue';
        }

        dispatch(updateHardwareResponse(result));
        return result;
    };

};

// Delete
const deleteHardwareRequest = () => {
    return {
        type: 'DELETE_HARDWARE_REQUEST'
    };
};

const deleteHardwareResponse = (input) => {
    return {
        type: 'DELETE_HARDWARE_RESPONSE',
        data: input.data,
        error: input.error
    };
};

export const deleteHardware = (hardwareId) => {

    return async (dispatch, getState) => {

        dispatch(deleteHardwareRequest());
        const result = {};

        try {
            const apiResult = await deleteHardwareApi(getState().authentication.token, hardwareId);
            result.data = apiResult.data.result;
        }
        catch (ex) {
            result.error = ex.message || 'Une erreur est survenue';
        }

        dispatch(deleteHardwareResponse(result));
        return result;
    };
};