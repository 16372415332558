import { handleActions } from 'redux-actions';

const initialState = {
    currentRole: null,
    items: null,
    count: null
};

export const roles = handleActions(
    {
        GET_ALL_ROLES_RESPONSE: (state, action) => ({
            ...state,
            items: action.payload.data.items,
            count: action.payload.data.totalCount
        })   
    },
    initialState
);