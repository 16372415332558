import React, { Component } from "react";
import { connect } from "react-redux";
import Radium from "radium";
import { getMoveRequestTickets } from "../../actions/tickets";
import ReactTable from "react-table";
import { push } from "connected-react-router";
import moment from "moment";
import {
  moveRequestStatusType,
  moveRequestStatusTypeName,
  moveRequestInstoreStatusType,
  moveRequestInstoreStatusTypeName,
} from "../../helpers/moveRequestStatus";
import Enumerable from "linq";
import { formatDatetime } from "../../helpers/date";
// import { downloadCSV } from "../../helpers/csv";
import { CSVLink } from "react-csv";

const getStatusLabel = (ticket) => {
  let statusLabel = moveRequestInstoreStatusTypeName[ticket.instoreStatus];
  if (
    ticket.instoreStatus === moveRequestInstoreStatusType.scheduled ||
    ticket.instoreStatus === moveRequestInstoreStatusType.done ||
    ticket.instoreStatus === moveRequestInstoreStatusType.rejected
  ) {
    switch (ticket.instoreStatus) {
      case moveRequestInstoreStatusType.done:
      case moveRequestInstoreStatusType.rejected:
        statusLabel +=
          " le " +
          formatDatetime(ticket.instoreStatusLastUpdate, "DD/MM/YYYY HH:mm");
        break;
      case moveRequestInstoreStatusType.scheduled:
        if (ticket.expectedDateYear && ticket.expectedDateDayOfYear) {
          statusLabel +=
            " le " +
            moment()
              .year(ticket.expectedDateYear)
              .dayOfYear(ticket.expectedDateDayOfYear)
              .format("DD-MM-YYYY");
        }
        if (ticket.expectedTimeRangeStart) {
          statusLabel += " " + ticket.expectedTimeRangeStart;
        }
        if (ticket.expectedTimeRangeEnd) {
          statusLabel += "-" + ticket.expectedTimeRangeEnd;
        }
        break;
      default:
        break;
    }
  }
  return statusLabel;
};

const formatCSVValue = (accessor, value, original) => {
  let result = value;
  switch (accessor) {
    case "creationTime":
      result = moment(value).format("DD-MM-YYYY");
      break;
    case "hasInternetAccess":
      result = value === true ? "Oui" : "Non";
      break;
    case "expectedDate":
      result = moment(value).format("DD-MM-YYYY");
      break;
    case "instoreStatus":
      result = getStatusLabel(original);
      break;
    case "currentStatus":
      result = moveRequestStatusTypeName[value];
      break;
    case "description":
      result = value && value.replaceAll('"', "'");
      break;
    default:
      result = value;
      break;
  }
  return result;
};

const columns = [
  {
    Header: "N° de demande",
    accessor: "friendlyNum",
  },
  {
    Header: "Nom du demandeur",
    accessor: "creatorName",
  },
  {
    Header: "Date de la demande",
    accessor: "creationTime",
    Cell: (props) => <div>{moment(props.value).format("DD-MM-YYYY")}</div>,
    filterMethod: (filter, row) =>
      moment(row[filter.id])
        .format("DD-MM-YYYY")
        .indexOf(filter.value) !== -1,
  },
  {
    Header: "Nom du magasin",
    accessor: "customer.name",
  },
  {
    Header: "Nom du contact magasin",
    accessor: "customer.managerName",
  },
  {
    Header: "Type de configuration",
    accessor: "hardwareTypes",
  },
  {
    Header: "Connectivité",
    accessor: "hasInternetAccess",
    Cell: (props) => <div>{props.value ? "Oui" : "Non"}</div>,
    filterMethod: (filter, row) => {
      if (filter.value === "all") {
        return true;
      }
      if (filter.value === "true") {
        return row[filter.id] === true;
      }
      return row[filter.id] === false || row[filter.id] === null;
    },
    Filter: ({ filter, onChange }) => (
      <select
        onChange={(event) => onChange(event.target.value)}
        style={{ width: "100%" }}
        value={filter ? filter.value : "all"}
      >
        <option value="all">Tous</option>
        <option value="true">Oui</option>
        <option value="false">Non</option>
      </select>
    ),
  },
  {
    Header: "Adresse postale",
    accessor: "customer.address",
  },
  {
    Header: `Date d'intervention planifiée`,
    accessor: "expectedDate",
    Cell: (props) => (
      <div>{props.value ? moment(props.value).format("DD-MM-YYYY") : ""}</div>
    ),
    filterMethod: (filter, row) =>
      moment(row[filter.id])
        .format("DD-MM-YYYY")
        .indexOf(filter.value) !== -1,
  },
  {
    Header: `Heure d'intervention planifiée`,
    accessor: "expectedTimeRange",
  },
  {
    Header: "Commentaires",
    accessor: "description",
  },
  {
    Header: "Statut MHD",
    accessor: "currentStatus",
    className: "status-column",
    width: 200,
    Cell: (props) => {
      return (
        <div className={"status-move-" + props.value}>
          {moveRequestStatusTypeName[props.value]}
        </div>
      );
    },
    filterMethod: (filter, row) => {
      if (filter.value === "all") {
        return true;
      } else {
        return row[filter.id].toString() === filter.value;
      }
    },
    Filter: ({ filter, onChange }) => (
      <select
        onChange={(event) => onChange(event.target.value)}
        style={{ width: "100%" }}
        value={filter ? filter.value : "all"}
      >
        <option value="all">Tous</option>
        {Object.keys(moveRequestStatusType).map((key) => {
          return (
            <option key={key} value={moveRequestStatusType[key]}>
              {moveRequestStatusTypeName[moveRequestStatusType[key]]}
            </option>
          );
        })}
      </select>
    ),
  },
  {
    Header: "Statut",
    accessor: "instoreStatus",
    className: "status-column",
    width: 200,
    Cell: (props) => {
      const statusLabel = getStatusLabel(props.original);
      return (
        <div className={"status-instore-" + props.value} title={statusLabel}>
          {statusLabel}
        </div>
      );
    },
    filterMethod: (filter, row) => {
      if (filter.value === "all") {
        return true;
      } else {
        return row[filter.id].toString() === filter.value;
      }
    },
    Filter: ({ filter, onChange }) => (
      <select
        onChange={(event) => onChange(event.target.value)}
        style={{ width: "100%" }}
        value={filter ? filter.value : "all"}
      >
        <option value="all">Tous</option>
        {Object.keys(moveRequestInstoreStatusType).map((key) => {
          return (
            <option key={key} value={moveRequestInstoreStatusType[key]}>
              {
                moveRequestInstoreStatusTypeName[
                  moveRequestInstoreStatusType[key]
                ]
              }
            </option>
          );
        })}
      </select>
    ),
  },
];

class MoveTracking extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dataToDownload: [],
    };
  }

  componentDidMount() {
    this.props.dispatch(getMoveRequestTickets());
  }

  handleDownload = () => {
    const currentRecords = this.reactTable.getResolvedState().sortedData;
    var data = [];
    for (var index = 0; index < currentRecords.length; index++) {
      let record_to_download = {};
      for (var colIndex = 0; colIndex < columns.length; colIndex++) {
        record_to_download[columns[colIndex].Header] = formatCSVValue(
          columns[colIndex].accessor,
          currentRecords[index][columns[colIndex].accessor],
          currentRecords[index]._original
        );
      }
      data.push(record_to_download);
    }
    this.setState({ dataToDownload: data }, () => {
      // downloadCSV(this.state.dataForDownload, "moveTracking.csv");
      setTimeout(() => {
        this.csvLink.link.click();
      }, 500);
    });
  };

  render() {
    const { tickets } = this.props;

    let formattedTickets = null;
    if (tickets && tickets.items) {
      // filter on status
      formattedTickets = Enumerable.from(tickets.items)
        .where((o) => o.currentStatus === moveRequestStatusType.validated)
        .toArray();

      // order by creationTime
      formattedTickets = Enumerable.from(formattedTickets)
        .orderByDescending((o) => o.creationTime)
        .toArray();

      // hardwareTypes format
      formattedTickets = formattedTickets.map((ticket) => {
        let newTicket = { ...ticket };
        let hardwareTypesString = "";
        newTicket.hardwareTypes &&
          newTicket.hardwareTypes.map((hardwareType) => {
            return (hardwareTypesString += hardwareTypesString
              ? ", " + hardwareType.name
              : hardwareType.name);
          });
        newTicket.hardwareTypes = hardwareTypesString;

        // expectedDate format
        newTicket.expectedDate =
          newTicket.expectedDateYear && newTicket.expectedDateDayOfYear
            ? moment()
                .year(newTicket.expectedDateYear)
                .dayOfYear(newTicket.expectedDateDayOfYear)
                .toDate()
            : null;

        // expectedTimeRange format
        newTicket.expectedTimeRange = "";
        if (newTicket.expectedTimeRangeStart) {
          newTicket.expectedTimeRange = newTicket.expectedTimeRangeStart;
        }
        if (newTicket.expectedTimeRangeEnd) {
          newTicket.expectedTimeRange += "-" + newTicket.expectedTimeRangeEnd;
        }

        return newTicket;
      });
    }

    const styles = {
      title: {
        textAlign: "center",
        padding: "10px",
      },
    };

    return (
      <div style={{ margin: "20px" }}>
        <h1 style={styles.title}>Tickets - Déplacements linéaires</h1>

        {/* <div style={{ marginBottom: '10px' }}>
                    <a className="btn btn-warning btn-sm" href={`${apiBaseUrl}/api/excelExport/exportAllMoveRequests?enc_auth_token=${encodeURIComponent(this.props.authentication.encryptedToken)}&isTracking=true`}>Export</a>
                </div> */}
        {formattedTickets && (
          <button
            onClick={this.handleDownload}
            style={{ marginBottom: 5, cursor: "pointer" }}
          >
            Export CSV
          </button>
        )}
        <CSVLink
          data={this.state.dataToDownload}
          separator={";"}
          ref={(r) => (this.csvLink = r)}
          style={{ display: "none" }}
          filename={"moveTracking.csv"}
        >
          Download me
        </CSVLink>
        {formattedTickets && (
          <ReactTable
            ref={(r) => (this.reactTable = r)}
            data={formattedTickets}
            columns={columns}
            minRows={10}
            noDataText="Aucun ticket"
            ofText="sur"
            nextText="Suivant"
            previousText="Précédent"
            rowsText="lignes"
            filterable
            defaultFilterMethod={(filter, row) =>
              String(row[filter.id])
                .toLowerCase()
                .indexOf(filter.value.toLowerCase()) !== -1
            }
            getTrProps={(state, rowInfo, column, instance) => ({
              onClick: (e, handleOriginal) => {
                this.props.dispatch(push("/move/" + rowInfo.original.id));
              },
            })}
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  authentication: state.authentication,
  settings: state.settings,
  tickets: state.tickets.moveTickets,
});

export default connect(mapStateToProps)(Radium(MoveTracking));
