import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Modal, ModalHeader, ModalBody, ModalFooter, Input, Label, Form, FormGroup, Button } from 'reactstrap';
import { getAllCustomerGroups, createCustomerGroup, updateCustomerGroup, deleteCustomerGroup } from '../actions/customerGroups';
import Radium from 'radium';
import { pushModalAsync, dismissModalAsync } from '../actions/modals';
import ConfirmModal from '../components/ConfirmModal';

class NewCustomerGroupModal extends Component{

    state = {
        isBusy: false,
        error: null,
        customerGroup: {
            name: '',
            // parentGroupId: '',
            // parentGroup: '',
            ...this.props.customerGroup
        }
    };

    // componentDidMount() {
    //     this.props.dispatch(getAllCustomerGroups());
    // }

    dismiss = (modalResult) => {
        this.props.onClose(modalResult);
    };

    handleInputChange = (e) => {
        const propName = e.target.name;
        let propValue = e.target.value;

        if (propName === 'name') {
            propValue = propValue.toUpperCase();
        }

        this.setState((prevState, prevProps) => ({
            customerGroup: { 
                ...prevState.customerGroup,
                [propName]: propValue 
            }
        }));
    };

    handleSubmit = (e) => {
        e.preventDefault();

        if (this.isFormInvalid() || this.state.isBusy) {
            return;
        }

        this.setState({ isBusy: true, error: null }, async() => {
            let saveResult = null;
            if(this.state.customerGroup.id  != null){
                saveResult = await this.props.dispatch(updateCustomerGroup(this.state.customerGroup));
            }
            else{
                saveResult = await this.props.dispatch(createCustomerGroup(this.state.customerGroup));
            }

            if (!saveResult.error){
                this.props.dispatch(getAllCustomerGroups());
                this.props.onClose(saveResult);
            }
            else {
                this.setState({ error: saveResult.error });
            }
            this.setState({ isBusy: false });
        });
    };

    handleDelete = (e) => {
        e.preventDefault();

        if (this.isFormInvalid() || this.state.isBusy) {
            return;
        }

        this.props.dispatch(pushModalAsync(ConfirmModal, {
            title: 'Supprimer un groupe',
            message: 'Êtes-vous sûr de vouloir supprimer ce groupe ?',
            yesButtonLabel: 'OUI',
            noButtonLabel: 'NON',
            onClose: (modalId, modalResult) => {
                this.props.dispatch(dismissModalAsync(modalId));
                if (modalResult){
                    this.setState({
                        error: null, 
                        isBusy : true
            
                    }, async() => {
                        const deleteResponse = await this.props.dispatch(deleteCustomerGroup(this.state.customerGroup.id));
                        if (!deleteResponse.error){
                            this.props.dispatch(getAllCustomerGroups());
                            this.props.onClose({isDeleted: true});
                        }
                        else {
                            this.setState({ error: deleteResponse.error, isBusy: false });
                        }
                    });
                }
            }
        }));
    };

    isFormInvalid = () => !this.state.customerGroup.name;

    render(){
        const { isOpen } = this.props;
        // const { customerGroups, isOpen } = this.props;
        // const currentCustomerGroup = this.state.customerGroup;
        // const parentCustomerGroup = currentCustomerGroup && currentCustomerGroup.parentGroupId && customerGroups.customerGroups ? Enumerable.from(customerGroups.customerGroups).firstOrDefault(o => o.id === currentCustomerGroup.parentGroupId) : null;
        let isFormInvalid = this.isFormInvalid();

        return (
            <Modal isOpen={isOpen} toggle={() => this.dismiss(false)} size="lg">

                <ModalHeader>
                    Ajouter un groupe
                </ModalHeader>

                <ModalBody>
                    <Form onSubmit={this.handleSubmit}>
                        <FormGroup>
                            <Label for="text">Nom du groupe*</Label>
                            <Input id="text" style={{textTransform: 'uppercase'}} type='text' name="name" value={this.state.customerGroup.name} onChange={this.handleInputChange} autoFocus />
                        </FormGroup>
                        {/* <FormGroup>
                            {customerGroups.customerGroups && 
                            <UncontrolledDropdown>
                                <div>Groupe parent</div>
                                <DropdownToggle caret style={{ marginTop: '10px', width: '200px' }}>
                                    {parentCustomerGroup ? parentCustomerGroup.name : 'Choix du groupe'}
                                </DropdownToggle>
                                <DropdownMenu>
                                    {customerGroups.customerGroups.map((customerGroup) => {
                                        if(currentCustomerGroup.id !== customerGroup.id){
                                            return (
                                                <DropdownItem key={customerGroup.id}
                                                onClick={() => this.handleInputChange({ target: { name: 'parentGroupId', value: customerGroup.id } })}>
                                                    {customerGroup.name}
                                                </DropdownItem>
                                                )
                                        }
                                        return null;
                                    })}
                                </DropdownMenu>
                            </UncontrolledDropdown>}
                        </FormGroup> */}
                    </Form>
                    <div style={{textAlign: 'right'}}>Tous les champs doivent être renseignés*</div>
                </ModalBody>

                <ModalFooter>
                    {this.state.customerGroup.id && <Button type="button" color="danger" onClick={this.handleDelete}>Supprimer le groupe</Button>}
                    <Button color="secondary" onClick={this.dismiss}>Annuler</Button>{' '}
                    <Button color="primary" onClick={this.handleSubmit} disabled={isFormInvalid}>Valider</Button>
                </ModalFooter>

            </Modal>
        );
    }
}

const mapStateToProps = state => ({
    authentication: state.authentication,
    customerGroups: state.customerGroups
});

export default connect(mapStateToProps)(Radium(NewCustomerGroupModal));