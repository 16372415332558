import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Button } from 'reactstrap';
import Radium from 'radium';
import ReactTable from 'react-table';
import { getAllUsers } from '../../actions/users';
import EditUserModal from './EditUserModal';
import { pushModalAsync, dismissModalAsync } from '../../actions/modals';


class Users extends Component {

    componentDidMount() {
        this.props.dispatch(getAllUsers());
    }

    createUser = () => {
        this.props.dispatch(pushModalAsync(EditUserModal, { 
            user: { userName: '', name: '', surname: '', emailAddress: '', password: '', passwordConfirmation: '', roleNames: [], userGroups: [], isActive: true },
            onClose: async(modalId, modalResult) => {
                this.props.dispatch(dismissModalAsync(modalId));
            }
        }));
    };

    editUser = (user) => {
        this.props.dispatch(pushModalAsync(EditUserModal, { 
            user,
            onClose: async(modalId, modalResult) => {
                this.props.dispatch(dismissModalAsync(modalId));
            }
        }));
    };

    render() {
        const { users } = this.props;
        const styles = {
            title: {
                textAlign: 'center',
                padding: '10px'
            },
            addClient: {
                padding: '0 0 5px 0'
            }
        };

        return (
            <div style={{ padding: '20px' }}>
                <h1 style={styles.title}>Gestion des utilisateurs</h1>
                <div style={styles.addClient}>
                    <Button color="primary" onClick={this.createUser}>Ajouter un utilisateur</Button>
                </div>

                <div style={{ display: 'flex', flex: '1' }}>
                    {users && users.users && (
                        <ReactTable
                            data={users.users}
                            filterable
                            defaultPageSize={10}
                            style={{ border: 'none', flex: '1' }}
                            noDataText="Aucun utilisateur"
                            ofText="sur"
                            nextText="Suivant"
                            previousText="Précédent"
                            rowsText="lignes"
                            defaultFilterMethod={(filter, row) => {
                                if (!filter.value) {
                                    return true;
                                }
                                return row[filter.id] && row[filter.id].toLowerCase().indexOf(filter.value.toLowerCase()) !== -1;
                            }}
                            columns={[
                                {
                                    Header: 'Prénom',
                                    accessor: 'name',
                                    Cell: props => <div style={styles.cellStyle}>{props.value}</div>
                                },
                                {
                                    Header: 'Nom',
                                    accessor: 'surname',
                                    Cell: props => <div style={styles.cellStyle}>{props.value}</div>
                                },
                                {
                                    Header: `Nom d'utilisateur`,
                                    accessor: 'userName',
                                    Cell: props => <div style={styles.cellStyle}>{props.value}</div>
                                },
                                {
                                    Header: `Role(s)`,
                                    accessor: 'roleNames',
                                    Cell: (props) => {
                                        let roles = [];
                                        Object.keys(props.value).map(value => {
                                            return roles.push(props.value[value]);
                                        })
                                        return(<div style={styles.cellStyle}>{roles.join(', ')}</div>)
                                    },
                                    filterable: false
                                },
                                {
                                    Header: `Groupe(s)`,
                                    accessor: 'userGroups',
                                    Cell: (props) => {
                                        let groups = [];
                                        Object.keys(props.value).map(value => {
                                            return groups.push(props.value[value].customerGroup.name);
                                        })
                                        return(<div style={styles.cellStyle}>{groups.join(', ')}</div>)
                                    },
                                    filterable: false
                                    // filterMethod: (filter, row) => {
                                    //     let found = false;
                                    //     if(row[filter.id]){
                                    //         row[filter.id].forEach(element => {
                                    //             if(element.customerGroup.name.toLowerCase().indexOf(filter.value.toLowerCase()) !== -1){
                                    //                 found = true;
                                    //             }
                                    //         });
                                    //     }
                                    //     return found;
                                    // }
                                }
                            ]}
                            getTrProps={(state, rowInfo, column, instance) => ({
                                onClick: (e, handleOriginal) => {
                                    this.editUser(rowInfo.original);
                                    // IMPORTANT! React-Table uses onClick internally to trigger
                                    // events like expanding SubComponents and pivots.
                                    // By default a custom 'onClick' handler will override this functionality.
                                    // If you want to fire the original onClick handler, call the
                                    // 'handleOriginal' function.
                                    // if (handleOriginal) {
                                    //     handleOriginal();
                                    // }
                                }
                            })}
                        />
                    )}
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    authentication: state.authentication,
    users: state.users
});

export default connect(mapStateToProps)(Radium(Users));