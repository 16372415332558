import React from 'react';
import Radium from 'radium';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import DashboardBlock from '../../components/DashboardBlock'
import { getAfterSaleTickets, getInstallationRequestTickets, getMoveRequestTickets } from '../../actions/tickets';
import Enumerable from 'linq';
import { afterSaleTicketStatusType } from '../../helpers/afterSaleStatus';
import { installationRequestStatusType, installationRequestInstoreStatusType } from '../../helpers/installationRequestStatus';
import { moveRequestStatusType, moveRequestInstoreStatusType } from '../../helpers/moveRequestStatus';
import { faCalculator, faCheck, faCalendarAlt, faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { permissions } from '../../helpers/permissions';

class Dashboard extends React.Component {

    componentDidMount() {
        const { authentication } = this.props;
        if(authentication.user && authentication.user.permissionNames){
            if(authentication.user.permissionNames.indexOf(permissions.PageInstallationRequestTickets) !== -1){
                this.props.dispatch(getInstallationRequestTickets());
            }
            if(authentication.user.permissionNames.indexOf(permissions.PageAfterSaleTickets) !== -1){
                this.props.dispatch(getAfterSaleTickets());
            }
            if(authentication.user.permissionNames.indexOf(permissions.PageMoveRequestTickets) !== -1){
                this.props.dispatch(getMoveRequestTickets());
            }
        }
    }

    onClickBlock = (block) => {
        if(block.type){
            switch(block.type){
                case 'installation':
                    this.props.dispatch(push('installation/list'));
                    break;
                case 'installationTracking':
                    this.props.dispatch(push(`installation/tracking/${block.routeParameters}`));
                    break;
                case 'sav':
                    this.props.dispatch(push('sav/list'));
                    break;
                default:
                    break;
            }
        }
    }

    render(){

        const { authentication, savTickets, installationTickets, moveTickets } = this.props;
        let installationTicketBlocks = null;
        let savBlocks = null;
        let moveTicketBlocks = null;

        if(installationTickets){
            const installationTicketCount = installationTickets.totalCount;
            const installationTicketValidated = Enumerable.from(installationTickets.items).where(o => o.currentStatus === installationRequestStatusType.validated).count();
            const installationTicketScheduled = Enumerable.from(installationTickets.items).where(o => o.instoreStatus === installationRequestInstoreStatusType.scheduled).count();
            const installationTicketDone = Enumerable.from(installationTickets.items).where(o => o.instoreStatus === installationRequestInstoreStatusType.done).count();
            installationTicketBlocks = [
                {
                    type: 'installation',
                    title: "Installations totales",
                    subTitle: '',
                    description: installationTicketCount,
                    progressPercent: '100',
                    progressColor: '#B59759',
                    icon: faCalculator
                },
                {
                    type: 'installation',
                    title: "Installations validées",
                    subTitle: '',
                    description: installationTicketValidated,
                    progressPercent: '100',
                    progressColor: '#B59759',
                    icon: faCheck
                },
                {
                    type: 'installationTracking',
                    title: "Installations planifiées",
                    subTitle: '',
                    description: installationTicketScheduled,
                    progressPercent: '100',
                    progressColor: '#B59759',
                    icon: faCalendarAlt,
                    routeParameters: installationRequestInstoreStatusType.scheduled,
                },
                {
                    type: 'installationTracking',
                    title: "Installations effectuées",
                    subTitle: '',
                    description: installationTicketDone,
                    progressPercent: '100',
                    progressColor: '#B59759',
                    icon: faCheckCircle,
                    routeParameters: installationRequestInstoreStatusType.done,
                }
            ];
        }
        if(moveTickets){
            const moveTicketCount = moveTickets.totalCount;
            const moveTicketValidated = Enumerable.from(moveTickets.items).where(o => o.currentStatus === moveRequestStatusType.validated).count();
            const moveTicketScheduled = Enumerable.from(moveTickets.items).where(o => o.instoreStatus === moveRequestInstoreStatusType.scheduled).count();
            const moveTicketDone = Enumerable.from(moveTickets.items).where(o => o.instoreStatus === moveRequestInstoreStatusType.done).count();
            moveTicketBlocks = [
                {
                    type: 'move',
                    title: "Déplacements linéaires",
                    subTitle: '',
                    description: moveTicketCount,
                    progressPercent: '100',
                    progressColor: '#B59759',
                    icon: faCalculator
                },
                {
                    type: 'move',
                    title: "Déplacements linéaires validés",
                    subTitle: '',
                    description: moveTicketValidated,
                    progressPercent: '100',
                    progressColor: '#B59759',
                    icon: faCheck
                },
                {
                    type: 'move',
                    title: "Déplacements linéaires planifiés",
                    subTitle: '',
                    description: moveTicketScheduled,
                    progressPercent: '100',
                    progressColor: '#B59759',
                    icon: faCalendarAlt
                },
                {
                    type: 'move',
                    title: "Déplacements linéaires effectués",
                    subTitle: '',
                    description: moveTicketDone,
                    progressPercent: '100',
                    progressColor: '#B59759',
                    icon: faCheckCircle
                }
            ];
        }
        if(savTickets){
            const savTicketCount = savTickets.totalCount;
            const savTicketDoing = Enumerable.from(savTickets.items).where(o => o.currentStatus === afterSaleTicketStatusType.doing).count();
            const savTicketDone = Enumerable.from(savTickets.items).where(o => o.currentStatus === afterSaleTicketStatusType.done).count();
            savBlocks = [
            {
                type: 'sav',
                title: "Tickets",
                subTitle: '',
                description: savTicketCount,
                progressPercent: '100',
                progressColor: '#B59759',
                icon: faCalculator
            },
            {
                type: 'sav',
                title: "Tickets en cours",
                subTitle: '',
                description: savTicketDoing,
                progressPercent: '100',
                progressColor: '#B59759',
                icon: faCalendarAlt
            },
            {
                type: 'sav',
                title: "Tickets résolus",
                subTitle: '',
                description: savTicketDone,
                progressPercent: '100',
                icon: faCheckCircle,
                progressColor: '#B59759',
            }
        ];
        }

        const styles = {

            root: {
                padding: '20px',
                height: '100%',
                width: '100%',
                overflow: 'auto'
            },
            blockContainer: {
                display: 'flex',
                flexWrap: 'wrap',
                alignItems: 'left',
                justifyContent: 'left'
            },
            block: {
                width: '275px',
                height: '230px',
                margin: '12px'
            }

        };

        return (
            <div style={styles.root}>
                <div>
                    {installationTickets &&
                    <div>
                        <h2>Installations</h2>
                        <div style={styles.blockContainer}>
                        {installationTicketBlocks && installationTicketBlocks.map((block, key) => (
                            <DashboardBlock key={key} block={block} onClick={() => this.onClickBlock(block)}
                            encryptedAuthToken={authentication.encryptedToken} style={styles.block} />
                        ))}
                        </div>
                    </div>}

                    {moveTickets &&
                    <div>
                        <h2>Déplacements linéaires</h2>
                        <div style={styles.blockContainer}>
                        {moveTicketBlocks && moveTicketBlocks.map((block, key) => (
                            <DashboardBlock key={key} block={block} onClick={() => this.onClickBlock(block)}
                            encryptedAuthToken={authentication.encryptedToken} style={styles.block} />
                        ))}
                        </div>
                    </div>}

                    {savTickets &&
                    <div>
                        <h2>SAV</h2>
                        <div style={styles.blockContainer}>
                        {savBlocks && savBlocks.map((block, key) => (
                            <DashboardBlock key={key} block={block} onClick={() => this.onClickBlock(block)}
                            encryptedAuthToken={authentication.encryptedToken} style={styles.block} />
                        ))}
                        </div>
                    </div>}
                </div>
            </div>
      );
    }
}

const mapStateToProps = state => ({
    authentication: state.authentication,
    savTickets: state.tickets.savTickets,
    installationTickets: state.tickets.installationTickets,
    moveTickets: state.tickets.moveTickets
});

export default connect(mapStateToProps)(Radium(Dashboard));