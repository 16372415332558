// import logo from "../images/instore_logo.png";
import mhd_logo from "../images/mhd_logo.png";

export const loadSettings = (host) => {
  return async (dispatch, getState) => {
    dispatch(loadSettingsRequest());
    const result = {};

    try {
      // switch (host){

      //     case 'tickets.sys1v2.instore-solution.fr':
      //     case 'mhd-magplaner.fr':
      //     case 'www.mhd-magplaner.fr':
      //     case 'localhost:3000':

      //         result.data = {
      //             tenancyName: 'MHD',
      //             logo: mhd_logo,
      //             logoBackgroundColor: '#FFF',
      //             chromeHeaderBackgroundColor: '#000',
      //             accentColor1: '#231761'
      //         };
      //         break;

      //     default:

      //         result.data = {
      //             tenancyName: 'Default',
      //             logo: logo,
      //             logoBackgroundColor: '#333',
      //             chromeHeaderBackgroundColor: '#333',
      //             accentColor1: '#231761'
      //         };
      //         break;

      // }
      result.data = {
        tenancyName: "MHD",
        logo: mhd_logo,
        logoBackgroundColor: "#FFF",
        chromeHeaderBackgroundColor: "#000",
        accentColor1: "#231761",
      };
    } catch (ex) {
      result.error = ex;
    }

    dispatch(loadSettingsResponse(result));
  };
};

const loadSettingsRequest = () => {
  return {
    type: "LOAD_SETTINGS_REQUEST",
  };
};

const loadSettingsResponse = (input) => {
  return {
    type: "LOAD_SETTINGS_RESPONSE",
    data: input.data,
    error: input.error,
  };
};
