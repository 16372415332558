import axios from 'axios';
import * as ApiSettings from './apiSettings';
import { getDefaultHeaders, getAllDefaultParams } from './apiSettings';

const serviceTicketPath = '/api/services/app/Ticket';
const serviceInstallationRequestTicketPath = '/api/services/app/InstallationRequestTicket';
const serviceAfterSaleTicketPath = '/api/services/app/AfterSaleTicket';
const serviceMoveRequestTicketPath = '/api/services/app/MoveRequestTicket';

// Tickets
export const getTicketsApi = (authToken) => {
    return axios.get(`${ApiSettings.apiBaseUrl}${serviceTicketPath}/GetAll`, {
        params: { ...getAllDefaultParams() },
        headers: {
            ...getDefaultHeaders(),
            'Authorization': `Bearer ${authToken}`
        }
    });
};

export const getTicketsByCustomerIdApi = (authToken, customerId) => {
    return axios.get(`${ApiSettings.apiBaseUrl}${serviceTicketPath}/GetAllByCustomerId`, {
        params: {
            customerId
        },
        headers: {
            ...getDefaultHeaders(),
            'Authorization': `Bearer ${authToken}`
        }
    });
};

// Installation Request Tickets
export const getInstallationRequestTicketsApi = (authToken) => {
    return axios.get(`${ApiSettings.apiBaseUrl}${serviceInstallationRequestTicketPath}/GetAll`, {
        params: { ...getAllDefaultParams() },
        headers: {
            ...getDefaultHeaders(),
            'Authorization': `Bearer ${authToken}`
        }
    });
};

export const getInstallationRequestTicketsByCustomerIdApi = (authToken, customerId) => {
    return axios.get(`${ApiSettings.apiBaseUrl}${serviceInstallationRequestTicketPath}/GetAllByCustomerId`, {
        params: {
            customerId
        },
        headers: {
            ...getDefaultHeaders(),
            'Authorization': `Bearer ${authToken}`
        }
    });
};

export const createInstallationRequestTicketApi = (authToken, ticket) => {
    
    return axios.post(`${ApiSettings.apiBaseUrl}${serviceInstallationRequestTicketPath}/Create`, {
        ...ticket
    },
    {
        headers: {
            ...getDefaultHeaders(),
            'Authorization': `Bearer ${authToken}`
        },
    });

};

export const updateInstallationRequestTicketApi = (authToken, ticket) => {
    
    return axios.put(`${ApiSettings.apiBaseUrl}${serviceInstallationRequestTicketPath}/Update`, {
        ...ticket
    },
    {
        headers: {
            ...getDefaultHeaders(),
            'Authorization': `Bearer ${authToken}`
        },
    });

};

// After Sale Tickets
export const getAfterSaleTicketApi = (authToken, id) => {
    return axios.get(`${ApiSettings.apiBaseUrl}${serviceAfterSaleTicketPath}/Get`, {
        params: {
            Id: id
        },
        headers: {
            ...getDefaultHeaders(),
            'Authorization': `Bearer ${authToken}`
        }
    });
};

export const getAfterSaleTicketsApi = (authToken) => {
    return axios.get(`${ApiSettings.apiBaseUrl}${serviceAfterSaleTicketPath}/GetAll`, {
        params: { ...getAllDefaultParams() },
        headers: {
            ...getDefaultHeaders(),
            'Authorization': `Bearer ${authToken}`
        }
    });
};

export const getAfterSaleTicketsByCustomerIdApi = (authToken, customerId) => {
    return axios.get(`${ApiSettings.apiBaseUrl}${serviceAfterSaleTicketPath}/GetAllByCustomerId`, {
        params: {
            customerId
        },
        headers: {
            ...getDefaultHeaders(),
            'Authorization': `Bearer ${authToken}`
        }
    });
};

export const createAfterSaleTicketApi = (authToken, ticket) => {
    
    return axios.post(`${ApiSettings.apiBaseUrl}${serviceAfterSaleTicketPath}/Create`, {
        ...ticket
    },
    {
        headers: {
            ...getDefaultHeaders(),
            'Authorization': `Bearer ${authToken}`
        },
    });

};

export const updateAfterSaleTicketApi = (authToken, ticket) => {
    
    return axios.put(`${ApiSettings.apiBaseUrl}${serviceAfterSaleTicketPath}/Update`, {
        ...ticket
    },
    {
        headers: {
            ...getDefaultHeaders(),
            'Authorization': `Bearer ${authToken}`
        },
    });

};

export const deleteAfterSaleTicketApi = (authToken, ticket) => {
    
    return axios.delete(`${ApiSettings.apiBaseUrl}${serviceAfterSaleTicketPath}/Delete`, {
        ...ticket
    },
    {
        headers: {
            ...getDefaultHeaders(),
            'Authorization': `Bearer ${authToken}`
        },
    });

};


// Move Request Tickets
export const getMoveRequestTicketsApi = (authToken) => {
    return axios.get(`${ApiSettings.apiBaseUrl}${serviceMoveRequestTicketPath}/GetAll`, {
        params: { ...getAllDefaultParams() },
        headers: {
            ...getDefaultHeaders(),
            'Authorization': `Bearer ${authToken}`
        }
    });
};

export const getMoveRequestTicketsByCustomerIdApi = (authToken, customerId) => {
    return axios.get(`${ApiSettings.apiBaseUrl}${serviceMoveRequestTicketPath}/GetAllByCustomerId`, {
        params: {
            customerId
        },
        headers: {
            ...getDefaultHeaders(),
            'Authorization': `Bearer ${authToken}`
        }
    });
};

export const createMoveRequestTicketApi = (authToken, ticket) => {
    return axios.post(`${ApiSettings.apiBaseUrl}${serviceMoveRequestTicketPath}/Create`, {
        ...ticket
    },
    {
        headers: {
            ...getDefaultHeaders(),
            'Authorization': `Bearer ${authToken}`
        },
    });
};

export const updateMoveRequestTicketApi = (authToken, ticket) => {
    return axios.put(`${ApiSettings.apiBaseUrl}${serviceMoveRequestTicketPath}/Update`, {
        ...ticket
    },
    {
        headers: {
            ...getDefaultHeaders(),
            'Authorization': `Bearer ${authToken}`
        },
    });
};