import axios from 'axios';
import * as ApiSettings from './apiSettings';
import { getDefaultHeaders, getAllDefaultParams } from './apiSettings';

const servicePath = '/api/services/app/CustomerGroup';

export const getCustomerGroupApi = (authToken, id) => {
    return axios.get(`${ApiSettings.apiBaseUrl}${servicePath}/Get`, {
        params: {
            Id: id
        },
        headers: {
            ...getDefaultHeaders(),
            'Authorization': `Bearer ${authToken}`
        }
    });
};

export const getAllCustomerGroupsApi = (authToken) => {
    return axios.get(`${ApiSettings.apiBaseUrl}${servicePath}/GetAll`, {
        params: { ...getAllDefaultParams() },
        headers: {
            ...getDefaultHeaders(),
            'Authorization': `Bearer ${authToken}`
        }
    });
};

export const createCustomerGroupApi = (authToken, customerGroup) => {
    return axios.post(`${ApiSettings.apiBaseUrl}${servicePath}/Create`, {
        ...customerGroup
    }, {
        headers: {
            ...getDefaultHeaders(),
            'Authorization': `Bearer ${authToken}`
        }
    });
};

export const updateCustomerGroupApi = (authToken, customerGroup) => {
    return axios.put(`${ApiSettings.apiBaseUrl}${servicePath}/Update`, {
        ...customerGroup
    }, {
        headers: {
            ...getDefaultHeaders(),
            'Authorization': `Bearer ${authToken}`
        }
    });
};

export const deleteCustomerGroupApi = (authToken, customerGroupId) => {
    return axios.delete(`${ApiSettings.apiBaseUrl}${servicePath}/Delete`, {
        params: {
            Id: customerGroupId
        },
        headers: {
            ...getDefaultHeaders(),
            'Authorization': `Bearer ${authToken}`
        }
    });
};