import { getTicketsApi, getTicketsByCustomerIdApi, getInstallationRequestTicketsApi, getInstallationRequestTicketsByCustomerIdApi,
     getAfterSaleTicketsApi, getAfterSaleTicketsByCustomerIdApi, createInstallationRequestTicketApi, updateInstallationRequestTicketApi,
     createAfterSaleTicketApi, updateAfterSaleTicketApi, getAfterSaleTicketApi, getMoveRequestTicketsApi,
     getMoveRequestTicketsByCustomerIdApi, createMoveRequestTicketApi, updateMoveRequestTicketApi} from '../api/tickets';
import { getResponseExceptionMessage } from '../api/apiSettings';

const getTicketsRequest = (type) => {
    return {
        type
    };
};

const getTicketsResponse = (type, input) => {
    return {
        type,
        data: input.data,
        error: input.error
    };
};

const createTicketRequest = (type) => {
    return {
        type: type
    };
};

const createTicketResponse = (type, input) => {
    return {
        type: type,
        data: input.data,
        error: input.error
    };
};

const updateTicketRequest = (type) => {
    return {
        type: type
    };
};

const updateTicketResponse = (type, input) => {
    return {
        type: type,
        data: input.data,
        error: input.error
    };
};

// Tickets
export const getTickets = () => {

    return async (dispatch, getState) => {

        dispatch(getTicketsRequest('GET_TICKETS_REQUEST'));
        const result = {};

        try {
            const apiResult = await getTicketsApi(getState().authentication.token);
            result.data = apiResult.data.result;
        }
        catch (ex) {
            result.error = ex.message || 'Une erreur est survenue';
        }

        dispatch(getTicketsResponse('GET_TICKETS_RESPONSE', result));
    };

};

export const getTicketsByCustomerId = (customerId) => {

    return async (dispatch, getState) => {

        dispatch(getTicketsRequest('GET_TICKETS_BY_CUSTOMER_ID_REQUEST'));
        const result = {};

        try {
            const apiResult = await getTicketsByCustomerIdApi(getState().authentication.token, customerId);
            result.data = apiResult.data.result;
        }
        catch (ex) {
            result.error = ex.message || 'Une erreur est survenue';
        }

        dispatch(getTicketsResponse('GET_TICKETS_BY_CUSTOMER_ID_RESPONSE', result));
    };

};

// Installation Request Tickets
export const getInstallationRequestTickets = () => {

    return async (dispatch, getState) => {

        dispatch(getTicketsRequest('GET_INSTALLATION_REQUEST_TICKETS_REQUEST'));
        const result = {};

        try {
            const apiResult = await getInstallationRequestTicketsApi(getState().authentication.token);
            result.data = apiResult.data.result;
        }
        catch (ex) {
            result.error = ex.message || 'Une erreur est survenue';
        }

        dispatch(getTicketsResponse('GET_INSTALLATION_REQUEST_TICKETS_RESPONSE', result));
    };

};

export const getInstallationRequestTicketsByCustomerId = (customerId) => {

    return async (dispatch, getState) => {

        dispatch(getTicketsRequest('GET_INSTALLATION_REQUEST_TICKETS_BY_CUSTOMER_ID_REQUEST'));
        const result = {};

        try {
            const apiResult = await getInstallationRequestTicketsByCustomerIdApi(getState().authentication.token, customerId);
            result.data = apiResult.data.result;
        }
        catch (ex) {
            result.error = ex.message || 'Une erreur est survenue';
        }

        dispatch(getTicketsResponse('GET_INSTALLATION_REQUEST_TICKETS_BY_CUSTOMER_ID_RESPONSE', result));
    };

};

export const createInstallationRequestTicket = (ticket) => {

    return async (dispatch, getState) => {

        dispatch(createTicketRequest('CREATE_INSTALLATION_REQUEST_TICKET_REQUEST'));

        const result = {};

        try {
            const apiResult = await createInstallationRequestTicketApi(getState().authentication.token, ticket);
            
            if (apiResult.data.error){
                result.error = `Erreur, veuillez vérifier que tous les champs soient correctement renseignés`;
            }
            else {
                result.data = apiResult.data.result;
            }
        }
        catch (ex) {
            result.error = getResponseExceptionMessage(ex);
        }
        
        dispatch(createTicketResponse('CREATE_INSTALLATION_REQUEST_TICKET_RESPONSE', result));

        return result;
    };

};

export const updateInstallationRequestTicket = (ticket) => {

    return async (dispatch, getState) => {

        dispatch(updateTicketRequest('UPDATE_INSTALLATION_REQUEST_TICKET_REQUEST'));

        const result = {};

        try {
            const apiResult = await updateInstallationRequestTicketApi(getState().authentication.token, ticket);
            
            if (apiResult.data.error){
                result.error = `Erreur, veuillez vérifier que tous les champs soient correctement renseignés`;
            }
            else {
                result.data = apiResult.data.result;
            }
        }
        catch (ex) {
            result.error = getResponseExceptionMessage(ex);
        }
        
        dispatch(updateTicketResponse('UPDATE_INSTALLATION_REQUEST_TICKET_RESPONSE', result));

        return result;
    };

};

// After Sale Tickets
export const getAfterSaleTicket = (id) => {

    return async (dispatch, getState) => {
        dispatch(getTicketsRequest('GET_AFTER_SALE_TICKET_REQUEST'));
        const result = {};
        
        try {
            const apiResult = await getAfterSaleTicketApi(getState().authentication.token, id);
            result.data = apiResult.data.result;
        }
        catch (ex) {
            result.error = ex.message || 'Une erreur est survenue';
        }

        dispatch(getTicketsResponse('GET_AFTER_SALE_TICKET_RESPONSE', result));
        return result;
    };

};

export const getAfterSaleTickets = () => {

    return async (dispatch, getState) => {
        dispatch(getTicketsRequest('GET_AFTER_SALE_TICKETS_REQUEST'));
        const result = {};

        try {
            const apiResult = await getAfterSaleTicketsApi(getState().authentication.token);
            result.data = apiResult.data.result;
        }
        catch (ex) {
            result.error = ex.message || 'Une erreur est survenue';
        }

        dispatch(getTicketsResponse('GET_AFTER_SALE_TICKETS_RESPONSE', result));
        return result;
    };

};

export const getAfterSaleTicketsByCustomerId = (customerId) => {

    return async (dispatch, getState) => {
        dispatch(getTicketsRequest('GET_AFTER_SALE_TICKETS_BY_CUSTOMER_ID_REQUEST'));
        const result = {};

        try {
            const apiResult = await getAfterSaleTicketsByCustomerIdApi(getState().authentication.token, customerId);
            result.data = apiResult.data.result;
        }
        catch (ex) {
            result.error = ex.message || 'Une erreur est survenue';
        }
        
        dispatch(getTicketsResponse('GET_AFTER_SALE_TICKETS_BY_CUSTOMER_ID_RESPONSE', result));
        return result;
    };

};

export const createAfterSaleTicket = (ticket) => {

    return async (dispatch, getState) => {

        dispatch(createTicketRequest('CREATE_AFTER_SALE_TICKET_REQUEST'));

        const result = {};

        try {
            const apiResult = await createAfterSaleTicketApi(getState().authentication.token, ticket);
            
            if (apiResult.data.error){
                result.error = `Erreur, veuillez vérifier que tous les champs soient correctement renseignés`;
            }
            else {
                result.data = apiResult.data.result;
            }
        }
        catch (ex) {
            result.error = getResponseExceptionMessage(ex);
        }
        
        dispatch(createTicketResponse('CREATE_AFTER_SALE_TICKET_RESPONSE', result));

        return result;
    };

};

export const updateAfterSaleTicket = (ticket) => {

    return async (dispatch, getState) => {

        dispatch(updateTicketRequest('UPDATE_AFTER_SALE_TICKET_REQUEST'));

        const result = {};

        try {
            const apiResult = await updateAfterSaleTicketApi(getState().authentication.token, ticket);

            if (apiResult.data.error){
                result.error = `Erreur, veuillez vérifier que tous les champs soient correctement renseignés`;
            }
            else {
                result.data = apiResult.data.result;
            }
        }
        catch (ex) {
            result.error = getResponseExceptionMessage(ex);
        }
        
        dispatch(updateTicketResponse('UPDATE_AFTER_SALE_TICKET_RESPONSE', result));

        return result;
    };

};

// Move Request Tickets
export const getMoveRequestTickets = () => {

    return async (dispatch, getState) => {

        dispatch(getTicketsRequest('GET_MOVE_REQUEST_TICKETS_REQUEST'));
        const result = {};

        try {
            const apiResult = await getMoveRequestTicketsApi(getState().authentication.token);
            result.data = apiResult.data.result;
        }
        catch (ex) {
            result.error = ex.message || 'Une erreur est survenue';
        }

        dispatch(getTicketsResponse('GET_MOVE_REQUEST_TICKETS_RESPONSE', result));
    };

};

export const getMoveRequestTicketsByCustomerId = (customerId) => {

    return async (dispatch, getState) => {

        dispatch(getTicketsRequest('GET_MOVE_REQUEST_TICKETS_BY_CUSTOMER_ID_REQUEST'));
        const result = {};

        try {
            const apiResult = await getMoveRequestTicketsByCustomerIdApi(getState().authentication.token, customerId);
            result.data = apiResult.data.result;
        }
        catch (ex) {
            result.error = ex.message || 'Une erreur est survenue';
        }

        dispatch(getTicketsResponse('GET_MOVE_REQUEST_TICKETS_BY_CUSTOMER_ID_RESPONSE', result));
    };

};

export const createMoveRequestTicket = (ticket) => {

    return async (dispatch, getState) => {

        dispatch(createTicketRequest('CREATE_MOVE_REQUEST_TICKET_REQUEST'));

        const result = {};

        try {
            const apiResult = await createMoveRequestTicketApi(getState().authentication.token, ticket);
            
            if (apiResult.data.error){
                result.error = `Erreur, veuillez vérifier que tous les champs soient correctement renseignés`;
            }
            else {
                result.data = apiResult.data.result;
            }
        }
        catch (ex) {
            result.error = getResponseExceptionMessage(ex);
        }
        
        dispatch(createTicketResponse('CREATE_MOVE_REQUEST_TICKET_RESPONSE', result));

        return result;
    };

};

export const updateMoveRequestTicket = (ticket) => {

    return async (dispatch, getState) => {

        dispatch(updateTicketRequest('UPDATE_MOVE_REQUEST_TICKET_REQUEST'));

        const result = {};

        try {
            const apiResult = await updateMoveRequestTicketApi(getState().authentication.token, ticket);
            
            if (apiResult.data.error){
                result.error = `Erreur, veuillez vérifier que tous les champs soient correctement renseignés`;
            }
            else {
                result.data = apiResult.data.result;
            }
        }
        catch (ex) {
            result.error = getResponseExceptionMessage(ex);
        }
        
        dispatch(updateTicketResponse('UPDATE_MOVE_REQUEST_TICKET_RESPONSE', result));

        return result;
    };

};