import axios from 'axios';
import * as ApiSettings from './apiSettings';
import { getDefaultHeaders, getAllDefaultParams } from './apiSettings';

const servicePath = '/api/services/app/HardwareType';

export const getHardwareTypesApi = (authToken) => {
    return axios.get(`${ApiSettings.apiBaseUrl}${servicePath}/GetAll`, {
        params: { ...getAllDefaultParams() },
        headers: {
            ...getDefaultHeaders(),
            'Authorization': `Bearer ${authToken}`
        }
    });
};

export const createHardwareTypeApi = (authToken, hardwareType) => {
    return axios.post(`${ApiSettings.apiBaseUrl}${servicePath}/Create`, {
        ...hardwareType
    }, {
        headers: {
            ...getDefaultHeaders(),
            'Authorization': `Bearer ${authToken}`
        }
    });
};


export const updateHardwareTypeApi = (authToken, hardwareType) => {
    return axios.put(`${ApiSettings.apiBaseUrl}${servicePath}/Update`, {
        ...hardwareType
    }, {
        headers: {
            ...getDefaultHeaders(),
            'Authorization': `Bearer ${authToken}`
        }
    });
};

export const deleteHardwareTypeApi = (authToken, hardwareTypeId) => {
    return axios.delete(`${ApiSettings.apiBaseUrl}${servicePath}/Delete`, {
        headers: {
            ...getDefaultHeaders(),
            'Authorization': `Bearer ${authToken}`
        },
        params: {
            Id: hardwareTypeId
        }
    });
};