import { resetPassword as resetPasswordApi, getProfile as getProfileApi, saveProfile as saveProfileApi } from '../api/account';
import { getResponseExceptionMessage } from '../api/apiSettings';

export const resetPassword = (userNameOrEmailAddress) => {

    return async (dispatch, getState) => {

        dispatch(resetPasswordRequest());

        const result = {};

        try {
            const apiResult = await resetPasswordApi(userNameOrEmailAddress, getState().settings.data.tenancyName, getState().authentication.token);
            if (apiResult && apiResult.data && apiResult.data.success && apiResult.data.result && !apiResult.data.result.errorMessage){
                result.data = true;
            }
            else {
                result.error = 'Une erreur est survenue';
                if(apiResult && apiResult.data && apiResult.data.result && apiResult.data.result.errorMessage){
                    result.error = apiResult.data.result.errorMessage;
                }
            }
        }
        catch (ex) {
            result.error = getResponseExceptionMessage(ex);
        }
        
        dispatch(resetPasswordResponse(result));

        return result;
    };

};

const resetPasswordRequest = () => {
    return {
        type: 'ACCOUNT_RESET_PASSWORD_REQUEST'
    };
};

const resetPasswordResponse = (input) => {
    return {
        type: 'ACCOUNT_RESET_PASSWORD_RESPONSE',
        data: input.data,
        error: input.error
    };
};

export const getProfile = () => {

    return async (dispatch, getState) => {

        dispatch(getProfileRequest());

        const result = {};

        try {
            const apiResult = await getProfileApi(getState().authentication.token);

            if (apiResult && apiResult.data && apiResult.data.success && apiResult.data.result){

                result.data = apiResult.data.result.user;
            }
            else {
                result.error = `Une erreur est survenue`;
            }
        }
        catch (ex) {
            result.error = getResponseExceptionMessage(ex);
        }
        
        dispatch(getProfileResponse(result));

        return result;
    };

};

const getProfileRequest = () => {
    return {
        type: 'ACCOUNT_GET_PROFILE_REQUEST'
    };
};

const getProfileResponse = (input) => {
    return {
        type: 'ACCOUNT_GET_PROFILE_RESPONSE',
        data: input.data,
        error: input.error
    };
};

export const saveProfile = (profile) => {

    return async (dispatch, getState) => {

        dispatch(saveProfileRequest());

        const result = {};

        try {
            const apiResult = await saveProfileApi(profile, getState().authentication.token);

            if (apiResult && apiResult.data && apiResult.data.success && apiResult.data.result){

                result.data = apiResult.data.result.user;
            }
            else {
                result.error = `Une erreur est survenue`;
            }
        }
        catch (ex) {
            result.error = getResponseExceptionMessage(ex);
        }
        
        dispatch(saveProfileResponse(result));

        return result;
    };

};

const saveProfileRequest = () => {
    return {
        type: 'ACCOUNT_SAVE_PROFILE_REQUEST'
    };
};

const saveProfileResponse = (input) => {
    return {
        type: 'ACCOUNT_SAVE_PROFILE_RESPONSE',
        data: input.data,
        error: input.error
    };
};