import axios from 'axios';
import * as ApiSettings from './apiSettings';
import { getAllDefaultParams } from './apiSettings';

const servicePath = '/api/services/app/User';

export const getUserApi = (authToken, id) => {
    return axios.get(`${ApiSettings.apiBaseUrl}${servicePath}/Get`, {
        params: {
            Id: id
        },
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${authToken}`
        }
    });
};

export const getAllUsersApi = (authToken) => {
    return axios.get(`${ApiSettings.apiBaseUrl}${servicePath}/GetAll`, {
        params: { ...getAllDefaultParams() },
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${authToken}`
        }
    });
};

export const createUserApi = (user, authToken) => {
    return axios.post(`${ApiSettings.apiBaseUrl}${servicePath}/Create`, {
        ...user
    }, {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${authToken}`
        }
    });
};

export const updateUserApi =    (user, authToken) => {
    return axios.put(`${ApiSettings.apiBaseUrl}${servicePath}/Update`, {
        ...user
    }, {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${authToken}`
        }
    });
};

export const deleteUserApi = (userId, authToken) => {
    return axios.delete(`${ApiSettings.apiBaseUrl}${servicePath}/Delete`, {
        params: {
            Id: userId
        },
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${authToken}`
        }
    });
};

export const getRolesApi = (authToken) => {
    return axios.get(`${ApiSettings.apiBaseUrl}${servicePath}/GetRoles`, {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${authToken}`
        }
    });
};