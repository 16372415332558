import { apiBaseUrl } from "../../api/apiSettings";

export const getFileUrl = (fileId, encryptedToken, fileName) => {

    let url = `${apiBaseUrl}/api/file/downloadFile?${encodeURIComponent('fileId')}=${encodeURIComponent(fileId)}`;
    // let appendSymbol = '?';

    // if (url.indexOf('?') !== -1){
    //     appendSymbol = '&';
    // }

    url = `${url}&${encodeURIComponent('enc_auth_token')}=${encodeURIComponent(encryptedToken)}`;

    if (fileName){

        url = `${url}&${encodeURIComponent('fileName')}=${encodeURIComponent(fileName)}`;

    }

    return url;

};