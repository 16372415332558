const initialState = {
    tickets: null,
    savTickets: null,
    installationTickets: null,
    moveTickets: null
};

export const tickets = (state = initialState, action) => {
    switch (action.type){

        case 'GET_TICKETS_RESPONSE':
            return {
                ...state,
                tickets: action.data,
            };

        case 'GET_INSTALLATION_REQUEST_TICKETS_RESPONSE':
            return {
                ...state,
                installationTickets: action.data,
            };

        case 'GET_AFTER_SALE_TICKETS_RESPONSE':
            return {
                ...state,
                savTickets: action.data,
            };

        case 'GET_MOVE_REQUEST_TICKETS_RESPONSE':
            return {
                ...state,
                moveTickets: action.data,
            };

        default:
            break;
    }

    return state;
};