import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Button,
  Form,
  Input,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import Radium from "radium";
import moment from "moment";
import { getHardwares } from "../../actions/hardwares";
import Enumerable from "linq";
import Calendar from "react-calendar";
import { getCustomers } from "../../actions/customers";
import {
  createAfterSaleTicket,
  updateAfterSaleTicket,
  getAfterSaleTicket,
} from "../../actions/tickets";
import { push } from "connected-react-router";
import {
  afterSaleTicketStatusType,
  afterSaleTicketStatusTypeName,
} from "../../helpers/afterSaleStatus";
import Dropzone from "react-dropzone";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPlusCircle,
  faEdit,
  faPaperclip,
  faTimesCircle,
} from "@fortawesome/free-solid-svg-icons";
import NewCustomerModal from "../../components/NewCustomerModal";
import { pushModalAsync, dismissModalAsync } from "../../actions/modals";
import { color } from "../../helpers/color";
import { createNotification } from "../../actions/notifications";
import { arrayToObject } from "../../helpers/arrayToObject";
import { upload } from "../../api/fileUpload";
import { getFileUrl } from "../../helpers/files";
import { permissions, hasPermission } from "../../helpers/permissions";
import DisplayContentModal from "../../components/DisplayContentModal";
import Select from "react-select";
import { roles } from "../../helpers/roles";

const emptyTicket = {
  id: "new",
  requestedDateYear: null,
  requestedDateDayOfYear: null,
  requestedTimeRangeStart: null,
  requestedTimeRangeEnd: null,
  description: null,
  hardwareId: null,
  customerId: null,
  afterSaleFiles: Object.create(null),
  hardwares: [],
};

class AfterSaleDetail extends Component {
  state = {
    ticket: {
      ...emptyTicket,
    },
    isBusy: false,
    tempAfterSaleFiles: [],
  };

  dropzoneRef = React.createRef();

  componentDidMount() {
    this.props.dispatch(getHardwares());
    this.props.dispatch(getCustomers());
  }

  componentDidUpdate = async (prevProps, prevState) => {
    if (
      this.props.match &&
      this.props.match.params.id !== this.state.ticket.id
    ) {
      if (this.props.match.params.id !== "new") {
        let getSavTicket = await this.props.dispatch(
          getAfterSaleTicket(this.props.match.params.id)
        );
        if (
          !getSavTicket.error &&
          getSavTicket.data &&
          this.state.ticket.id === "new"
        ) {
          this.setState({ ticket: { ...getSavTicket.data } });
          return;
        }
      } else {
        this.setState({ ticket: { ...emptyTicket } });
      }
    }
  };

  handleInputChange = (e) => {
    const propName = e.target.name;
    let propValue = e.target.value;

    if (propName === "requestedDate") {
      this.setState((prevState, prevProps) => ({
        ticket: {
          ...prevState.ticket,
          requestedDateYear: propValue ? moment(propValue).year() : null,
          requestedDateDayOfYear: propValue
            ? moment(propValue).dayOfYear()
            : null,
        },
      }));
    } else if (propName === "expectedDate") {
      this.setState((prevState, prevProps) => ({
        ticket: {
          ...prevState.ticket,
          expectedDateYear: propValue ? moment(propValue).year() : null,
          expectedDateDayOfYear: propValue
            ? moment(propValue).dayOfYear()
            : null,
        },
      }));
    } else {
      this.setState((prevState, prevProps) => ({
        ticket: {
          ...prevState.ticket,
          [propName]: propValue,
        },
      }));
    }
  };

  handleSubmit = async (e) => {
    e.preventDefault();

    if (this.isFormInvalid() || this.state.isBusy) {
      return;
    }

    this.setState({ isBusy: true }, async () => {
      try {
        await this.handleSubmitFiles();

        const ticket = {
          ...this.state.ticket,
          afterSaleFiles: this.state.tempAfterSaleFiles,
        };

        if (ticket.id === "new") {
          const createResult = await this.props.dispatch(
            createAfterSaleTicket(ticket)
          );
          if (!createResult.error) {
            this.setState({ isBusy: false }, () => {
              this.props.dispatch(push(`/sav/${createResult.data.id}`));
            });
          } else {
            this.setState({ isBusy: false });
          }
        } else {
          const updateResult = await this.props.dispatch(
            updateAfterSaleTicket(ticket)
          );
          if (!updateResult.error) {
            this.setState({ isBusy: false, ticket: { ...updateResult.data } });
          } else {
            this.setState({ isBusy: false });
          }
        }
      } catch (ex) {
        console.error(ex);
        this.setState({ isBusy: false });
      }
    });
  };

  getCurrentTicketCustomer = () => {
    const { customers } = this.props;
    const { ticket } = this.state;
    let customer =
      ticket.customerId && customers.items
        ? Enumerable.from(customers.items).firstOrDefault(
            (o) => o.id === ticket.customerId
          )
        : null;
    if (!customer && ticket.customer) {
      customer = ticket.customer;
    }
    return customer;
  };

  handleAddCustomerClick = async (e) => {
    this.props.dispatch(
      pushModalAsync(NewCustomerModal, {
        onClose: async (modalId, modalResult) => {
          this.props.dispatch(dismissModalAsync(modalId));
          if (modalResult && modalResult.data && modalResult.data.id) {
            this.setState((prevState, prevProps) => ({
              ticket: {
                ...prevState.ticket,
                customerId: modalResult.data.id,
              },
            }));
          }
        },
      })
    );
  };

  handleEditCustomerClick = async (e) => {
    let customer = this.getCurrentTicketCustomer();
    if (customer) {
      this.props.dispatch(
        pushModalAsync(NewCustomerModal, {
          customer: customer,
          onClose: async (modalId, modalResult) => {
            if (modalResult.isDeleted) {
              this.setState((prevState, prevProps) => ({
                ticket: {
                  ...prevState.ticket,
                  customerId: null,
                  customer: null,
                },
              }));
            } else {
              if (modalResult.data && modalResult.data.id) {
                this.setState((prevState, prevProps) => ({
                  ticket: {
                    ...prevState.ticket,
                    customerId: modalResult.data.id,
                  },
                }));
              }
            }
            this.props.dispatch(dismissModalAsync(modalId));
          },
        })
      );
    }
  };

  onFileDrop = async (files, rejected) => {
    if (rejected.length > 0) {
      this.props.dispatch(
        createNotification(
          `Le fichier choisi n'est pas du type attendu`,
          "danger"
        )
      );
    } else if (files.length > 0) {
      let currentMaxWeight = this.state.ticket.afterSaleFiles
        ? Object.keys(this.state.ticket.afterSaleFiles).length
        : 0;
      Object.keys(this.state.ticket.afterSaleFiles).map((key) => {
        if (this.state.ticket.afterSaleFiles[key].weight > currentMaxWeight) {
          currentMaxWeight = this.state.ticket.afterSaleFiles[key].weight;
        }
        return currentMaxWeight;
      });
      this.setState((prevState, prevProps) => ({
        ticket: {
          ...prevState.ticket,
          afterSaleFiles: {
            ...prevState.ticket.afterSaleFiles,
            ...arrayToObject(
              files.map((file, key) => ({
                id: (new Date().getTime() * Math.random()).toString(),
                file,
                originalFileName: file.name,
                weight: currentMaxWeight + key + 1,
              })),
              "id"
            ),
          },
        },
      }));
    }
  };

  deleteAfterSaleFile = (afterSaleFileId) => {
    if (this.state.ticket.afterSaleFiles[afterSaleFileId]) {
      const afterSaleFiles = { ...this.state.ticket.afterSaleFiles };
      delete afterSaleFiles[afterSaleFileId];
      this.setState((prevState, prevProps) => ({
        ticket: {
          ...prevState.ticket,
          afterSaleFiles: afterSaleFiles,
        },
      }));
    }
  };

  handleSubmitFiles = async () => {
    try {
      const newTempAfterSaleFiles = [];
      for (const afterSaleFileId in this.state.ticket.afterSaleFiles) {
        const afterSaleFile = this.state.ticket.afterSaleFiles[afterSaleFileId];

        // If file is not yet attached to ticket, upload it
        if (
          !this.state.ticket.afterSaleFiles[afterSaleFileId].afterSaleTicketId
        ) {
          const formData = new FormData();
          formData.append(afterSaleFile.file.name, afterSaleFile.file);
          const uploadResult = await upload(
            formData,
            (progressEvent) => {
              if (progressEvent.type === "progress") {
                this.setState((prevState, prevProps) => ({
                  ticket: {
                    ...prevState.ticket,
                    afterSaleFiles: {
                      ...prevState.ticket.afterSaleFiles,
                      [afterSaleFile.id]: {
                        ...prevState.ticket.afterSaleFiles[afterSaleFile.id],
                        uploadProgress:
                          progressEvent.loaded / progressEvent.total,
                      },
                    },
                  },
                }));
              }
            },
            this.props.authentication.token
          );

          if (uploadResult && uploadResult.data.result.path) {
            const newAfterSaleFile = {
              tempUploadPath: uploadResult.data.result.path,
              tempUploadFileName: afterSaleFile.file.name,
              weight: afterSaleFile.weight,
            };
            newTempAfterSaleFiles.push(newAfterSaleFile);
          }
        } else {
          newTempAfterSaleFiles.push(afterSaleFile);
        }
      }
      this.setState({ tempAfterSaleFiles: newTempAfterSaleFiles });
    } catch (ex) {
      console.error(ex);
      this.setState({ isBusy: false });
    }
  };

  showFile = async (file) => {
    const { authentication } = this.props;
    if (file && file.originalFileName) {
      if (file.originalFileName.endsWith(".pdf")) {
        const url = getFileUrl(
          file.fileId,
          authentication.encryptedToken,
          file.originalFileName
        );
        window.location = url;
      } else {
        const html = (
          <img
            alt="img"
            src={getFileUrl(
              file.fileId,
              authentication.encryptedToken,
              file.originalFileName
            )}
            style={{ maxWidth: "100%", maxHeight: "100%" }}
          />
        );
        this.props.dispatch(
          pushModalAsync(DisplayContentModal, {
            title: file.originalFileName,
            content: html,
            contentAlign: "center",
          })
        );
      }
    }
  };

  addHardware = (hardware) => {
    if (this.state.ticket.hardwares) {
      if (
        !Enumerable.from(this.state.ticket.hardwares).any(
          (o) => o.id === hardware.id
        )
      ) {
        this.setState((prevState, prevProps) => ({
          ticket: {
            ...prevState.ticket,
            hardwares: [
              ...prevState.ticket.hardwares,
              { _id: new Date().getTime().toString(), ...hardware },
            ],
          },
        }));
      }
    }
  };

  deleteHardware = (hardware) => {
    const hardwareIndex = this.state.ticket.hardwares.indexOf(hardware);
    if (hardwareIndex !== -1) {
      const hardwares = [...this.state.ticket.hardwares];
      hardwares.splice(hardwareIndex, 1);
      this.setState((prevState, prevProps) => ({
        ticket: {
          ...prevState.ticket,
          hardwares: hardwares,
        },
      }));
    }
  };

  isFormInvalid = () =>
    !Object.keys(this.state.ticket.afterSaleFiles).length ||
    !this.state.ticket.customerId ||
    !Object.keys(this.state.ticket.hardwares).length ||
    !this.state.ticket.description ||
    !this.state.ticket.requestedDateDayOfYear;

  render() {
    const { ticket } = this.state;
    const { hardwares, customers, authentication } = this.props;
    const permissionNames =
      authentication.user && authentication.user.permissionNames
        ? authentication.user.permissionNames
        : [];
    const currentDateRequested =
      ticket.requestedDateYear && ticket.requestedDateDayOfYear
        ? moment()
            .year(ticket.requestedDateYear)
            .dayOfYear(ticket.requestedDateDayOfYear)
            .toDate()
        : null;
    const currentDateExpected =
      ticket.expectedDateYear && ticket.expectedDateDayOfYear
        ? moment()
            .year(ticket.expectedDateYear)
            .dayOfYear(ticket.expectedDateDayOfYear)
            .toDate()
        : null;
    const minDateRequested =
      authentication.user &&
      Enumerable.from(authentication.user.roleNames).any(
        (o) =>
          o === roles.Admin ||
          o === roles.AdminInstore ||
          o === roles.AdminPLV ||
          o === roles.AdminRS
      )
        ? null
        : moment()
            .add(14, "days")
            .toDate();
    let customer = this.getCurrentTicketCustomer();

    let isCustomerIncomplete = false;
    if (customer != null) {
      isCustomerIncomplete =
        !customer.contactPhone ||
        !customer.name ||
        !customer.code ||
        !customer.address ||
        !customer.postalCode ||
        !customer.city ||
        !customer.country ||
        !customer.phone ||
        !customer.email ||
        !customer.contactPhone ||
        !customer.faxPC ||
        !customer.function ||
        !customer.customerGroupId;
    }

    const customerSelectOptions =
      customers && customers.items
        ? Enumerable.from(customers.items)
            .orderBy((o) => o.name)
            .select((o) => ({ value: o.id, label: o.name }))
            .toArray()
        : [];
    const selectedCustomer = customer
      ? Enumerable.from(customerSelectOptions).firstOrDefault(
          (o) => o.value === customer.id
        )
      : null;

    const styles = {
      root: {
        height: "100%",
        width: "100%",
        overflow: "hidden",
        overflowY: "auto",
        padding: "20px",
      },
      title: {
        textTransform: "uppercase",
        fontSize: "20px",
        color: "white",
        flex: "1",
      },
    };

    if (authentication && authentication.user) {
      return (
        <div style={{ display: "flex", height: "100%", width: "100%" }}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
              height: "100%",
            }}
          >
            <div style={{ padding: "20px", background: "#B59759" }}>
              <div style={{ display: "flex" }}>
                <div style={styles.title}>
                  {ticket.id !== "new" ? (
                    <span>
                      Demande d'intervention{" "}
                      <strong>{ticket.friendlyNum}</strong>
                    </span>
                  ) : (
                    `Nouvelle demande d'intervention SAV`
                  )}
                </div>

                <div>
                  <Button
                    color="success"
                    type="button"
                    onClick={this.handleSubmit}
                    style={{
                      display: "block",
                      fontSize: "16px",
                      textTransform: "uppercase",
                    }}
                    disabled={
                      this.isFormInvalid() ||
                      this.state.isBusy ||
                      isCustomerIncomplete
                    }
                  >
                    {ticket.id !== "new" ? "Enregistrer" : "Créer la demande"}
                  </Button>

                  {this.state.isBusy && (
                    <div style={{ fontSize: "13px" }}>
                      Merci de patienter...
                    </div>
                  )}
                </div>
              </div>

              {ticket.id !== "new" && (
                <div>
                  <div style={{ fontSize: "13px", color: "white" }}>
                    <div>
                      Créé par {ticket.creatorName} le{" "}
                      {moment(ticket.creationTime).format("DD/MM/YYYY HH:mm")}
                    </div>
                  </div>

                  <UncontrolledDropdown>
                    <DropdownToggle
                      caret
                      style={{ marginTop: "10px", width: "200px" }}
                    >
                      {afterSaleTicketStatusTypeName[ticket.currentStatus]}
                    </DropdownToggle>
                    <DropdownMenu>
                      {Object.keys(afterSaleTicketStatusType).map((key) => (
                        <DropdownItem
                          disabled={
                            !hasPermission(
                              permissionNames,
                              permissions.UpdateTicketStatus
                            ) &&
                            (ticket &&
                              ticket.ticket &&
                              ticket.ticket.creatorUserId !==
                                authentication.user.id)
                          }
                          key={afterSaleTicketStatusType[key]}
                          onClick={() =>
                            this.handleInputChange({
                              target: {
                                name: "currentStatus",
                                value: afterSaleTicketStatusType[key],
                              },
                            })
                          }
                        >
                          {
                            afterSaleTicketStatusTypeName[
                              afterSaleTicketStatusType[key]
                            ]
                          }
                        </DropdownItem>
                      ))}
                    </DropdownMenu>
                  </UncontrolledDropdown>
                </div>
              )}
            </div>

            <Form onSubmit={this.handleSubmit} style={styles.root}>
              <fieldset disabled={this.state.isBusy}>
                <div
                  style={{
                    display: "flex",
                    marginBottom: "5px",
                    flexWrap: "wrap",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      flex: "1",
                      border: "1px solid #CCC",
                      background: "white",
                      padding: "15px",
                      minHeight: "350px",
                      margin: "5px",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexWrap: "wrap",
                        borderBottom: "1px solid #DDD",
                        paddingBottom: "10px",
                        marginBottom: "15px",
                      }}
                    >
                      <div
                        style={{
                          flex: "1",
                          fontSize: "20px",
                          color: color.gold,
                        }}
                      >
                        MAGASIN
                      </div>
                      {authentication.user && (
                        <div>
                          {authentication.user.permissionNames &&
                            authentication.user.permissionNames.indexOf(
                              permissions.ManageCustomers
                            ) !== -1 && (
                              <FontAwesomeIcon
                                icon={faPlusCircle}
                                color="#28a745"
                                style={{
                                  margin: "auto",
                                  paddingRight: "5px",
                                  cursor: "pointer",
                                }}
                                size="2x"
                                onClick={this.handleAddCustomerClick}
                              />
                            )}
                          {customer && (
                            <FontAwesomeIcon
                              icon={faEdit}
                              color="#007bff"
                              style={{
                                margin: "auto",
                                paddingRight: "5px",
                                cursor: "pointer",
                              }}
                              size="2x"
                              onClick={this.handleEditCustomerClick}
                            />
                          )}
                        </div>
                      )}

                      {customerSelectOptions && (
                        <Select
                          value={selectedCustomer}
                          options={customerSelectOptions}
                          styles={{
                            container: (provided) => ({
                              ...provided,
                              width: "100%",
                              maxWidth: "300px",
                            }),
                          }}
                          onChange={(option) =>
                            this.handleInputChange({
                              target: {
                                name: "customerId",
                                value: option.value,
                              },
                            })
                          }
                        />
                      )}
                    </div>

                    {customer && (
                      <div>
                        {isCustomerIncomplete && (
                          <div style={{ color: "red", fontWeight: "bold" }}>
                            Veuillez compléter les champs manquants en cliquant
                            sur le crayon bleu ci-dessus.
                          </div>
                        )}
                        <div
                          style={{
                            flex: "1",
                            overflowY: "auto",
                            fontSize: "14px",
                            color: "#222",
                          }}
                        >
                          <div>
                            <strong>Nom:</strong> {customer.name}
                          </div>
                          <div>
                            <strong>Code:</strong> {customer.code}
                          </div>
                          <div>
                            <strong>Adresse:</strong> {customer.address}{" "}
                            {customer.postalCode} {customer.city}{" "}
                            {customer.country}
                          </div>
                          <div>
                            <strong>Tél du magasin:</strong> {customer.phone}
                          </div>
                          <div>
                            <strong>Tél du contact:</strong>{" "}
                            {customer.contactPhone}
                          </div>
                          <div>
                            <strong>Nom du contact:</strong>{" "}
                            {customer.managerName}
                          </div>
                          <div>
                            <strong>Fax PC:</strong> {customer.faxPC}
                          </div>
                          <div>
                            <strong>Email:</strong> {customer.email}
                          </div>
                          <div>
                            <strong>Fonction:</strong> {customer.function}
                          </div>
                          <div>
                            <strong>Groupe:</strong>{" "}
                            {customer.customerGroup
                              ? customer.customerGroup.name
                              : ""}
                          </div>
                          <div>
                            <strong>Terroir d'Ecosse:</strong>{" "}
                            {customer.isTerroirEcosse ? "Oui" : "Non"}
                          </div>
                        </div>
                      </div>
                    )}
                  </div>

                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      flex: "1",
                      border: "1px solid #CCC",
                      background: "white",
                      padding: "15px",
                      height: "350px",
                      margin: "5px",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        borderBottom: "1px solid #DDD",
                        paddingBottom: "10px",
                        marginBottom: "15px",
                      }}
                    >
                      <div
                        style={{
                          flex: "1",
                          fontSize: "20px",
                          color: color.gold,
                          textTransform: "uppercase",
                        }}
                      >
                        Matériels
                      </div>
                      {hardwares.items && (
                        <UncontrolledDropdown>
                          <DropdownToggle caret style={{ width: "250px" }}>
                            Choix des matériels
                          </DropdownToggle>
                          <DropdownMenu
                            style={{ maxHeight: "300px", overflow: "auto" }}
                          >
                            {hardwares.items.map((hardware) => (
                              <DropdownItem
                                key={hardware.id}
                                onClick={() => this.addHardware(hardware)}
                              >
                                {hardware.name}
                              </DropdownItem>
                            ))}
                          </DropdownMenu>
                        </UncontrolledDropdown>
                      )}
                    </div>

                    <div>
                      {ticket.hardwares &&
                        ticket.hardwares.map((hardware) => (
                          <div
                            key={hardware._id || hardware.id}
                            style={{
                              padding: "10px",
                              borderRadius: "5px",
                              background: "#F0F0F0",
                              marginBottom: "5px",
                            }}
                          >
                            <FontAwesomeIcon
                              icon={faTimesCircle}
                              color="#DF0000"
                              style={{
                                fontSize: "13px",
                                cursor: "pointer",
                                marginRight: "10px",
                              }}
                              onClick={() => this.deleteHardware(hardware)}
                            />
                            {hardware.name}
                          </div>
                        ))}
                      <span
                        style={{
                          fontSize: "11px",
                          color: color.gold,
                          textTransform: "none",
                        }}
                      >
                        *description obligatoire
                      </span>
                      <Input
                        id="text"
                        type="textarea"
                        name="description"
                        placeholder="Description du problème rencontré"
                        value={ticket.description || ""}
                        onChange={this.handleInputChange}
                        style={{ flex: "1" }}
                      />
                    </div>
                  </div>
                </div>

                <div
                  style={{
                    display: "flex",
                    marginBottom: "5px",
                    flexWrap: "wrap",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      flex: "1",
                      border: "1px solid #CCC",
                      background: "white",
                      padding: "15px",
                      minHeight: "400px",
                      margin: "5px",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        borderBottom: "1px solid #DDD",
                        paddingBottom: "10px",
                        marginBottom: "15px",
                      }}
                    >
                      <div
                        style={{
                          flex: "1",
                          fontSize: "20px",
                          color: color.gold,
                          textTransform: "uppercase",
                        }}
                      >
                        Photo du problème
                      </div>
                    </div>

                    <fieldset disabled={this.state.isBusy}>
                      <div style={{ display: "flex", margin: "10px 0" }}>
                        <FontAwesomeIcon
                          icon={faPaperclip}
                          style={{ cursor: "pointer", fontSize: "20px" }}
                          title="Ajouter une photo"
                          onClick={() => this.dropzoneRef.current.open()}
                        />
                      </div>

                      {ticket.afterSaleFiles &&
                        Object.keys(ticket.afterSaleFiles)
                          .sort((a, b) => {
                            return (
                              ticket.afterSaleFiles[a].weight -
                              ticket.afterSaleFiles[b].weight
                            );
                          })
                          .map((afterSaleFileId, key) => {
                            let fileType = "img";
                            if (
                              ticket.afterSaleFiles[afterSaleFileId]
                                .originalFileName &&
                              ticket.afterSaleFiles[
                                afterSaleFileId
                              ].originalFileName.endsWith(".pdf")
                            ) {
                              fileType = "pdf";
                            }
                            return (
                              <div key={afterSaleFileId}>
                                {key === 0 &&
                                  fileType === "img" &&
                                  ticket.afterSaleFiles[afterSaleFileId]
                                    .fileId && (
                                    <img
                                      src={getFileUrl(
                                        ticket.afterSaleFiles[afterSaleFileId]
                                          .fileId,
                                        authentication.encryptedToken,
                                        ticket.afterSaleFiles[afterSaleFileId]
                                          .originalFileName
                                      )}
                                      style={{
                                        maxWidth: "250px",
                                        maxHeight: "250px",
                                        margin: "5px",
                                        float: "left",
                                      }}
                                      alt="img"
                                    />
                                  )}
                                <div
                                  style={{
                                    minWidth: "200px",
                                    display: "flex",
                                    position: "relative",
                                    marginBottom: "10px",
                                    alignItems: "center",
                                    borderRadius: "5px",
                                    background: "#D9D9D9",
                                    padding: "5px",
                                    overflow: "hidden",
                                  }}
                                >
                                  {ticket.afterSaleFiles[afterSaleFileId]
                                    .uploadProgress && (
                                    <div
                                      style={{
                                        position: "absolute",
                                        zIndex: "0",
                                        height: "100%",
                                        left: "0",
                                        top: "0",
                                        background: "#F0F0F0",
                                        width: `${ticket.afterSaleFiles[
                                          afterSaleFileId
                                        ].uploadProgress * 100}%`,
                                      }}
                                    />
                                  )}
                                  {ticket.afterSaleFiles[afterSaleFileId]
                                    .uploadProgress && (
                                    <div
                                      style={{
                                        fontSize: "11px",
                                        marginRight: "5px",
                                        fontWeight: "bold",
                                        position: "relative",
                                        zIndex: "1",
                                      }}
                                    >
                                      {Math.round(
                                        ticket.afterSaleFiles[afterSaleFileId]
                                          .uploadProgress * 100
                                      )}
                                      %
                                    </div>
                                  )}
                                  <div
                                    onClick={() =>
                                      this.showFile(
                                        ticket.afterSaleFiles[afterSaleFileId]
                                      )
                                    }
                                    style={{
                                      flex: "1",
                                      fontSize: "12px",
                                      overflow: "hidden",
                                      textOverflow: "ellipsis",
                                      position: "relative",
                                      zIndex: "1",
                                      cursor: "pointer",
                                    }}
                                  >
                                    {
                                      ticket.afterSaleFiles[afterSaleFileId]
                                        .originalFileName
                                    }
                                  </div>
                                  {!this.state.isBusy && (
                                    <Button
                                      className="btn-danger"
                                      onClick={() =>
                                        this.deleteAfterSaleFile(
                                          afterSaleFileId
                                        )
                                      }
                                    >
                                      Supprimer
                                    </Button>
                                  )}
                                </div>
                              </div>
                            );
                          })}
                      <Dropzone
                        accept="image/*, .pdf"
                        disableClick
                        ref={this.dropzoneRef}
                        onDrop={this.onFileDrop}
                        multiple={true}
                        style={{ display: "none" }}
                      />
                    </fieldset>
                  </div>

                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      flex: "1",
                      flexWrap: "wrap",
                      border: "1px solid #CCC",
                      background: "white",
                      padding: "15px",
                      minHeight: "400px",
                      margin: "5px",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        borderBottom: "1px solid #DDD",
                        paddingBottom: "10px",
                        marginBottom: "15px",
                      }}
                    >
                      <div
                        style={{
                          flex: "1",
                          fontSize: "20px",
                          color: color.gold,
                          textTransform: "uppercase",
                        }}
                      >
                        Date / heure de démarrage souhaitée
                      </div>
                    </div>

                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        flex: "1",
                        flexWrap: "wrap",
                        overflowY: "auto",
                        fontSize: "14px",
                        color: "#222",
                      }}
                    >
                      <div style={{ flex: "1" }}>
                        <Calendar
                          name="requestedDate"
                          tileDisabled={({ activeStartDate, date, view }) => {
                            const isSameDayAndYear =
                              moment(date).format("DD/MM/YYYY") ===
                              moment(currentDateRequested).format("DD/MM/YYYY");
                            return (
                              ticket.id !== "new" &&
                              !hasPermission(
                                permissionNames,
                                permissions.UpdateTicketDates
                              ) &&
                              !isSameDayAndYear
                            );
                          }}
                          minDate={minDateRequested}
                          activeStartDate={minDateRequested}
                          value={
                            ticket.requestedDateYear &&
                            ticket.requestedDateDayOfYear
                              ? moment()
                                  .year(ticket.requestedDateYear)
                                  .dayOfYear(ticket.requestedDateDayOfYear)
                                  .toDate()
                              : null
                          }
                          onChange={(date) =>
                            this.handleInputChange({
                              target: { name: "requestedDate", value: date },
                            })
                          }
                        />
                      </div>

                      <div
                        style={{
                          flex: "1",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <div style={{ margin: "0 10px" }}>Heure</div>
                        <Input
                          disabled={
                            ticket.id !== "new" &&
                            !hasPermission(
                              permissionNames,
                              permissions.UpdateTicketDates
                            )
                          }
                          type="time"
                          name="requestedTimeRangeStart"
                          value={ticket.requestedTimeRangeStart || "00:00"}
                          onChange={this.handleInputChange}
                        />
                        {/* <div style={{ margin: '0 10px' }}>et</div>
                                                <Input type="time" name="requestedTimeRangeEnd" value={ticket.requestedTimeRangeEnd || ''} onChange={this.handleInputChange} /> */}
                      </div>
                    </div>
                  </div>
                </div>

                {ticket.id !== "new" && (
                  <div>
                    <div
                      style={{
                        display: "flex",
                        flexWrap: "wrap",
                        marginBottom: "5px",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          flex: "1",
                          border: "1px solid #CCC",
                          background: "white",
                          padding: "15px",
                          minHeight: "400px",
                          margin: "5px",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            borderBottom: "1px solid #DDD",
                            paddingBottom: "10px",
                            marginBottom: "15px",
                          }}
                        >
                          <div
                            style={{
                              flex: "1",
                              fontSize: "20px",
                              color: color.gold,
                              textTransform: "uppercase",
                            }}
                          >
                            Date d'intervention planifiée
                          </div>
                        </div>

                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            flex: "1",
                            overflowY: "auto",
                            fontSize: "14px",
                            color: "#222",
                          }}
                        >
                          <Calendar
                            name="expectedDate"
                            tileDisabled={({ activeStartDate, date, view }) => {
                              const isSameDayAndYear =
                                moment(date).format("DD/MM/YYYY") ===
                                moment(currentDateExpected).format(
                                  "DD/MM/YYYY"
                                );
                              return (
                                ticket.id !== "new" &&
                                !hasPermission(
                                  permissionNames,
                                  permissions.UpdateTicketDates
                                ) &&
                                !isSameDayAndYear
                              );
                            }}
                            minDate={minDateRequested}
                            activeStartDate={minDateRequested}
                            value={
                              ticket.expectedDateYear &&
                              ticket.expectedDateDayOfYear
                                ? moment()
                                    .year(ticket.expectedDateYear)
                                    .dayOfYear(ticket.expectedDateDayOfYear)
                                    .toDate()
                                : null
                            }
                            onChange={(date) =>
                              this.handleInputChange({
                                target: { name: "expectedDate", value: date },
                              })
                            }
                          />
                        </div>
                      </div>

                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          flex: "1",
                          border: "1px solid #CCC",
                          background: "white",
                          padding: "15px",
                          minHeight: "400px",
                          margin: "5px",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            borderBottom: "1px solid #DDD",
                            paddingBottom: "10px",
                            marginBottom: "15px",
                          }}
                        >
                          <div
                            style={{
                              flex: "1",
                              fontSize: "20px",
                              color: color.gold,
                              textTransform: "uppercase",
                            }}
                          >
                            Créneau horaire planifié
                          </div>
                        </div>

                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            flex: "1",
                            overflowY: "auto",
                            fontSize: "14px",
                            color: "#222",
                          }}
                        >
                          <div
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <div style={{ margin: "0 10px" }}>Heure</div>
                            <Input
                              disabled={
                                !hasPermission(
                                  permissionNames,
                                  permissions.UpdateTicketDates
                                )
                              }
                              type="time"
                              name="expectedTimeRangeStart"
                              value={ticket.expectedTimeRangeStart || ""}
                              onChange={this.handleInputChange}
                            />
                            {/* <div style={{ margin: '0 10px' }}>et</div>
                                                        <Input disabled={!hasPermission(permissionNames, permissions.UpdateTicketDates)} type="time" name="expectedTimeRangeEnd" value={ticket.expectedTimeRangeEnd || ''} onChange={this.handleInputChange} /> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </fieldset>
            </Form>
          </div>
          {/* {ticket.id !== 'new' && <TicketCommentsSidePanel ticket={ticket} />} */}
        </div>
      );
    } else {
      return null;
    }
  }
}

const mapStateToProps = (state) => ({
  settings: state.settings,
  tickets: state.tickets,
  hardwares: state.hardwares,
  customers: state.customers,
  authentication: state.authentication,
});

export default connect(mapStateToProps)(Radium(AfterSaleDetail));
